import { NoBscProviderError } from "@binance-chain/bsc-connector";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  InjectedConnector,
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector'

import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from '@chakra-ui/react'

import { configEnv, ICodeChainList } from "~/@config";

import { utils } from "ethers";
import ChainConfig from "~/common/constants/ChainConfig";

const { CHAINS } = configEnv();
const { EConnectorId, ChainLocalKey, connectorsByName, listChain } = ChainConfig;

const { accountActive, chainCode, connectorId, walletTitle } = ChainLocalKey;


const WalletContext = createContext(null);

const WalletProvider = ({ children }) => {
  const { activate, account, library, connector, active, deactivate, chainId } = useWeb3React();
  const navigate = useNavigate()
  const toast = useToast();
  const [isActive, setIsActive] = useState(false);
  const [shouldDisable, setShouldDisable] = useState(false) // Should disable connect button while connecting to Wallet
  const [isLoading, setIsLoading] = useState(true);

  const addChain = async (chainCode: ICodeChainList) => {
    const provider = window.ethereum;
    const selectedChain = CHAINS[chainCode];
    const { chainId } = selectedChain;
    const storeChainCode = localStorage.getItem(ChainLocalKey.chainCode);

    if (storeChainCode === chainCode) {
      return true;
    }

    if (!provider) {
      console.error(
        "Can't setup the BSC network on metamask because window.ethereum is undefined",
      )
      return false
    }
    try {

      console.log({
        ...selectedChain,
        chainId: `0x${chainId.toString(16)}`,
      })
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${chainId.toString(16)}` }]
        });
        localStorage.setItem(ChainLocalKey.chainCode, chainCode);
        return true;
      } catch (err) {
        // This error code indicates that the chain has not been added to MetaMask
        // alert(JSON.stringify(err.data.originalError))
        if (err.code === 4902 || err?.data?.originalError?.code == 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                ...selectedChain,
                chainId: `0x${chainId.toString(16)}`,
              },
            ]
          });
          localStorage.setItem(ChainLocalKey.chainCode, chainCode);
          return true;
        }
        return false;
      }
    } catch (error) {
      console.error('Failed to setup the network in Metamask:', error)
      return false
    }

  }
  const login = useCallback(
    (connectorID) => {
      const connector = connectorsByName[connectorID];

      if (connector) {
        activate(connector, async (error) => {
          console.log('-------------------');
          console.log({ error });
          console.log('-------------------');
          if (error instanceof UnsupportedChainIdError) {
            const chainCode = (localStorage.getItem(connectorId) || "POLYGON") as ICodeChainList;
            const hasSetup = await addChain(chainCode)
            if (hasSetup) {
              activate(connector)
            }
          } else {
            window.localStorage.removeItem(connectorId)
            if (
              error instanceof NoEthereumProviderError ||
              error instanceof NoBscProviderError
            ) {
              toast({
                title: 'Provider Error',
                description: 'No provider was found',
                position: 'top-right',
                isClosable: true,
                status: 'error',
              })
            } else if (
              error instanceof UserRejectedRequestErrorInjected ||
              error instanceof UserRejectedRequestErrorWalletConnect
            ) {
              if (connector instanceof WalletConnectConnector) {
                const walletConnector = connector
                walletConnector.walletConnectProvider = null
              }

              toast({
                title: 'Authorization Error',
                description: 'Please authorize to access your account',
                position: 'top-right',
                isClosable: true,
                status: 'error',
              })
            } else {
              console.log(error.name, error.message)
              toast({
                title: error.name,
                description: error.message,
                position: 'top-right',
                isClosable: true,
                status: 'error',
              })
            }
          }
        })
      } else {
        console.log('Unable to find connector')

        toast({
          title: 'Unable to find connector',
          description: 'The connector config is wrong',
          position: 'top-right',
          isClosable: true,
          status: 'error',
        })
      }
      localStorage.setItem(accountActive, account || '');
    },
    [account, activate, toast],
  )

  const logout = useCallback(() => {
    // dispatch(profileClear())
    deactivate()
    // This localStorage key is set by @web3-react/walletconnect-connector
    if (window.localStorage.getItem('walletconnect')) {
      // connectorsByName.walletconnect?.close()
      // connectorsByName.walletconnect?.walletConnectProvider = null
    }
    navigate('/')
  }, [deactivate, navigate])
  // Init Loading
  useEffect(() => {
    // connect().then(val => {
    //     setIsLoading(false)
    // })
    login(EConnectorId.Injected)
  }, [login])

  // Check when App is Connected or Disconnected to Wallet
  const handleIsActive = useCallback(() => {
    console.log('App is connected with Wallet ', active)
    setIsActive(active)
  }, [active])

  const getCurrentCode = () => {
    return localStorage.getItem(ChainLocalKey.chainCode) || 'POLYGON';
  }

  useEffect(() => {
    handleIsActive()
  }, [handleIsActive])

  useEffect(() => {
    const currentChain = listChain.find(item => item.chainId === Number(chainId));
    const storeChainCode = localStorage.getItem(ChainLocalKey.chainCode) || '';
    const chainCode = currentChain?.chainCode || "";
    if (storeChainCode !== chainCode) {
      localStorage.setItem(ChainLocalKey.chainCode, chainCode)
    }
  }, [chainId]);

  const values = useMemo(
    () => ({
      addChain,
      isActive,
      account,
      isLoading,
      connector,
      library,
      login,
      logout,
      shouldDisable,
      getCurrentCode,
    }),
    [isActive, account, isLoading, connector, library, login, logout, shouldDisable]
  )

  return <WalletContext.Provider value={values}>{children}</WalletContext.Provider>
}


export { WalletContext, WalletProvider }