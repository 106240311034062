import React from 'react'
import {
    ModalOverlay,
    Modal,
    ModalContent,
    ModalBody,
    Text,
    Image,
    VStack,
    Divider,
} from '@chakra-ui/react'

import FacebookImage from '~/assets/svgs/logo-facebook.svg'

interface IMintDetailModal {
    isOpen?: boolean
    onDismiss?: () => void
    txHash?: string
}

export const MintSuccessModal: React.FC<IMintDetailModal> = ({
    isOpen,
    onDismiss: onClose,
    txHash,
}) => {

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered >
            <ModalOverlay />
            <ModalContent mx="20px">
                <ModalBody textAlign={"center"} py="30px" >

                    <VStack spacing={4}>

                        <Text
                            fontSize={'23px'}
                            lineHeight={'29px'}
                            fontWeight={600}
                            color='08010C'
                        >
                            Thành công
                        </Text>

                        <Divider />

                        <Text
                            fontSize={'16px'}
                            lineHeight={'18px'}
                            fontWeight={600}
                            color='08010C'
                        >
                            NFT của bạn sẽ được hiển thị trong vòng 5-10 phút.
                        </Text>

                        {txHash &&
                            <Text
                                fontSize={'12px'}
                                lineHeight={'16px'}
                                fontWeight={400}
                                color="08010C"
                                cursor={"pointer"}
                                maxW="320px"
                                onClick={() => {
                                    window.open(txHash)
                                }}
                            >
                                Địa chỉ siêu dữ liệu:
                                {txHash}
                            </Text>
                        }

                        <VStack>
                            <Text
                                fontSize={'12px'}
                                lineHeight={'16px'}
                                fontWeight={400}
                                color="08010C"
                            >
                                Cập nhật thông tin mới nhất tại đây:
                            </Text>
                            <Image
                                cursor={"pointer"}
                                src={FacebookImage}
                                onClick={() => {
                                    window.open("https://www.facebook.com/groups/708321273875570")
                                }}
                            />
                        </VStack>

                    </VStack>

                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
