import { ReactComponent as Metamask } from '~/assets/svgs/wallets/meta-mask.svg'
import { ReactComponent as WalletConnect } from '~/assets/svgs/wallets/wallet-connect.svg'
import { ReactComponent as RiceConnect } from '~/assets/svgs/wallets/rice-wallet.svg'

// import TrustWallet from '~/layouts/Svg/Icons/TrustWallet'
// import MathWallet from '~/layouts/Svg/Icons/MathWallet'
// import TokenPocket from '~/layouts/Svg/Icons/TokenPocket'
// import BinanceChain from '~/layouts/Svg/Icons/BinanceChain'
// import SafePal from '~/layouts/Svg/Icons/SafePal'
// import Coin98 from '~/layouts/Svg/Icons/Coin98'

export const ConnectorNames = {
  Injected: 'injected',
  WalletConnect: 'walletconnect',
  BSC: 'bsc',
}

const connectors = [
  {
    title: 'Metamask',
    icon: Metamask,
    image: '/assets/images/meta-mask.png',
    connectorId: ConnectorNames.Injected,
    priority: 1,
  },
  {
    title: 'Rice Wallet',
    icon: RiceConnect,
    connectorId: ConnectorNames.Injected,
    priority: 2,
  },
  {
    title: 'WalletConnect',
    icon: WalletConnect,
    connectorId: ConnectorNames.WalletConnect,
    priority: 3,
  },
  // {
  //   title: 'Trust Wallet',
  //   icon: TrustWallet,
  //   connectorId: ConnectorNames.Injected,
  //   priority: 3,
  // },
  // {
  //   title: 'MathWallet',
  //   icon: MathWallet,
  //   connectorId: ConnectorNames.Injected,
  //   priority: 999,
  // },
  // {
  //   title: 'TokenPocket',
  //   icon: TokenPocket,
  //   connectorId: ConnectorNames.Injected,
  //   priority: 999,
  // },

  // {
  //   title: 'Binance Chain',
  //   icon: BinanceChain,
  //   connectorId: ConnectorNames.BSC,
  //   priority: 999,
  // },
  // {
  //   title: 'Coin98',
  //   icon: Coin98,
  //   connectorId: ConnectorNames.Injected,
  //   priority: 999,
  // },
  // {
  //   title: 'SafePal',
  //   icon: SafePal,
  //   connectorId: ConnectorNames.Injected,
  //   priority: 999,
  // },
]

export const WalletConfig = connectors
export const connectorLocalStorageKey = 'connectorIdv2'
export const walletLocalStorageKey = 'wallet'
export const emailGoogleLogin = 'emailGoogleLogin'
