import { useCallback, useState } from 'react'
import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react'
import { usePagination } from '@ajna/pagination'
import { useParams } from 'react-router-dom'
import {
  useQueryParams,
  withDefault,
  StringParam,
  NumberParam,
  ObjectParam,
} from 'use-query-params'
import { useTranslation } from 'react-i18next'

import LayoutList from '~/components/LayoutList'
import Select from '~/components/Select'
import PaginationComponent from '~/components/Pagination'
import Loading from '~/components/Loading'
import EmptyData from '~/components/EmptyData'
import CollectionDetailItemCard from '~/container/CollectionDetailItemCard/CollectionDetailItemCard'
import { ReactComponent as RefreshIcon } from '~/assets/svgs/refresh.svg'
import { ReactComponent as SearchIcon } from '~/assets/svgs/search.svg'

import useListItemInCollection from '../hooks/useListItemInCollection'
import { ORDERS } from '../constants/orders'
import useWindowSize from '~/hooks/useWindowSize'

const Items = ({ selectedList, setSelectedList }) => {
  const { width } = useWindowSize()
  const { t } = useTranslation(
    'src/views/CollectionDetail/components/Items.lang.json',
  )
  const [{ order, fromPrice, toPrice, attributes }, setQuery] = useQueryParams({
    order: withDefault(StringParam, ORDERS.ids[0]),
    fromPrice: withDefault(NumberParam, 0),
    toPrice: withDefault(NumberParam, 0),
    attributes: withDefault(ObjectParam, {
      traitType: '',
      values: '',
    }),
  })

  const [valueSearch, setValueSearch] = useState('')
  const { collectionAddress } = useParams()
  const [pageIndex, setPageIndex] = useState(1)

  const { data, total, isLoading, refetch, isRefetching } =
    useListItemInCollection({
      pageSize: 10,
      pageIndex,
      address: collectionAddress,
      valueSearch,
      order,
      minPrice: fromPrice || undefined,
      maxPrice: toPrice || undefined,
      ...(attributes?.values &&
        attributes?.values !== 'undefined' && {
          attributes: [
            {
              traitType: attributes?.traitType as any,
              values: [attributes?.values],
            },
          ],
        }),
    })

  const { pages, pagesCount, currentPage, setCurrentPage } = usePagination({
    total,
    limits: {
      outer: 1,
      inner: 1,
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: pageIndex,
    },
  })

  const handlePageChange = useCallback(
    (nextPage: number): void => {
      setPageIndex(nextPage)
      setCurrentPage(nextPage)
    },
    [setCurrentPage],
  )

  const onSelect = useCallback(
    (item) => {
      const index = selectedList.findIndex(
        (selectedItem) => selectedItem?.id === item?.id,
      )
      if (index > -1) {
        const newList = selectedList.filter(
          (selected) => selected.id !== item?.id,
        )
        setSelectedList(newList)

        return
      }
      setSelectedList((current) => [...current, item])
    },
    [selectedList, setSelectedList],
  )

  return (
    <Box w="100%">
      <HStack flexWrap="wrap">
        <Box>
          <Box mb="27px" width="100%">
            <Button
              bg="white !important"
              borderRadius="10px"
              h="39px"
              isLoading={isRefetching}
              onClick={() => refetch()}
            >
              <Icon as={RefreshIcon} />
            </Button>
          </Box>
        </Box>
        <Box w="212px">
          <Box mb="27px" width="100%">
            <Select
              options={ORDERS.ids.map((item) => ({
                label: t(ORDERS.data[item]),
                value: item,
              }))}
              placeholder="Select Options"
              onChange={(selected) => setQuery({ order: selected.value })}
              value={{
                label: t(ORDERS.data[order]),
                value: order,
              }}
            />
          </Box>
        </Box>
        <Box
          w={{
            base: '300px',
            xl: '320px',
          }}
        >
          <InputGroup mb="27px">
            <Input
              bg="white"
              placeholder="Search by name or token ID"
              borderRadius="10px"
              border="1.5px solid #DFE6F1"
              outline="none"
              onChange={(e) => setValueSearch(e?.target?.value)}
            />
            <InputRightElement
              pointerEvents="none"
              children={<Icon as={SearchIcon} color="gray.300" />}
            />
          </InputGroup>
        </Box>
      </HStack>

      {isLoading ? (
        <Loading />
      ) : data?.length > 0 ? (
        <SimpleGrid
          columns={
            selectedList?.length === 0
              ? {
                  sm: 1,
                  lg: 3,
                  xl: 3,
                  '2xl': 5,
                  '3xl': 6,
                }
              : {
                  sm: 1,
                  lg: 3,
                  xl: 3,
                  '2xl': 4,
                  '3xl': 6,
                }
          }
          w="100%"
          spacing="16px"
          {...(width <= 1440 && data.length > 1 && { minChildWidth: '200px' })}
        >
          {data.map((item) => {
            return (
              <CollectionDetailItemCard
                key={item.id}
                {...item}
                onSelect={onSelect}
                isSelected={
                  selectedList?.findIndex(
                    (selected) => selected.id === item.id,
                  ) > -1
                }
                isShowSelected={selectedList?.length > 0}
              />
            )
          })}
        </SimpleGrid>
      ) : (
        <EmptyData />
      )}

      {total !== 0 && !isLoading && (
        <Stack justify="center" w="100%" marginTop={10}>
          <Box>
            <PaginationComponent
              pagesCount={pagesCount}
              currentPage={currentPage}
              isDisabled={false}
              onPageChange={handlePageChange}
              pages={pages}
            />
          </Box>
        </Stack>
      )}
    </Box>
  )
}

export default Items
