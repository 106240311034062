import { useQuery } from '@tanstack/react-query'
import NFTMarketPlaceServices from '~/services/nft.marketplace.services'

// interface IItemNewest {
//   id: string
//   createdDate: string
//   updatedDate: string
//   createdBy: string
//   updatedBy: string
//   askId: number
//   address: string
//   tokenId: number
//   seller: string
//   price: number
//   status: string
// }

const useListItemInCollection = (variables) => {
  const results = useQuery<{
    data: Array<any>
    total: number
  }>(['collectionListItem', variables], async () =>
    NFTMarketPlaceServices.collectionListItem(variables),
  )
  return {
    ...results,
    data: results?.data?.data ?? [],
    total: results?.data?.total,
  }
}

export default useListItemInCollection
