import { INftProject } from "~/dto";
import Logo from '~/assets/images/cio/CIO.png'
import { EStatus } from "~/common/enums";

export const Cio: INftProject =
{
    collection: "cio-vietnam-christmas",
    name: "CIO Vietnam Christmas",
    banner: "",
    logo: Logo,
    shortContent: "",
    description: "",
    status: EStatus.UPCOMING,
    tag1: "NFT Collectible",
    tag2: "Hiệp Hội",
    tag3: "",
    nftAllowcation: "(TBA)",
    deposit: "Miễn Phí",
    listNft: [
        {
            askId: "",
            collectionName: "",
            name: "",
            image: "",
            video: "",
            about: ""
        }
    ]
}