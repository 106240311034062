import { INftProject } from "~/dto";
import Banner from '~/assets/images/exploring-nft/banner-web3-passport.png'
import Logo from '~/assets/images/exploring-nft/passport-ava-png.png'
import Image from '~/assets/images/exploring-nft/passport-ava-png.png'
import { EStatus } from "~/common/enums";

export const Web3Passport: INftProject =
{
    collection: "exploring-nft",
    name: "Exploring NFT",
    banner: Banner,
    logo: Logo,
    shortContent: "EXPLORING NFT là hành trình tìm hiểu, khám phá về NFT dành riêng cho marketer thông qua việc trải nghiệm, tương tác trực tiếp với NFT.",
    description: "EXPLORING NFT là hành trình tìm hiểu, khám phá về NFT dành riêng cho marketer thông qua việc trải nghiệm, tương tác trực tiếp với NFT.",
    status: EStatus.UPCOMING,
    tag1: "NFT Bộ sưu tập",
    tag2: "Food & Bevarage",
    tag3: "Ho Chi Minh",
    nftAllowcation: "(TBA)",
    deposit: "Miễn Phí",
    listNft: [
        {
            askId: "web3-passport",
            collectionName: "Web3 Passport For Marketer",
            name: "Web3 Passport For Marketer",
            image: Image,
            video: "../../web3-passport.mp4",
            about: "Web3 Passport For Marketer là NFT đầu tiên trong hành trình EXPLORING NFT của marketer, là một tấm vé đưa marketer bước chân vào Web3. Người dùng sẽ tìm hiểu và trải nghiệm flow mint free NFT từ: tạo ví, whitelist, mint NFT, redeem,..."
        }
    ]
}