import { configEnv } from '~/@config'
import { blockChainHelper } from './block-chain.helper'
const { BLOCK_CHAIN } = configEnv()

const setupNetwork = async () => {
  const provider = window.ethereum
  if (provider) {
    const chainId = parseInt(BLOCK_CHAIN.REACT_APP_CHAIN_ID, 10)
    try {
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
            chainName: 'Binance Smart Chain Mainnet',
            nativeCurrency: {
              name: 'BNB',
              symbol: 'bnb',
              decimals: 18,
            },
            rpcUrls: blockChainHelper.nodes,
            blockExplorerUrls: [`${BLOCK_CHAIN.REACT_APP_BSCSCAN}/`],
          },
        ],
      })
      return true
    } catch (error) {
      console.error('Failed to setup the network in Metamask:', error)
      return false
    }
  } else {
    console.error(
      "Can't setup the BSC network on metamask because window.ethereum is undefined",
    )
    return false
  }
}
export const walletHelper = {
  setupNetwork,
}
