import { useCallback, useMemo, useState } from 'react'
import {
  Avatar,
  Box,
  Flex,
  Icon,
  Image,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  HStack,
  Spacer,
  Button,
  useDisclosure,
  Stack,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import MainLayout from '~/components/MainLayout/MainLayout'
import { ReactComponent as HeartIcon } from '~/assets/svgs/heart.svg'
import { ReactComponent as FullHeartIcon } from '~/assets/svgs/full-heart.svg'
import { ReactComponent as ArrowRightIcon } from '~/assets/svgs/arrow-right.svg'
import { ReactComponent as ArrowLeftIcon } from '~/assets/svgs/Arrow-right-icon.svg'
import { MARKET_PLACE_ROUTER } from '~/routes/routes'

import Activities from './components/Activities/Activities'
import Items from './components/Items'
import ItemsMobile from './components/Items.mobile'
import useCollectionInfo from './hooks/useCollectionInfo'
import FilterItems from './components/FilterItems'
import Description from './components/Description'
import CollectionInfo from './components/CollectionInfo'
import ReportInfo from './components/ReportInfo'
import HeaderMobile from './components/Header.mobile'
import SocialsMobile from './components/Socials.mobile'
import Socials from './components/Socials'
import BulkBuySection from './components/BulkBuySection/BulkBuySection'

const TabCustom = ({ children }) => {
  return (
    <Tab
      fontSize="18px"
      lineHeight="21px"
      fontWeight="500"
      color="black.light"
      borderRadius="0px"
      borderBottomColor="white"
      bg="transparent"
      _selected={{
        fontWeight: '600',
        color: 'blue.neutral',
        borderBottomColor: 'blue.neutral',
      }}
      position="relative"
    >
      {children}
    </Tab>
  )
}

const DetailCollection = () => {
  const { collectionAddress: address } = useParams()
  const { data } = useCollectionInfo({ address })

  const navigation = useNavigate()
  const { t } = useTranslation(
    'src/views/Marketplace/DetailCollection.lang.json',
  )
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [isShowFilter, setIsShowFilter] = useState(true)
  const [isFavorite, setIsFavorite] = useState(false)
  const [selectedList, setSelectedList] = useState([])

  const socialData = useMemo(() => {
    return data?.sosials?.reduce((currentValue, nextValues) => {
      currentValue[nextValues.type] = nextValues.link
      return currentValue
    }, {})
  }, [data])

  const onRemoveItem = useCallback((id) => {
    setSelectedList((current) => current.filter((item) => item.id !== id))
  }, [])

  const removeAllItem = useCallback(() => {
    setSelectedList([])
  }, [])

  const renderDesktopLayout = useCallback(() => {
    return (
      <Flex flex={1} overflow="hidden" paddingBottom="20px">
        {isShowFilter && (
          <Box
            w={isOpen ? '282px' : '60px'}
            boxShadow="-4px 0px 15px rgba(82, 63, 105, 0.15)"
            bg="white"
            transition="all 250ms ease-in-out"
            display={{
              base: 'none',
              lg: 'block',
            }}
            h="100vh"
          >
            {isOpen ? (
              <FilterItems onOpen={onOpen} isOpen={isOpen} onClose={onClose} />
            ) : (
              <HStack p="20px" w="100%">
                <Icon as={ArrowLeftIcon} onClick={onOpen} w="24px" h="24px" />
              </HStack>
            )}
          </Box>
        )}

        <Box flex={1} w="100%">
          <Box
            mt="30px"
            px="50px"
            display={{
              base: 'none',
              lg: 'block',
            }}
          >
            <Tabs
              isLazy
              onChange={(tab) => {
                setIsShowFilter(!tab)
              }}
            >
              <TabList borderBottomWidth={0}>
                <TabCustom>Items</TabCustom>
                <TabCustom>Activities</TabCustom>
              </TabList>

              <TabPanels mt="30px">
                <TabPanel px={0}>
                  <Items
                    selectedList={selectedList}
                    setSelectedList={setSelectedList}
                  />
                </TabPanel>
                <TabPanel px={0} overflow="hidden">
                  <Activities />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>

        {selectedList?.length > 0 && (
          <Box
            w={'357px'}
            boxShadow="-4px 0px 15px rgba(82, 63, 105, 0.15)"
            bg="white"
            transition="all 250ms ease-in-out"
            display={{
              base: 'none',
              lg: 'block',
            }}
            h="100vh"
          >
            <BulkBuySection
              listBuys={selectedList}
              onClose={removeAllItem}
              onRemoveItem={onRemoveItem}
            />
          </Box>
        )}
      </Flex>
    )
  }, [
    isOpen,
    isShowFilter,
    onClose,
    onOpen,
    onRemoveItem,
    removeAllItem,
    selectedList,
  ])

  const renderMobileLayout = useCallback(() => {
    return (
      <Box display={{ base: 'block', lg: 'none' }}>
        <ItemsMobile />

        <HStack
          p="20px"
          bg="white"
          onClick={() => {
            navigation(`${MARKET_PLACE_ROUTER}/${address}/activities`)
          }}
          pb={{
            base: '100px',
            lg: 0,
          }}
          display={{
            base: 'flex',
            lg: 'none',
          }}
        >
          <Text
            fontWeight="700"
            color="secondary"
            fontSize="22px"
            lineHeight="26px"
          >
            Activities
          </Text>
          <Spacer />
          <ArrowRightIcon />
        </HStack>
      </Box>
    )
  }, [address, navigation])

  const renderFooter = useCallback(() => {
    return (
      <Box
        display={{
          base: 'bock',
          lg: 'none',
        }}
        position="fixed"
        bottom={0}
        left={0}
        right={0}
        backgroundColor="white"
        zIndex="100"
        py="20px"
        textAlign="center"
        px="20px"
        boxShadow="0px -2px 6px rgba(0, 0, 0, 0.1)"
      >
        <Button
          leftIcon={
            <Icon
              as={isFavorite ? FullHeartIcon : HeartIcon}
              w="24px"
              h="24px"
            />
          }
          w="100%"
          border="1px solid #DFE6F1 !important"
          bg="white !important"
          h="auto"
          py="9px"
          fontWeight="500"
          letterSpacing="-0.5px"
          fontSize="15px"
          lineHeight="18px"
          color="black.light"
          borderRadius="10px"
          onClick={() => {
            setIsFavorite((currentState) => !currentState)
          }}
        >
          Add to Favorite
        </Button>
      </Box>
    )
  }, [isFavorite])

  return (
    <MainLayout>
      <Box
        w="100%"
        h="100%"
        bg={{
          base: 'white',
          lg: 'transparent',
        }}
        position="relative"
      >
        <HeaderMobile />
        <Box
          position="relative"
          pt={{
            base: '50px',
            lg: 0,
          }}
        >
          <Image
            h={{
              base: '120px',
              lg: '263px',
            }}
            w={'full'}
            src={data?.image}
            objectFit={'cover'}
          />
        </Box>

        <Box bg="white">
          <Flex
            justify={{
              base: 'flex-start',
            }}
            mt={{
              base: '-50px',
              lg: '-80px',
            }}
            alignItems={{
              base: 'flex-end',
            }}
            mx={{
              base: '20px',
            }}
            position="relative"
          >
            <Avatar
              size={{
                base: '100px',
                lg: '160px',
              }}
              w={{
                base: '100px',
                lg: '160px',
              }}
              h={{
                base: '100px',
                lg: '160px',
              }}
              src="/assets/images/demo/avatar.png"
              borderWidth="6px"
              borderStyle="solid"
              borderColor="white"
            />
            <SocialsMobile socialData={socialData} />
            <HStack w="100%" justifyContent="space-between" align="start">
              <Box flex={1} position="relative">
                <CollectionInfo name={data?.name} symbol={data?.symbol} />
              </Box>
              <Box>
                <Socials socialData={socialData} />
              </Box>
            </HStack>
          </Flex>
        </Box>

        <Stack
          py="24px"
          px="38px"
          bg="white"
          alignItems="flex-start"
          borderBottom="1px solid #DFE6F1"
          flexDirection={{
            base: 'column',
            lg: 'row',
          }}
        >
          <Box
            flex={{
              base: 1,
              lg: 2,
              xl: 1,
            }}
            order={{
              base: 2,
              lg: 1,
            }}
            w="100%"
          >
            <ReportInfo address={address} />
          </Box>
          <Box
            flex={1}
            order={{
              base: 1,
              lg: 2,
            }}
          >
            <Box
              ml={{
                base: '20px',
              }}
            >
              <Description description={data?.description} />
            </Box>
          </Box>
        </Stack>

        {renderMobileLayout()}
        {renderDesktopLayout()}
      </Box>

      {/* {renderFooter()} */}
    </MainLayout>
  )
}

export default DetailCollection
