import { HStack, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useCollectionTotalReport from '../hooks/useCollectionTotalReport'

const ReportInfoItem = ({
  title = '',
  description = '',
  icon = null,
  pipe = undefined,
}) => {
  return (
    <Stack
      bg="#F3F6F9"
      py={{
        base: '12px',
        lg: '15px',
      }}
      px={{
        base: '10px',
        lg: '12px',
      }}
      borderRadius="8px"
      flexDirection={{
        base: 'column',
        lg: 'row',
      }}
      alignItems="center"
      justifyContent="space-between"
      spacing={0}
    >
      <Text
        fontWeight="400"
        fontSize={{
          base: '12px',
          lg: '13px',
        }}
        lineHeight={{
          base: '14px',
          lg: '15px',
        }}
        letterSpacing="-0.5px"
        color="black.lighter"
        textAlign={{
          base: 'left',
          lg: 'center',
        }}
      >
        {title}
      </Text>

      <HStack>
        {icon && <Image src="/assets/images/demo/busd.svg" />}
        <Text
          textAlign="center"
          color="blue.darker"
          letterSpacing="-0.5px"
          fontWeight="500"
          fontSize={{
            base: '14px',
            lg: '14px',
          }}
          lineHeight={{
            base: '16px',
            lg: '16px',
          }}
        >
          {pipe ? pipe(description) : description}
        </Text>
      </HStack>
    </Stack>
  )
}

const ReportInfo = ({ address }) => {
  const { t } = useTranslation(
    'src/views/CollectionDetail/components/ReportInfo.lang.json',
  )
  const { data: dataTotalReport } = useCollectionTotalReport({ address })

  const INFO = useMemo(
    () => [
      {
        title: t('floor_price'),
        amount: `${dataTotalReport?.floorPrice?.toFixed(2) ?? 0}`,
        icon: true,
      },
      {
        title: t('total_volume'),
        amount: `${dataTotalReport?.totalVolume?.toFixed(2) ?? 0}`,
        icon: true,
      },
      {
        title: t('avg_save_price'),
        amount: `${dataTotalReport?.avgSavePrice?.toFixed(2) ?? 0}`,
        icon: true,
      },
      {
        title: t('total_listed'),
        amount: `${dataTotalReport?.totalListed ?? 0}`,
        icon: null,
      },
      {
        title: t('total_supply'),
        amount: `${dataTotalReport?.totalSupply ?? 0}`,
        icon: null,
      },
      {
        title: t('total_holders'),
        amount: `${dataTotalReport?.totalHolders ?? 0}`,
        icon: null,
      },
    ],
    [t, dataTotalReport],
  )

  return (
    <SimpleGrid
      columns={{
        base: 2,
        '375': 2,
        sm: 2,
        '2xl': 3,
      }}
      spacing="16px"
    >
      {INFO.map((item, index) => {
        return (
          <ReportInfoItem
            key={index}
            title={t(item.title)}
            description={item.amount}
            icon={item.icon}
          />
        )
      })}
    </SimpleGrid>
  )
}

export default memo(ReportInfo)
