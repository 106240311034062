import { useCallback, useMemo, useState } from 'react'
import {
    Avatar,
    Box,
    Flex,
    SimpleGrid,
    Image,
    Text,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    HStack,
    Spacer,
    Stack,
    VStack,
} from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'

import MainLayout from '~/components/MainLayout/MainLayout'
import { ReactComponent as ArrowRightIcon } from '~/assets/svgs/arrow-right.svg'
import { Activities } from './components/Activities'
import HeaderMobile from './components/Header.mobile'
import Description from './components/Description'
import { CollectionDetailItem } from './components/CollectionDetailItem/CollectionDetailItem'
import { resourceProjects } from '~/hooks/v2/resources'
import useWindowSize from '~/hooks/useWindowSize'
import { NftDetail } from './components/NftDetail'

const TabCustom = ({ children }) => {
    return (
        <Tab
            fontSize="18px"
            lineHeight="21px"
            fontWeight="500"
            color="black.light"
            borderRadius="0px"
            borderBottomColor="white"
            bg="transparent"
            _selected={{
                fontWeight: '600',
                color: 'blue.neutral',
                borderBottomColor: 'blue.neutral',
            }}
            position="relative"
        >
            {children}
        </Tab>
    )
}

export const CollectionDetail = () => {
    const { width } = useWindowSize();
    const { id: address } = useParams()

    const projects = useMemo(() => {
        return resourceProjects.find(item => item.collection === address);
    }, [address])

    // const renderDesktopLayout = useCallback(() => {
    //     return (
    //         <Flex flex={1} overflow="hidden" paddingBottom="20px">

    //             <Box flex={1} w="100%">
    //                 <Box
    //                     mt="30px"
    //                     px="50px"
    //                     display={{
    //                         base: 'none',
    //                         lg: 'block',
    //                     }}
    //                 >
    //                     <Tabs
    //                         isLazy
    //                         onChange={(tab) => {
    //                             // setIsShowFilter(!tab)
    //                         }}
    //                     >
    //                         <TabList borderBottomWidth={0}>
    //                             <TabCustom>Bộ sưu tập</TabCustom>
    //                             <TabCustom>Thông tin về shop</TabCustom>
    //                         </TabList>

    //                         <TabPanels mt="30px">
    //                             <TabPanel px={0}>

    //                                 <SimpleGrid
    //                                     columns={{
    //                                         sm: 1,
    //                                         lg: 3,
    //                                         xl: 3,
    //                                         '2xl': 5,
    //                                         '3xl': 6,
    //                                     }}
    //                                     w="100%"
    //                                     spacing="16px"
    //                                     {...(width <= 1440 && projects.listNft.length > 1 && { minChildWidth: '200px' })}
    //                                 >
    //                                     {projects.listNft.map((item) => {
    //                                         return (
    //                                             <CollectionDetailItem
    //                                                 {...item}
    //                                                 collectionName={projects.name}
    //                                             />
    //                                         )
    //                                     })}
    //                                 </SimpleGrid>


    //                             </TabPanel>
    //                             <TabPanel px={0} overflow="hidden">
    //                                 <Activities />
    //                             </TabPanel>
    //                         </TabPanels>
    //                     </Tabs>
    //                 </Box>
    //             </Box>
    //         </Flex>
    //     )
    // }, [projects.listNft, projects.name, width])

    // const renderMobileLayout = useCallback(() => {
    //     return (
    //         <Box display={{ base: 'block', lg: 'none' }}>

    //             {projects.listNft.length > 2 ?

    //                 <SimpleGrid columns={2} spacing="15px" paddingX="20px">
    //                     {projects.listNft.map((item) => {
    //                         return (
    //                             <CollectionDetailItem
    //                                 {...item}
    //                             />
    //                         )
    //                     })}
    //                 </SimpleGrid>
    //                 :
    //                 <VStack>
    //                     {projects.listNft.map((item) => {
    //                         return (
    //                             <CollectionDetailItem
    //                                 {...item}
    //                             />
    //                         )
    //                     })}
    //                 </VStack>
    //             }

    //             <HStack
    //                 p="20px"
    //                 bg="white"
    //                 onClick={() => {
    //                 }}
    //                 pb={{
    //                     base: '100px',
    //                     lg: 0,
    //                 }}
    //                 display={{
    //                     base: 'flex',
    //                     lg: 'none',
    //                 }}
    //             >
    //                 <Text
    //                     fontWeight="700"
    //                     color="secondary"
    //                     fontSize="22px"
    //                     lineHeight="26px"
    //                 >
    //                     Thông tin về shop
    //                 </Text>
    //                 <Spacer />
    //                 <ArrowRightIcon />
    //             </HStack>
    //         </Box>
    //     )
    // }, [projects.listNft, projects.name])

    return (
        <MainLayout>
            <Box
                w="100%"
                h="100%"
                bg={{
                    base: 'white',
                    lg: 'transparent',
                }}
                position="relative"
            >
                <HeaderMobile />

                <Box
                    position="relative"
                    pt={{
                        base: '50px',
                        lg: 0,
                    }}
                >
                    <Image
                        h={{
                            base: '160px',
                            lg: '320px',
                        }}
                        w={'full'}
                        src={projects.banner}
                        objectFit={{ base: 'cover', lg: "initial" }}
                    />
                </Box>

                <Box bg="white">
                    <Flex
                        justify={{
                            base: 'flex-start',
                        }}
                        mt={{
                            base: '-50px',
                            lg: '-80px',
                        }}
                        alignItems={{
                            base: 'flex-end',
                        }}
                        mx={{
                            base: '20px',
                        }}
                        position="relative"
                    >
                        <Avatar
                            size={{
                                base: '100px',
                                lg: '160px',
                            }}
                            w={{
                                base: '100px',
                                lg: '160px',
                            }}
                            h={{
                                base: '100px',
                                lg: '160px',
                            }}
                            src={projects.logo}
                            borderWidth="6px"
                            borderStyle="solid"
                            borderColor="white"
                        />
                        <HStack w="100%" justifyContent="space-between" align="start">
                            <Box flex={1} position="relative">
                                <Flex align="start">
                                    <Text
                                        zIndex={10}
                                        fontSize={{
                                            base: '18px',
                                            lg: '28px',
                                        }}
                                        color={{
                                            base: 'black.light',
                                            lg: 'black.light',
                                        }}
                                        lineHeight={{
                                            base: '21px',
                                            lg: '33px',
                                        }}
                                        letterSpacing="-0.5px"
                                        fontWeight="600"
                                        noOfLines={2}
                                    >
                                        {projects.name}
                                    </Text>
                                </Flex>
                            </Box>

                        </HStack>
                    </Flex>
                </Box>

                <Stack
                    py="24px"
                    px="38px"
                    bg="white"
                    alignItems="flex-start"
                    borderBottom="1px solid #DFE6F1"
                    flexDirection={{
                        base: 'column',
                        lg: 'row',
                    }}
                >
                    <Box
                        flex={1}
                        order={{
                            base: 1,
                            lg: 2,
                        }}
                    >
                        <Box
                            ml={{
                                base: '0px',
                                lg: '20px'
                            }}
                        >
                            <Description description={projects.description} />
                        </Box>
                    </Box>
                </Stack>

                <NftDetail nftDetail={projects.listNft[0]} nftAllowcation={projects.nftAllowcation || "TBA"} />

                {/* 
                {renderMobileLayout()}
                {renderDesktopLayout()} */}
            </Box>

        </MainLayout>
    )
}
