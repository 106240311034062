import { Box, SimpleGrid, Text, HStack, Spacer } from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import EmptyData from '~/components/EmptyData'

import MyLoader from '~/components/Loader'
import Loading from '~/components/Loading'
import CollectionDetailItemCard from '~/container/CollectionDetailItemCard/CollectionDetailItemCard'
import { MARKET_PLACE_ROUTER } from '~/routes/routes'

import useListItemInCollection from '../hooks/useListItemInCollection'
import FooterReviewBulkBuyMobile from './FooterReviewBulkBuy.mobile'

const ItemMobile = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { collectionAddress } = useParams()
  const { data, isLoading } = useListItemInCollection({
    pageSize: 4,
    pageIndex: 1,
    address: collectionAddress,
  })
  const [selectedList, setSelectedList] = useState([])

  const onSelect = useCallback(
    (item) => {
      console.log('====================================')
      console.log(item)
      console.log('====================================')
      const index = selectedList.findIndex(
        (selectedItem) => selectedItem?.id === item?.id,
      )
      if (index > -1) {
        const newList = selectedList.filter(
          (selected) => selected.id !== item?.id,
        )
        setSelectedList(newList)

        return
      }
      setSelectedList((current) => [...current, item])
    },
    [selectedList, setSelectedList],
  )

  const onRemoveItem = useCallback((id) => {
    setSelectedList((current) => current.filter((item) => item.id !== id))
  }, [])

  const removeAllItem = useCallback(() => {
    setSelectedList([])
  }, [])

  return (
    <Box
      display={{
        base: 'block',
        lg: 'none',
      }}
    >
      <HStack p="20px" bg="white" w="100%">
        <Text
          fontWeight="700"
          color="secondary"
          fontSize="22px"
          lineHeight="26px"
        >
          Items
        </Text>
        <Spacer />

        <Text
          onClick={() =>
            navigate(`${MARKET_PLACE_ROUTER}/${collectionAddress}/items`)
          }
          fontWeight="400"
          fontSize="14px"
          color="secondary"
          lineHeight="16px"
        >
          See all
        </Text>
      </HStack>
      {isLoading ? (
        <Loading />
      ) : data?.length > 0 ? (
        <SimpleGrid columns={2} spacing="15px" paddingX="20px">
          {data.map((item) => {
            return (
              <CollectionDetailItemCard
                key={item.id}
                {...item}
                isSelected={
                  selectedList?.findIndex(
                    (selected) => selected.id === item.id,
                  ) > -1
                }
                onSelect={onSelect}
              />
            )
          })}
        </SimpleGrid>
      ) : (
        <EmptyData />
      )}
      {selectedList.length > 0 && (
        <FooterReviewBulkBuyMobile
          selectedList={selectedList}
          onRemoveItem={onRemoveItem}
          removeAllItem={removeAllItem}
        />
      )}
    </Box>
  )
}

export default ItemMobile
