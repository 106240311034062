import { useCallback, useState } from 'react'
import { Box, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { usePagination } from '@ajna/pagination'
import { useParams } from 'react-router-dom'

import PaginationComponent from '~/components/Pagination'
import CollectionDetailItemCard from '~/container/CollectionDetailItemCard/CollectionDetailItemCard'

import useListItemInCollection from '../hooks/useListItemInCollection'

const MoreNFTsSection = () => {
  const [pageIndex, setPageIndex] = useState(1)
  const { collectionAddress } = useParams()

  const { data, total } = useListItemInCollection({
    pageSize: 10,
    pageIndex,
    address: collectionAddress,
  })

  const { pages, pagesCount, currentPage, setCurrentPage } = usePagination({
    total: total,
    limits: {
      outer: 1,
      inner: 1,
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  })

  const handlePageChange = useCallback(
    (nextPage: number): void => {
      setPageIndex(nextPage)
      setCurrentPage(nextPage)
    },
    [setCurrentPage],
  )

  return (
    <div>
      <Box
        mt="30px"
        px={{
          base: '20px',
          lg: 0,
        }}
        pb={{
          base: '200px',
          lg: 0,
        }}
      >
        <Text
          fontSize={{
            base: '18px',
            lg: '28px',
          }}
          color="black.light"
          letterSpacing="-0.5px"
          lineHeight={{
            base: '21px',
            lg: '33px',
          }}
          fontWeight="700"
        >
          More from this collection
        </Text>

        <SimpleGrid
          columns={{
            base: 2,
            md: 3,
            lg: 3,
            xl: 5,
            '2xl': 6,
          }}
          spacing="20px"
          mt="28px"
        >
          {data.map((item) => {
            return <CollectionDetailItemCard key={item.id} {...item} />
          })}
        </SimpleGrid>

        {total !== 0 && (
          <Stack justify="center" w="100%" marginTop={10}>
            <Box>
              <PaginationComponent
                pagesCount={pagesCount}
                currentPage={currentPage}
                isDisabled={false}
                onPageChange={handlePageChange}
                pages={pages}
              />
            </Box>
          </Stack>
        )}
      </Box>
    </div>
  )
}

export default MoreNFTsSection
