/* eslint-disable max-len */
const resources = {
"src/components/BackButtonMobile.lang.json": import("~/components/BackButtonMobile.lang.json"),
"src/components/BottomBar.mobile.lang.json": import("~/components/BottomBar.mobile.lang.json"),
"src/components/Card.lang.json": import("~/components/Card.lang.json"),
"src/components/CollectionCard.lang.json": import("~/components/CollectionCard.lang.json"),
"src/components/Header.mobile.lang.json": import("~/components/Header.mobile.lang.json"),
"src/components/MainLayout/Header.lang.json": import("~/components/MainLayout/Header.lang.json"),
"src/components/MainLayout/Sidebar.lang.json": import("~/components/MainLayout/Sidebar.lang.json"),
"src/components/Pagination.lang.json": import("~/components/Pagination.lang.json"),
"src/container/ApprovalPurchaseModal/ApprovalPurchaseModal.lang.json": import("~/container/ApprovalPurchaseModal/ApprovalPurchaseModal.lang.json"),
"src/container/ChangePriceNFTModal/ChangePriceNFTModal.lang.json": import("~/container/ChangePriceNFTModal/ChangePriceNFTModal.lang.json"),
"src/container/CollectionDetailItemCard/CollectionDetailItemCard.lang.json": import("~/container/CollectionDetailItemCard/CollectionDetailItemCard.lang.json"),
"src/container/RemoveNFTModal/RemoveNFTModal.lang.json": import("~/container/RemoveNFTModal/RemoveNFTModal.lang.json"),
"src/container/SellNFTModal/SellNFTModal.lang.json": import("~/container/SellNFTModal/SellNFTModal.lang.json"),
"src/container/SendNFTModal/SendNFTModal.lang.json": import("~/container/SendNFTModal/SendNFTModal.lang.json"),
"src/views/CollectionDetail/Activities.mobile.lang.json": import("~/views/CollectionDetail/Activities.mobile.lang.json"),
"src/views/CollectionDetail/components/Activities/Activities.lang.json": import("~/views/CollectionDetail/components/Activities/Activities.lang.json"),
"src/views/CollectionDetail/components/FilterItems.lang.json": import("~/views/CollectionDetail/components/FilterItems.lang.json"),
"src/views/CollectionDetail/components/Header.mobile.lang.json": import("~/views/CollectionDetail/components/Header.mobile.lang.json"),
"src/views/CollectionDetail/components/Items.lang.json": import("~/views/CollectionDetail/components/Items.lang.json"),
"src/views/CollectionDetail/components/ReportInfo.lang.json": import("~/views/CollectionDetail/components/ReportInfo.lang.json"),
"src/views/CollectionDetail/DetailCollection.lang.json": import("~/views/CollectionDetail/DetailCollection.lang.json"),
"src/views/CollectionDetail/Items.mobile.lang.json": import("~/views/CollectionDetail/Items.mobile.lang.json"),
"src/views/Collections/Collections.lang.json": import("~/views/Collections/Collections.lang.json"),
"src/views/Home/components/Header.lang.json": import("~/views/Home/components/Header.lang.json"),
"src/views/Home/components/Launchpad/LaunchpadCard.lang.json": import("~/views/Home/components/Launchpad/LaunchpadCard.lang.json"),
"src/views/Home/components/Launchpad/LaunchpadCard.mobile.lang.json": import("~/views/Home/components/Launchpad/LaunchpadCard.mobile.lang.json"),
"src/views/Home/components/NewestCollections.lang.json": import("~/views/Home/components/NewestCollections.lang.json"),
"src/views/Home/components/TopSellers.lang.json": import("~/views/Home/components/TopSellers.lang.json"),
"src/views/Home/components/Trending.lang.json": import("~/views/Home/components/Trending.lang.json"),
"src/views/Home/Home.lang.json": import("~/views/Home/Home.lang.json"),
"src/views/NFTDetail/NFTDetail.lang.json": import("~/views/NFTDetail/NFTDetail.lang.json"),
"src/views/NFTSales/AllProjects.lang.json": import("~/views/NFTSales/AllProjects.lang.json"),
"src/views/NFTSales/Claim.lang.json": import("~/views/NFTSales/Claim.lang.json"),
"src/views/NFTSales/components/ButtonInfoNFT.lang.json": import("~/views/NFTSales/components/ButtonInfoNFT.lang.json"),
"src/views/NFTSales/components/ClaimCard.lang.json": import("~/views/NFTSales/components/ClaimCard.lang.json"),
"src/views/NFTSales/components/NFTCard.lang.json": import("~/views/NFTSales/components/NFTCard.lang.json"),
"src/views/NFTSales/components/OpeningCard.lang.json": import("~/views/NFTSales/components/OpeningCard.lang.json"),
"src/views/NFTSales/components/RefundCard.lang.json": import("~/views/NFTSales/components/RefundCard.lang.json"),
"src/views/NFTSales/components/Tag.lang.json": import("~/views/NFTSales/components/Tag.lang.json"),
"src/views/NFTSales/components/UpcomingCard.lang.json": import("~/views/NFTSales/components/UpcomingCard.lang.json"),
"src/views/NFTSales/DetailNFT.lang.json": import("~/views/NFTSales/DetailNFT.lang.json"),
"src/views/NFTSales/Refund.lang.json": import("~/views/NFTSales/Refund.lang.json"),
"src/views/Profile/components/ActivityItem.mobile.lang.json": import("~/views/Profile/components/ActivityItem.mobile.lang.json"),
"src/views/Profile/components/FavoriteTab.lang.json": import("~/views/Profile/components/FavoriteTab.lang.json"),
"src/views/Profile/components/MyItemCard.lang.json": import("~/views/Profile/components/MyItemCard.lang.json"),
"src/views/Profile/components/MyItems.lang.json": import("~/views/Profile/components/MyItems.lang.json"),
"src/views/Profile/components/OnSales.lang.json": import("~/views/Profile/components/OnSales.lang.json"),
"src/views/Profile/Profile.lang.json": import("~/views/Profile/Profile.lang.json"),
};

export default resources;
