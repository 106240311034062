import sample from 'lodash/sample'
import { configEnv } from '~/@config'
import Numeral from 'numeral'

const { BLOCK_CHAIN } = configEnv()

const formatPrice = (price: string) => {
  const [p1, p2] = price.split(/\./)
  if (!p2) {
    return p1
  }
  return `${p1}.${p2.substring(0, 3)}`
}

const compareAddress = (addressA: string, addressB: string) => {
  if (!addressA || !addressB) {
    return false
  }
  return addressA.toLowerCase().trim() === addressB.toLowerCase().trim()
}

const delayTime = (ms: number = 3000) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('Ok')
    }, ms)
  })
}

const nodes = [
  BLOCK_CHAIN.REACT_APP_NODE_1,
  BLOCK_CHAIN.REACT_APP_NODE_2,
  BLOCK_CHAIN.REACT_APP_NODE_3,
]
const getNodeUrl = () => {
  return sample(nodes)
}

const formattedNum = (number, usd = false, acceptNegatives = true) => {
  if (isNaN(number) || number === '' || number === undefined) {
    return usd ? '$0' : 0
  }
  let num = parseFloat(number)

  if (acceptNegatives && num > 500000000) {
    return (usd ? '$' : '') + toK(num.toFixed(0))
  }

  if (num === 0) {
    if (usd) {
      return '$0'
    }
    return 0
  }

  if (num < 0.0001 && num > 0) {
    return usd ? '< $0.0001' : '< 0.0001'
  }

  if (num > 1000) {
    return usd
      ? formatDollarAmount(num, 0)
      : Number(parseFloat(num + '').toFixed(0)).toLocaleString()
  }

  if (usd) {
    if (num < 0.1) {
      return formatDollarAmount(num, 4)
    } else {
      return formatDollarAmount(num, 4)
    }
  }

  return Number(parseFloat(num + '').toFixed(6)).toLocaleString()
}

const formatNumber = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

// using a currency library here in case we want to add more in future
const formatDollarAmount = (num, digits) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })
  return formatter.format(num)
}

const toK = (num) => {
  return Numeral(num).format('0.[00]a')
}

const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

// eslint-disable-next-line import/no-anonymous-default-export
export const blockChainHelper = {
  compareAddress,
  delayTime,
  formatPrice,
  getNodeUrl,
  nodes,
  formattedNum,
  formatNumber,
  formatDollarAmount,
  toK,
  isServer,
}
