
import Aurora from '~/assets/svgs/aurora.svg'
import Bsc from '~/assets/svgs/bsc.svg'
import Ethereum from '~/assets/svgs/eth.svg'
import Polygon from '~/assets/svgs/polygon.svg'
import { INetwork } from '~/dto'

export const NETWORKS: INetwork[] = [
    {
        code: 'BSC',
        title: 'BSC',
        icon: Bsc,
    },
    {
        code: 'ETHEREUM',
        title: 'Ethereum',
        icon: Ethereum,
    },
    {
        code: 'AURORA',
        title: 'Aurora',
        icon: Aurora,
    },
    {
        code: 'POLYGON',
        title: 'Polygon',
        icon: Polygon,
    },
]