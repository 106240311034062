import { Text } from '@chakra-ui/react'

const TransactionType = ({ type }: { type: string }) => {
  let color = '#1F9254'
  let text = 'Listing'

  switch (type) {
    case 'AskCancelListing':
      color = '#A30D11'
      text = 'Cancel'
      break
    case 'AskUpdatePrice':
      color = '#CD6200'
      text = 'Change price'
      break
    case 'AskSale':
      color = '#0052CD'
      text = 'Sale'
      break
    case 'BidCreated':
      color = '#1F9254'
      text = 'Created'
      break

    default:
      break
  }
  return (
    <Text color={color} fontSize="13px" lineHeight="15px" fontWeight="500">
      {text}
    </Text>
  )
}

export default TransactionType
