import { useRef, useState } from 'react'
import { Box, Text, SimpleGrid, HStack, Icon } from '@chakra-ui/react'
import Slider from 'react-slick'

import MainLayout from '~/components/MainLayout/MainLayout'
import UpcomingCard from './components/UpcomingCard'
import OpeningCard from './components/OpeningCard'
import useWindowSize from '~/hooks/useWindowSize'
import SecondaryButton from '~/components/SecondaryButton'

import { ReactComponent as ArrowRightIcon } from '~/assets/svgs/arrow-right.svg'
import { ReactComponent as ArrowLeftIcon } from '~/assets/svgs/arrow-left.svg'
import WrapperHeaderMobile from '../../NFTSales/components/WrapperHeaderMobile'
import { resourceProjects } from '~/hooks/v2/resources'
import { EStatus } from '~/common/enums'

export const AllProjects = () => {
  const { width } = useWindowSize()
  const [activeSlide, setActiveSlide] = useState(0)

  const refSlider = useRef<Slider>(null);
  const projectsOpening = resourceProjects.filter(item => item.status === EStatus.OPENING);
  const projectsUpcoming = resourceProjects.filter(item => item.status === EStatus.UPCOMING);


  return (
    <MainLayout>

      <WrapperHeaderMobile />

      <Box
        mr={{
          base: '20px',
          xl: '41px',
        }}
        ml={{
          base: '20px',
          xl: '47px',
        }}
        mt={{
          base: '14px',
          lg: '40px',
        }}
        mb={{
          base: '120px',
          lg: '40px',
        }}
      >
        <Box>
          <Text
            fontSize="28px"
            lineHeight="33px"
            color="black.light"
            fontWeight="600"
          >
            Opening
          </Text>
          <Box w="100%" overflow="hidden" mt={{ base: "50px", lg: "20px" }}>
            <Slider
              ref={refSlider}
              // autoplay={true}
              slidesToShow={1}
              slidesToScroll={1}
              dots={false}
              infinite={false}
              {...(width < 992 ? {} : { variableWidth: true })}
              beforeChange={(current, next) => {
                setActiveSlide(next)
              }}
              afterChange={() => { }}
            >
              {projectsOpening.map((item) => {
                return (
                  <Box key={item.collection}>
                    <Box
                      maxW={{
                        base: '100%',
                        lg: '860px',
                        // '2lg': '860px',
                        // '3lg': '918px',
                      }}
                      mr={{
                        base: '5px',
                        lg: '24px',
                      }}
                    >
                      <OpeningCard {...item} />
                    </Box>
                  </Box>
                )
              })}
            </Slider>
            <HStack
              mt={{
                base: '51px',
                lg: '18px',
              }}
              justifyContent={{
                base: 'center',
                lg: 'space-between',
              }}
            >
              <HStack>
                {projectsOpening.map((_, index) => {
                  return (
                    <Box
                      w={index === activeSlide ? '48px' : '24px'}
                      h="6px"
                      borderRadius="100px"
                      bg={index === activeSlide ? 'blue.darkest' : 'grey.c8'}
                      transition="all 250ms ease-in-out"
                    />
                  )
                })}
              </HStack>

              <HStack
                display={{
                  base: 'none',
                  lg: 'flex',
                }}
              >
                <SecondaryButton
                  borderRadius="50%"
                  onClick={refSlider.current?.slickPrev}
                  w="40px"
                  h="40px"
                  maxH="auto"
                  maxW="auto"
                  borderColor="white"
                  _disabled={{
                    bg: 'white',
                    borderColor: 'white',
                  }}
                >
                  <Icon
                    as={ArrowLeftIcon}
                    width="24px"
                    height="24px"
                    color="grey.c8"
                  />
                </SecondaryButton>
                <SecondaryButton
                  borderRadius="50%"
                  onClick={refSlider.current?.slickNext}
                  w="40px"
                  h="40px"
                  maxH="auto"
                  maxW="auto"
                  borderColor="white"
                  _disabled={{
                    bg: 'white',
                    borderColor: 'white',
                  }}
                >
                  <Icon as={ArrowRightIcon} width="20px" height="20px" />
                </SecondaryButton>
              </HStack>
            </HStack>
          </Box>
        </Box>

        <Box mt="77px">
          <Text
            fontSize="28px"
            lineHeight="33px"
            color="black.light"
            fontWeight="600"
          >
            Upcoming
          </Text>
          <SimpleGrid
            mt="24px"
            columns={{
              base: 1,
              md: 2,
              xl: 3,
              '3xl': 4,
            }}
            spacingX="12px"
            spacingY="20px"
          >
            {projectsUpcoming.map((item, idx) => {
              return <UpcomingCard key={idx} {...item} />
            })}
          </SimpleGrid>
        </Box>

      </Box>
    </MainLayout>
  )
}
