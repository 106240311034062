import { useCallback, useMemo, useState } from 'react'
import {
  Box,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  Menu as MenuCharkra,
  MenuButton,
  MenuList,
  MenuItem as MenuItemCharkra,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Flex,
  Divider,
  Avatar,
  Spacer,
  Image,
} from '@chakra-ui/react'
import { useWeb3React } from '@web3-react/core'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ChevronDownIcon } from '@chakra-ui/icons'

import { ReactComponent as SearchIcon } from '~/assets/svgs/search.svg'
import { ReactComponent as BNBIcon } from '~/assets/svgs/bnb.svg'
import { ReactComponent as BUSDIcon } from '~/assets/svgs/busd.svg'
import { ReactComponent as EnglishIcon } from '~/assets/svgs/english.svg'
import { ReactComponent as WalletIcon } from '~/assets/svgs/wallet.svg'
import { ReactComponent as CopyFilledIcon } from '~/assets/svgs/copy-filled.svg'

import ConnectWalletButton from '../ConnectWalletButton'
import { HOME_ROUTER, MARKET_PLACE_ROUTER, NEWS } from '~/routes/routes'
import { useTranslation } from 'react-i18next'
import useWindowSize from '~/hooks/useWindowSize'
import useListSearch from '~/hooks/Collections/useListSearch'
import { formatAddress, pipeAmount } from '~/utils'
import { useBlockChain } from '~/hooks/useBlockChain'
import { useConnectWallet } from '~/hooks/@global/useConnectWallet'
import { NETWORKS } from '~/common/constants/Networks'

const ButtonProfilePopover = () => {
  const { account } = useWeb3React()
  const { width } = useWindowSize()
  const { balanceBnb, balanceBusd } = useBlockChain()

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          bg="yellow.primary !important"
          h="auto"
          py="8px"
          borderRadius="12px"
          leftIcon={
            <Box
              w="36px"
              h="36px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bg="#FFEB3B"
              borderRadius="6px"
            >
              <Icon as={WalletIcon} w="24px" h="24px" />
            </Box>
          }
        >
          {width < 1025 ? (
            <></>
          ) : (
            <>
              {account?.substring(0, 4) ?? ''}...
              {account?.substring(account.length - 5) ?? ''}
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent w="300px">
        <PopoverArrow />
        <PopoverHeader borderBottomColor="transparent">
          <HStack>
            <Avatar
              size="60px"
              w="60px"
              h="60px"
              src="/assets/images/demo/avatar.png"
              borderWidth="6px"
              borderStyle="solid"
              borderColor="white"
            />
            <VStack w="100%" alignItems="flex-start">
              <HStack w="100%">
                <Text
                  fontSize="15px"
                  lineHeight="18px"
                  fontWeight="600"
                  color="black.1d"
                >
                  Unname
                </Text>
                <Spacer />
                <Link to="/profile">
                  <Text
                    fontSize="13px"
                    lineHeight="15px"
                    fontWeight="500"
                    color="yellow.dark"
                  >
                    View profile
                  </Text>
                </Link>
              </HStack>
              <HStack
                bg="blue.lighter"
                px="10px"
                borderRadius="30px"
                justifyContent="center"
                py="5px"
              >
                <Text
                  color="blue.neutral"
                  fontSize="16px"
                  lineHeight="19px"
                  letterSpacing="-0.5px"
                >
                  {formatAddress(account)}
                </Text>
                <Spacer />
                <Box
                  bg="blue.neutral"
                  w="24px"
                  h="24px"
                  borderRadius="18px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Icon w="14px" h="14px" as={CopyFilledIcon} />
                </Box>
              </HStack>
            </VStack>
          </HStack>
        </PopoverHeader>
        <PopoverBody>
          <Divider />
          <HStack w="100%" mb="20px" mt="20px" cursor="pointer">
            <HStack>
              <Icon as={BNBIcon} />
              <Text
                fontSize="14px"
                lineHeight="16px"
                fontWeight="400"
                color="black.1d"
              >
                {pipeAmount(balanceBnb)}
              </Text>
            </HStack>

            <HStack cursor="pointer">
              <Icon as={BUSDIcon} />
              <Text
                fontSize="14px"
                lineHeight="16px"
                fontWeight="400"
                color="black.1d"
              >
                {pipeAmount(balanceBusd)}
              </Text>
            </HStack>
          </HStack>
          <Divider />
          <Flex>
            <Link to="/profile">
              <Text
                fontSize="15px"
                lineHeight="18px"
                fontWeight="400"
                color="black.1d"
                mt="20px"
              >
                My Items
              </Text>
            </Link>
          </Flex>
          <Flex>
            <Link to="/profile?tabIndex=1">
              <Text
                fontSize="15px"
                lineHeight="18px"
                fontWeight="400"
                color="black.1d"
                mt="20px"
              >
                On Sales
              </Text>
            </Link>
          </Flex>
          <Flex>
            <Link to="/profile?tabIndex=2">
              <Text
                fontSize="15px"
                lineHeight="18px"
                fontWeight="400"
                color="black.1d"
                mt="20px"
              >
                Activities History
              </Text>
            </Link>
          </Flex>
          <Flex>
            <Link to="/profile?tabIndex=3">
              <Text
                fontSize="15px"
                lineHeight="18px"
                fontWeight="400"
                color="black.1d"
                my="20px"
              >
                Favorite
              </Text>
            </Link>
          </Flex>
          {/*
          <Text
          <Divider mt="20px" />
            mt="20px"
            mb="20px"
            fontSize="15px"
            lineHeight="18px"
            fontWeight="400"
            color="grey.66"
            textAlign="center"
          >
            Sign out
          </Text> */}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

const InputAutoFill = () => {
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const [valueSearch, setValueSearch] = useState('')
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { data } = useListSearch()
  const splitPageName = pathname.split('/')

  const isCollection = pathname.includes(MARKET_PLACE_ROUTER)

  const isShow = pathname.includes(NEWS);

  if (isShow) {
    return null;
  }

  return (
    <Box position={'relative'}>
      <InputGroup
        maxW={{
          lg: '300px',
          '2lg': '355px',
          xl: '475px',
        }}
        w={{
          lg: '300px',
          '2lg': '355px',
          xl: '475px',
        }}
        _focus={{ boxShadow: 'none' }}
      >
        <Input
          _focus={{ boxShadow: 'none' }}
          pr="4.5rem"
          type="text"
          bg="#EFF3F9 !important"
          placeholder={'Search collections'}
          onClick={() => {
            setIsOpenSearch(!isOpenSearch)
          }}
          value={valueSearch}
          onChange={(e) => setValueSearch(e.target.value.toLowerCase() || '')}
        />
        <InputRightElement width="4.5rem">
          <Icon as={SearchIcon} />
        </InputRightElement>
      </InputGroup>

      {isOpenSearch && (
        <Box
          position={'absolute'}
          bg="white"
          boxShadow="0px 0px 50px rgba(82, 63, 105, 0.15)"
          py="15px"
          w="100%"
          borderRadius="6px"
          maxH="304px"
          overflowY="auto"
          zIndex={30}
          onMouseLeave={() => {
            setIsOpenSearch(false)
          }}
        >
          <Text
            fontSize="15px"
            lineHeight="18px"
            color="black"
            fontWeight="500"
            px="20px"
          >
            Collections
          </Text>
          <VStack alignItems="flex-start" spacing="10px" mt="15px">
            {data
              .filter(
                (v) =>
                  v.name.toLowerCase().includes(valueSearch) ||
                  v.address.toLowerCase().includes(valueSearch),
              )
              .map((item, index) => {
                return (
                  <HStack
                    key={index}
                    cursor="pointer"
                    onClick={(e) => {
                      navigate(`${MARKET_PLACE_ROUTER}/${item.address}`)
                    }}
                    px="20px"
                    py="10px"
                    w="100%"
                    _hover={{
                      bg: 'rgba(199, 225, 255, 0.5)',
                    }}
                  >
                    <Image
                      h="40px"
                      w="40px"
                      src={item.image}
                      fallbackSrc="/assets/images/empty-item.png"
                      objectFit={'cover'}
                      borderRadius="8px"
                    />
                    <Text>{item.name}</Text>
                  </HStack>
                )
              })}
          </VStack>
        </Box>
      )}
    </Box>
  )
}

const MAPPING_ROUTER_TO_NAME = {
  [HOME_ROUTER]: 'home',
}

const Header = () => {
  const { t } = useTranslation('src/views/Home/components/Header.lang.json')
  const { account } = useWeb3React()
  const { pathname } = useLocation()
  const { width } = useWindowSize()
  const navigation = useNavigate()

  const { addChain, getCurrentCode } = useConnectWallet()
  const chainCurrentCode = getCurrentCode();

  const splitPageName = pathname.split('/')

  const nameHeader = useMemo(() => {
    if (splitPageName.length === 2) {
      if (splitPageName[1]) {
        const newName = splitPageName[1].replace("-", " ");
        return newName;
      } else {
        return "Home";
      }
    }
    if (splitPageName.length > 2) {
      if (splitPageName[1] === "news") {
        return splitPageName[1];
      } else {
        return splitPageName[2].replaceAll("-", " ");
      }
    }
    return "Home";
  }, [splitPageName])

  const renderChain = useCallback(() => {
    const chain = NETWORKS.find(item => item.code === chainCurrentCode);

    return (
      <MenuCharkra>
        {({ isOpen }) => (
          <>
            <MenuButton
              w="auto"
              px="0"
              isActive={isOpen}
              as={Button}
              rightIcon={<ChevronDownIcon />}
              bg="white !important"
            >
              <HStack>
                <Image
                  src={chain.icon}
                  w="22px"
                  h="22px"
                />
                <Text
                  color="secondary"
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="22px"
                >
                  {chain.title}
                </Text>
              </HStack>
            </MenuButton>
            <MenuList>
              {NETWORKS.map((child, index) => {
                return (
                  <MenuItemCharkra
                    key={index}
                    cursor="pointer"
                    onClick={async () => {
                      const { code } = child
                      await addChain(code);
                    }}
                  >
                    <HStack>
                      <Image
                        src={child.icon}
                        w="22px"
                        h="22px"
                      />
                      <Text>{child.title}</Text>
                    </HStack>
                  </MenuItemCharkra>
                )
              })}

            </MenuList>
          </>
        )}
      </MenuCharkra>
    )
  }, [addChain, chainCurrentCode])

  return (
    <Box w="100%" h="85px" bg="white" py="17px" px="50px">
      <HStack justifyContent="space-between" alignItems="center" w="100%">
        <VStack
          h="100%"
          justifyContent="center"
          spacing={0}
          alignItems="flex-start"
          cursor={"pointer"}
          onClick={() => {
            navigation("/")
          }}
        >
          <Text
            color="black.1d"
            lineHeight="31px"
            fontSize="22px"
            fontWeight="600"
            textTransform="capitalize"
          >
            {nameHeader}
          </Text>
        </VStack>

        <InputAutoFill />

        <HStack spacing="20px" alignItems="center">
          <MenuCharkra>
            {({ isOpen }) => (
              <>
                <MenuButton
                  w="auto"
                  px="0"
                  isActive={isOpen}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  bg="white !important"
                >
                  <Icon as={EnglishIcon} w="28px" h="19px" />
                </MenuButton>
                <MenuList>
                  <MenuItemCharkra>
                    <HStack>
                      <Icon as={EnglishIcon} w="28px" h="19px" />
                      <Text>English</Text>
                    </HStack>
                  </MenuItemCharkra>
                </MenuList>
              </>
            )}
          </MenuCharkra>

          {renderChain()}

          {account ? (
            <ButtonProfilePopover />
          ) : (
            <Box>
              <ConnectWalletButton
                h="auto"
                py="5px"
                leftIcon={
                  <Box
                    w="36px"
                    h="36px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bg="#FFEB3B"
                    borderRadius="6px"
                  >
                    <Icon as={WalletIcon} w="24px" h="24px" />
                  </Box>
                }
                hiddenText={width < 1280}
                w="auto"
                minW="auto"
                px={{
                  '2lg': 0,
                  '3lg': '9px',
                }}
                pl={{
                  '2lg': '8px',
                  '3lg': '9px',
                }}
              />
            </Box>
          )}
        </HStack>
      </HStack>
    </Box>
  )
}

export default Header
