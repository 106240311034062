import { IEnvConfig } from '..'

const config: IEnvConfig = {
  name: 'QA',
  DOMAIN_URL: 'https://qa-api-nft.famcentral.finance',
  DOMAIN_URL_API: "https://qa-api-nft.famcentral.finance",
  CONNECTORS: {
    ROOT: {
      baseUrl: 'https://qa-api-nft.famcentral.finance',
    },
  },
  CHAINS: {
    BSC: {
      chainId: 97,
      chainName: "bsc_testnet",
      nativeCurrency: {
        name: 'BNB',
        symbol: 'bnb',
        decimals: 18
      },
      rpcUrls: [
        "https://data-seed-prebsc-2-s1.binance.org:8545",
        "https://data-seed-prebsc-2-s1.binance.org:8545",
        "https://data-seed-prebsc-2-s1.binance.org:8545"
      ],
      blockExplorerUrls: ["https://testnet.bscscan.com"],
    },
    ETHEREUM: {
      chainId: 5,
      chainName: "testnet",
      nativeCurrency: {
        "name": "Görli Ether",
        "symbol": "GOR",
        "decimals": 18
      },
      rpcUrls: [
        "https://rpc.goerli.mudit.blog/",
      ],
      blockExplorerUrls: ["https://goerli.etherscan.io"],
    },
    AURORA: {
      chainId: 1313161555,
      chainName: "Aurora Testnet",
      "nativeCurrency": {
        "name": "Ether",
        "symbol": "aETH",
        "decimals": 18
      },
      rpcUrls: [
        "https://testnet.aurora.dev"
      ],
      blockExplorerUrls: ["https://testnet.aurorascan.dev"]
    },
    POLYGON: {
      chainId: 80001,
      chainName: "polygon_testnet",
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18
      },
      rpcUrls: [
        "https://data-seed-prebsc-2-s1.binance.org:8545",
        "https://data-seed-prebsc-2-s1.binance.org:8545",
        "https://data-seed-prebsc-2-s1.binance.org:8545"
      ],
      blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
    }
  },
  BLOCK_CHAIN: {
    REACT_APP_CHAIN_ID: '97',
    REACT_APP_GTAG: 'GTM-PXLD3XW',
    REACT_APP_NODE_1: 'https://data-seed-prebsc-2-s1.binance.org:8545',
    REACT_APP_NODE_2: 'https://data-seed-prebsc-2-s2.binance.org:8545',

    REACT_APP_NODE_3: 'https://data-seed-prebsc-2-s2.binance.org:8545',

    REACT_APP_BSCSCAN: 'https://testnet.bscscan.com',
    REACT_APP_GRAPH_API_PROFILE:
      'https://api.thegraph.com/subgraphs/name/pancakeswap/profile',
    REACT_APP_GRAPH_API_PREDICTION:
      'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction',
    REACT_APP_GRAPH_API_LOTTERY:
      'https://api.thegraph.com/subgraphs/name/pancakeswap/lottery',

    REACT_APP_SNAPSHOT_BASE_URL: 'https://testnet.snapshot.org',
    REACT_APP_SNAPSHOT_VOTING_API:
      'https://xtjyd0liqe.execute-api.ap-northeast-1.amazonaws.com/dev/api',
    REACT_APP_API_PROFILE: '',
  },
  ADDRESS_CONFIG: {
    WBNB: '0x0dE8FCAE8421fc79B29adE9ffF97854a424Cad09'.trim(),
    BUSD: '0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7'.trim(),
    USDT: '0x7ef95a0FEE0Dd31b22626fA2e10Ee6A223F8a684'.trim(),
    BICYCLE: '0x0dB7062d618e7Ed7ea79e4A2B273Cc375fc27dCe'.trim(),
    PANCAKE_FACTORY: '0x5Fe5cC0122403f06abE2A75DBba1860Edb762985'.trim(),
    PANCAKE_ROUTER: '0xCc7aDc94F3D80127849D2b41b6439b7CF1eB4Ae0'.trim(),
    FAM_TOKEN: '0x302e8CD8bb32628364F918a3775F0E599BA5770C',
    MARKET_V2_ADDRESS: "".trim(),
  },

  GOOGLE_CLIENT_ID: "1028163660284-uo2mcidbqlrvusbec3dm5nffc6d5ill7.apps.googleusercontent.com",
  MORALIS_API_CHAIN: 'bsc testnet',
  TRASH_WALLET_ADDRESS: '0xa003B365E113F7c35a98d451689969aF1E70E559',
  BNB_TOKEN_SYMBOL: 'BNB',
  ERC1155_TYPE: 'ERC1155',
  TOKEN_UTILS_POOL_ADDRESS: '0xb0E7089d2af365f33E0053d83aE97E7a441A5470',
  POOL_FAUCET: "",
  POOL_NFT_COLLECTIBLE: "0xF18df35D79BE0e43d164AC9207E59ECb92f60f65",
  POOL_NFT_TRIAL_1: "",
  POOL_NFT_TRIAL_2: "",
  POOL_NFT_TRIAL_3: "",
  POOL_NFT_GS25: "",
  POOL_NFT_HENIKEN: "",
  POOL_NFT_TRUNG_NGUYEN: "",
  POOL_FCOFFEE: "",
  POOL_REDDER: ""
}

export default config
