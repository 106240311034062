import { Flex, Icon, Text } from '@chakra-ui/react'
import { ReactComponent as VerifyIcon } from '~/assets/svgs/components/verify.svg'

const CollectionInfo = ({ name, symbol }: { name: string; symbol: string }) => {
  return (
    <Flex align="start">
      <Text
        zIndex={10}
        fontSize={{
          base: '18px',
          lg: '28px',
        }}
        color={{
          base: 'black.light',
          lg: 'black.light',
        }}
        lineHeight={{
          base: '21px',
          lg: '33px',
        }}
        letterSpacing="-0.5px"
        fontWeight="600"
        noOfLines={2}
      >
        {name} - {symbol}
      </Text>
      <Icon as={VerifyIcon} ml="5px" w="24px" h="24px" zIndex={10} />
    </Flex>
  )
}

export default CollectionInfo
