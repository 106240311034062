import { Box, CloseButton, HStack, Text, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import PrimaryButton from '~/components/PrimaryButton'
import useWindowSize from '~/hooks/useWindowSize'

import BulkBuySection from './BulkBuySection/BulkBuySection'

const FooterReviewBulkBuy = ({
  selectedList,
  onRemoveItem,
  removeAllItem,
}: {
  selectedList: any
  onRemoveItem: any
  removeAllItem: any
}) => {
  const { width, height } = useWindowSize()
  const [isShowReview, setIsShowReview] = useState(false)
  const total = selectedList.reduce((current, next) => {
    return current + Number(next.price)
  }, 0)
  return (
    <Box
      position="fixed"
      bottom={0}
      right={0}
      left={0}
      background="white"
      shadow="0px -4px 16px rgba(0, 0, 0, 0.1)"
      paddingBottom="20px"
      px="20px"
      pt="15px"
      zIndex={101}
    >
      <HStack justify="end" mb="17px">
        <CloseButton onClick={removeAllItem} />
      </HStack>
      <HStack justify="space-between" align="start">
        <VStack align="start">
          <Text
            fontSize="14px"
            lineHeight="16px"
            color="black.1d"
            letterSpacing="-0.5px"
          >
            Buy multiple items ({selectedList?.length})
          </Text>
          <Text
            fontSize="14px"
            lineHeight="16px"
            color="black.1d"
            letterSpacing="-0.5px"
          >
            Total{' '}
            <Text fontWeight="600" as="span">
              {total}
            </Text>
          </Text>
        </VStack>

        <Box>
          <PrimaryButton onClick={() => setIsShowReview(true)} px="27px">
            Review order
          </PrimaryButton>
        </Box>
      </HStack>

      {isShowReview && (
        <Box
          position="fixed"
          bottom={0}
          left={0}
          right={0}
          zIndex={20}
          background="white"
          shadow="0px -4px 16px rgba(0, 0, 0, 0.1)"
          borderTopRadius="20px"
          paddingBottom="20px"
          maxH={height}
          overflowY="auto"
        >
          <BulkBuySection
            onRemoveItem={onRemoveItem}
            onClose={() => {
              setIsShowReview(false)
            }}
            listBuys={selectedList}
          />
        </Box>
      )}
    </Box>
  )
}

export default FooterReviewBulkBuy
