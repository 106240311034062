import { useQuery } from '@tanstack/react-query'

import NFTMarketPlaceServices from '~/services/nft.marketplace.services'

interface ICollectionInfo {
  address: string
  createdBy: null
  createdDate: string
  description: string
  id: string
  iframe: string
  image: string
  name: string
  sosials: Array<{
    link: string
    logo: string
    type: string
  }>
  status: number
  symbol: string
  updatedBy: null
}

const useCollectionInfo = (variables) => {
  const results = useQuery<ICollectionInfo>(
    ['collectionInfo', variables],
    async () => NFTMarketPlaceServices.collectionInfo(variables),
  )
  return { ...results, data: results?.data }
}

export default useCollectionInfo
