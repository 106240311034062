import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, SimpleGrid, Text, VStack, } from '@chakra-ui/react';

import useWindowSize from '~/hooks/useWindowSize';
import MainLayout from '~/components/MainLayout/MainLayout';
import WrapperHeaderMobile from '~/views/NFTSales/components/WrapperHeaderMobile';
import { ABOUT_DATA_1, ABOUT_DATA_2, ABOUT_DATA_MOBILE } from './data';

const TextValue = (props: { text: string }) => {
    const { text } = props;
    return (
        <Text
            fontSize={{
                base: "16px",
            }}
            lineHeight={{
                base: "24px",
            }}
            fontWeight="400"
            color={"secondary"}
            py="5px"
            borderRadius="12px"
            background={{
                md: "#F3F4F7"
            }}
        >
            <span
                style={{ whiteSpace: "pre-line" }}
                dangerouslySetInnerHTML={{ __html: text }}
            />
        </Text>
    )
}

export const AboutNft = () => {
    const { width } = useWindowSize();

    const CourseCurriculumContainer = (props: { title: string, desciption: string[] }) => {
        return (
            <AccordionItem
                border="none"
                background="#F2F2F2"
                padding="8px 14px"
                borderRadius={"6px"}
                mt="10px"
            >
                <AccordionButton
                    _hover={{
                        background: "transparent"
                    }}
                    gap={4}
                    alignItems="start"
                >
                    <AccordionIcon />
                    <Box flex='1' textAlign='left' h={{ base: "auto", }}>
                        <Text
                            fontSize={"16px"}
                            lineHeight="151.18%"
                            color={"272727"}
                        >
                            <strong>
                                {props.title}
                            </strong>
                        </Text>
                    </Box>
                </AccordionButton>

                <AccordionPanel
                    pb={4}
                >
                    {props.desciption.map((item, idx) => {
                        return (
                            <TextValue text={item} key={idx} />
                        )
                    })}
                </AccordionPanel>
            </AccordionItem>
        )
    }

    const renderAbout1 = () => {
        return (
            <VStack alignItems="left" spacing={6}>
                <Accordion
                    allowMultiple
                    defaultIndex={[0]}
                >
                    {ABOUT_DATA_1.map((item, idx) => {
                        return (
                            <CourseCurriculumContainer {...item} key={idx} />
                        )
                    })}
                </Accordion>
            </VStack>
        )
    }

    const renderAbout2 = () => {
        return (
            <VStack alignItems="left" spacing={6}>
                <Accordion
                    allowMultiple
                >
                    {ABOUT_DATA_2.map((item, idx) => {
                        return (
                            <CourseCurriculumContainer {...item} key={idx} />
                        )
                    })}
                </Accordion>
            </VStack>
        )
    }

    const renderAboutMobile = () => {
        return (
            <VStack alignItems="left" spacing={6}>
                <Accordion
                    allowMultiple
                    defaultIndex={[0]}
                >
                    {ABOUT_DATA_MOBILE.map((item, idx) => {
                        return (
                            <CourseCurriculumContainer {...item} key={idx} />
                        )
                    })}
                </Accordion>
            </VStack>
        )
    }

    return (
        <MainLayout>

            <WrapperHeaderMobile />

            <Box
                pl={{
                    base: '10px',
                    md: '35px',
                    xl: '50px',
                }}
                pr={{
                    base: '10px',
                    md: '26px',
                }}
                mt="26px"
                overflow="hidden"
                mb="150px"
            >
                <Box
                    paddingX={{
                        '2xl': "150px"
                    }}
                    zIndex={1}
                    position="relative"
                >

                    <Box mt={{ base: "50px", lg: "32px" }}>

                        <SimpleGrid
                            columns={{
                                lg: 2,
                                xl: 2
                            }}
                            w="full"
                            spacing={4}
                            bg="#fff"
                            borderRadius="24px"
                            p="24px"
                        >

                            {width <= 920 ?
                                renderAboutMobile()
                                :
                                <>
                                    {renderAbout1()}
                                    {renderAbout2()}
                                </>
                            }

                        </SimpleGrid>

                    </Box>

                </Box>
            </Box>

        </MainLayout>
    )
}