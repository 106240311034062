import { Box, HStack, Icon, Stack, Text } from '@chakra-ui/react'
import { useWeb3React } from '@web3-react/core'

import ConnectWalletButtonSecondary from '~/components/ConnectWalletButtonSecondary'
import PrimaryButton from '~/components/PrimaryButton'
import SecondaryButton from '~/components/SecondaryButton'
import { compareAddress } from '~/utils'

import { ReactComponent as MarketIcon } from '~/assets/svgs/market.svg'
import { ReactComponent as SendIcon } from '~/assets/svgs/send.svg'
import { ReactComponent as TagIcon } from '~/assets/svgs/tag.svg'

import { ReactComponent as WalletIcon } from '~/assets/svgs/wallet.svg'
import { useTranslation } from 'react-i18next'

const GroupButton = ({
  onOpenBuyNFTModal,
  onOpenSendModal,
  onOpenSellModal,
  data,
  onOpenChangePriceModal,
  onOpenRemoveNFTModal,
}) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()

  const renderActionButton = () => {
    const isOwner = compareAddress(account, data?.seller)

    if (!account) {
      return <ConnectWalletButtonSecondary name={t('Connect Wallet')} />
    }
    if (!isOwner) {
      return (
        <Box>
          <PrimaryButton
            mt={{
              base: '0',
            }}
            h="auto"
            w="100%"
            maxH="auto"
            bg="yellow.primary !important"
            border="2px solid yellow.primary"
            borderRadius="12px"
            fontSize={{
              base: '18px',
              lg: '20px',
            }}
            color="black.light"
            py="5px"
            fontWeight="500"
            onClick={onOpenBuyNFTModal}
          >
            <HStack justifyContent="center">
              <Icon
                as={WalletIcon}
                w={{
                  base: '30px',
                  lg: '35px',
                }}
                h={{
                  base: '30px',
                  lg: '35px',
                }}
              />
              <Text>{t('Buy now')}</Text>
            </HStack>
          </PrimaryButton>
          <Text
            mt="26px"
            fontSize="12px"
            lineHeight="24px"
            fontWeight="500"
            letterSpacing="-0.5px"
            color="grey.97"
          >
            By clicking “Buy now”, you agree to the{' '}
            <Text color="blue.neutral" as="span">
              Terms of service{' '}
            </Text>
          </Text>
        </Box>
      )
    }

    const isSell = data?.status === 'LISTING'

    if (!isSell) {
      return (
        <HStack w="100%" flexWrap="wrap">
          <Box flex={1}>
            <SecondaryButton
              mt={{
                base: 0,
              }}
              h="auto"
              w="100%"
              maxH="auto"
              bg="white !important"
              border="2px solid #DFE6F1"
              borderRadius="12px"
              fontSize={{
                base: '18px',
                lg: '20px',
              }}
              color="black.light"
              py="5px"
              onClick={onOpenSendModal}
            >
              <HStack justifyContent="center">
                <Icon
                  as={SendIcon}
                  w={{
                    base: '30px',
                    lg: '35px',
                  }}
                  h={{
                    base: '30px',
                    lg: '35px',
                  }}
                />
                <Text>{t('send')}</Text>
              </HStack>
            </SecondaryButton>
          </Box>
          <Box flex={1}>
            <PrimaryButton
              mt={{
                base: '0',
              }}
              h="auto"
              w="100%"
              maxH="auto"
              bg="yellow.primary !important"
              border="2px solid yellow.primary"
              borderRadius="12px"
              fontSize={{
                base: '18px',
                lg: '20px',
              }}
              color="black.light"
              py="5px"
              onClick={onOpenSellModal}
            >
              <HStack justifyContent="center">
                <Icon
                  as={TagIcon}
                  w={{
                    base: '30px',
                    lg: '35px',
                  }}
                  h={{
                    base: '30px',
                    lg: '35px',
                  }}
                />
                <Text>{t('sell')}</Text>
              </HStack>
            </PrimaryButton>
          </Box>
        </HStack>
      )
    }

    return (
      <Stack
        flexDirection={{
          base: 'column',
          md: 'row',
        }}
        spacing={0}
        flexWrap="wrap"
      >
        <Box
          flex={1}
          mr={{
            md: '10px',
          }}
        >
          <SecondaryButton
            mt={{
              base: 5,
              md: 0,
              lg: '20px',
            }}
            h="auto"
            w="100%"
            maxH="auto"
            bg="white !important"
            fontSize={{
              base: '18px',
              lg: '20px',
            }}
            color="black.light"
            py="5px"
            textTransform="none"
            onClick={onOpenRemoveNFTModal}
          >
            <HStack justifyContent="center">
              <Icon
                as={MarketIcon}
                w={{
                  base: '30px',
                  lg: '35px',
                }}
                h={{
                  base: '30px',
                  lg: '35px',
                }}
              />
              <Text>{t('remove_from_market')}</Text>
            </HStack>
          </SecondaryButton>
        </Box>
        <Box flex={1}>
          <PrimaryButton
            mt={{
              base: '10px',
              md: 0,
              lg: '20px',
            }}
            h="auto"
            w="100%"
            maxH="auto"
            fontSize={{
              base: '18px',
              lg: '20px',
            }}
            py="5px"
            textTransform="none"
            onClick={onOpenChangePriceModal}
          >
            <HStack justifyContent="center">
              <Icon
                as={TagIcon}
                w={{
                  base: '30px',
                  lg: '35px',
                }}
                h={{
                  base: '30px',
                  lg: '35px',
                }}
              />
              <Text>{t('change_price')}</Text>
            </HStack>
          </PrimaryButton>
        </Box>
      </Stack>
    )
  }

  return (
    <Box
      display={{
        base: 'none',
        lg: 'block',
      }}
      marginTop="25px"
    >
      {renderActionButton()}
    </Box>
  )
}

export default GroupButton
