import { FC } from 'react'

import {
  ModalOverlay,
  Modal,
  ModalContent,
  ModalCloseButton,
  HStack,
  Text,
  ModalHeader,
  Box,
  ModalBody,
  SimpleGrid,
  Icon,
  Divider,
  Image,
} from '@chakra-ui/react'

import {
  connectorLocalStorageKey,
  walletLocalStorageKey,
  WalletConfig as config,
  ConnectorNames,
} from '~/common/constants'

interface IConnectorWalletModal {
  isOpen?: boolean
  onDismiss?: () => void
  login?: any
}

const ConnectorWalletModal: FC<IConnectorWalletModal> = ({
  login,
  isOpen,
  onDismiss: onClose = () => {
    return null
  },
}) => {
  return (
    <Modal isOpen={isOpen || false} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxWidth="642px" w="100%" borderRadius="12px" mx="15px">
        <ModalHeader bg="#FFFFFF" borderRadius="12px" />
        <ModalCloseButton bg="#FFF" />
        <ModalBody bg="#FFFFFF" px="15px" borderRadius="12px">
          <Box>
            <Text
              fontSize="22px"
              lineHeight="27px"
              color="secondary"
              fontWeight="bold"
              textAlign="center"
              textTransform="uppercase"
            >
              Connect to a wallet
            </Text>
          </Box>
          <Divider mt="27px" mb="20px" />
          <SimpleGrid
            columns={{
              md: 2,
              lg: 2,
              xl: 2,
              '2xl': 2,
            }}
            spacing={2}
          >
            {config.map(({ title, icon, image, connectorId }) => (
              <Box
                cursor="pointer"
                key={title}
                onClick={() => {
                  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

                  // Since iOS does not support Trust Wallet we fall back to WalletConnect

                  if (title === 'Trust Wallet' && isIOS) {
                    login(ConnectorNames.WalletConnect)
                  } else {
                    login(connectorId)
                  }
                  localStorage.setItem(walletLocalStorageKey, title)
                  localStorage.setItem(connectorLocalStorageKey, connectorId)
                  onClose()
                }}
                bg="white"
                role="group"
              >
                <HStack
                  bg="#FAFCFE"
                  borderRadius="8px"
                  color="secondary"
                  border="1px solid #f5f5f5"
                  _hover={{
                    bg: '#F2F2F2',
                    color: 'subText',
                    borderRadius: '8px',
                  }}
                  alignItems="center"
                  px={{
                    base: '20px',
                    md: '25px',
                  }}
                  py="4px"
                  spacing={{
                    base: '5px',
                    md: '12px',
                  }}
                >
                  <Box>
                    {image ? (
                      <Image src={image} w="64px" h="64px" />
                    ) : (
                      <Icon as={icon} fontSize="64px" />
                    )}
                  </Box>
                  <Text
                    fontSize="16px"
                    lineHeight="19px"
                    fontWeight="bold"
                    pl="20px"
                  >
                    {title}
                  </Text>
                </HStack>
              </Box>
            ))}
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ConnectorWalletModal
