import { useState } from 'react'
import { Box, Flex, HStack, Text } from '@chakra-ui/react'
import { ReactComponent as ApprovalIcon } from '~/assets/svgs/approval-icon.svg'

import Sidebar from './Sidebar'
import Header from './Header'
import BottomBarMobile from '../BottomBar.mobile'
import ApprovalPurchaseModal from '~/container/ApprovalPurchaseModal/ApprovalPurchaseModal'
import { useMarket } from '~/hooks/market'

const MainLayout = ({ children }) => {
  const { isLoading, isApproveBusd } = useMarket()
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Flex overflow="hidden" h="100vh" w="100%" maxW="100vw">
      <Box
        h="100vh"
        display={{
          base: 'none',
          lg: 'block',
        }}
      >
        <Sidebar />
      </Box>
      <Box
        bg={{
          base: 'white',
          lg: 'antiFlashWhite',
        }}
        overflow="hidden"
        w="100%"
        flex={1}
      >
        <Box
          display={{
            base: 'none',
            lg: 'block',
          }}
        >
          <Header />
          {/* {!isLoading && !isApproveBusd && (
            <HStack
              bg="blue.neutral"
              py="6px"
              justifyContent="center"
              cursor="pointer"
              onClick={() => {
                setIsOpen(true)
              }}
            >
              <ApprovalIcon />
              <Text
                fontSize="13px"
                color="white"
                lineHeight="26px"
                fontWeight="600"
              >
                Please approve token to gain access to purchase NFTs (You only
                have to approve once)
              </Text>
            </HStack>
          )} */}
        </Box>
        <Box
          w="100%"
          h={{
            base: '100%',
            md: 'calc(100% - 85px)',
          }}
          overflowY="auto"
          overflowX="hidden"
        >
          {children}
        </Box>
        <Box
          position="fixed"
          bottom={0}
          left={0}
          right={0}
          zIndex={20}
          display={{
            base: 'block',
            lg: 'none',
          }}
        >
          <BottomBarMobile />
        </Box>
      </Box>
      {/* <ApprovalPurchaseModal isOpen={isOpen} onClose={() => setIsOpen(false)} /> */}
    </Flex>
  )
}

export default MainLayout
