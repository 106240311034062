import { CloseIcon } from '@chakra-ui/icons'
import { HStack, IconButton, Image, Text, VStack } from '@chakra-ui/react'
import React, { FC } from 'react'
import { ReactComponent as BNBIcon } from '~/assets/svgs/bnb.svg'
import { formatMoney } from '~/utils'

const BulkBuyItem: FC<{
  item: {
    address: string
    askId: string
    createdBy: null
    createdDate: string
    description: string
    exactPrice: string
    id: string
    image: string
    symbol: string
    name: string
    price: string
    seller: string
    status: string
    tokenId: string
    updatedBy: null
    updatedDate: string
    version: string
    collectionName?: string
  }
  onRemoveItem: () => void
}> = ({ item, onRemoveItem }) => {
  console.log('====================================')
  console.log(item.price)
  console.log('====================================')
  return (
    <HStack px="20px" spacing="13px" py="15px" justify="space-between">
      <HStack spacing="12px">
        <Image
          src={item.image}
          w="40px"
          h="40px"
          borderRadius="4px"
          backgroundColor="white"
          filter="drop-shadow(-3px 3px 8px rgba(0, 0, 0, 0.06)) drop-shadow(3px 3px 8px rgba(0, 0, 0, 0.06))"
        />
        <VStack align="start">
          <Text
            fontSize="14px"
            lineHeight="16px"
            letterSpacing="-0.5px"
            color="black.1d"
            noOfLines={1}
          >
            {item.name}
          </Text>
          <Text
            fontSize="12px"
            lineHeight="14px"
            letterSpacing="-0.5px"
            color="grey.97"
            noOfLines={1}
          >
            {item.name} <Text as="span">#{item.tokenId}</Text>
          </Text>
        </VStack>
      </HStack>

      <HStack spacing="27px">
        <HStack>
          <BNBIcon />
          <Text
            color="#1D47A1"
            fontSize="14px"
            lineHeight="20px"
            letterSpacing="-0.5px"
            fontWeight="600"
          >
            {item.price}
          </Text>
        </HStack>

        <IconButton
          aria-label=""
          size="20px"
          borderRadius="20px"
          background="#E0E0E0"
          icon={<CloseIcon width="8px" height="8px" />}
          onClick={() => onRemoveItem()}
          padding="7px"
        />
      </HStack>
    </HStack>
  )
}

export default BulkBuyItem
