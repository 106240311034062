import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import sample from 'lodash/sample'
import { configEnv, IChainConfig, ICodeChainList } from '~/@config';
const { CHAINS } = configEnv();


enum EConnectorId {
  Injected = "injected",
  WalletConnect = "walletconnect",
}

const ChainLocalKey = {
  connectorId: "connectorId",
  chainCode: "chainCode",
  walletTitle: "walletTitle",
  accountActive: "accountActive"
}

const chainIds: number[] = Object.values(CHAINS).map((item) => (item as IChainConfig).chainId);
const chainCodes = Object.keys(CHAINS) as ICodeChainList[];
const listChain = Object.keys(CHAINS).map(chainCode => {
  return {
    ...CHAINS[chainCode],
    chainCode
  }
}) as (IChainConfig & { chainCode: ICodeChainList })[];



const getUrlRpc = () => {
  const currentChainCode = localStorage.getItem(ChainLocalKey.chainCode) || "";
  if (!currentChainCode) {
    return "";
  }
  return sample(CHAINS[currentChainCode].rpcUrls)
}

const getChainId = () => {
  const currentChainCode = localStorage.getItem(ChainLocalKey.chainCode) || "";
  if (!currentChainCode) {
    return 56;
  }
  return CHAINS[currentChainCode].chainId
}

const getUrlScan = () => {
  const currentChainCode = localStorage.getItem(ChainLocalKey.chainCode) || "";
  if (!currentChainCode) {
    return "";
  }
  return sample(CHAINS[currentChainCode].blockExplorerUrls)
}

const rpcByWalletConnect = chainCodes.reduce((result, code) => {
  const { chainId, blockExplorerUrls, rpcUrls } = CHAINS[code];
  const rpcUrl = sample(rpcUrls);
  Object.assign(result, {
    [`${chainId}`]: rpcUrl || ""
  })
  return result;
}, {})




const connectorsByName = {
  [EConnectorId.Injected]: new InjectedConnector({ supportedChainIds: chainIds }),
  [EConnectorId.WalletConnect]: new WalletConnectConnector({
    rpc: rpcByWalletConnect,
    bridge: 'https://pancakeswap.bridge.walletconnect.org/',
    qrcode: true,
    // pollingInterval: POLLING_INTERVAL,
  })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  EConnectorId,
  ChainLocalKey,
  chainIds,
  chainCodes,
  getUrlRpc,
  getUrlScan,
  getChainId,
  connectorsByName,
  listChain,
}
