import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Flex,
  Divider,
  Avatar,
  VStack,
  Image,
  useOutsideClick,
  MenuButton,
  MenuList,
  Menu as MenuCharkra,
  MenuItem as MenuItemCharkra,
} from '@chakra-ui/react'
import { useWeb3React } from '@web3-react/core'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { ReactComponent as LogoCircleIcon } from '~/assets/svgs/logo-circle.svg'
import LogoIcon from '~/assets/svgs/Logo.png'
import { ReactComponent as SearchIcon } from '~/assets/svgs/search.svg'
import { ReactComponent as WalletIcon } from '~/assets/svgs/wallet.svg'
import { ReactComponent as CopyFilledIcon } from '~/assets/svgs/copy-filled.svg'

import BNBIcon from './Icons/BNB'
import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useListSearch from '~/hooks/Collections/useListSearch'
import { useBlockChain } from '~/hooks/useBlockChain'
import { formatAddress, pipeAmount } from '~/utils'
import { MARKET_PLACE_ROUTER } from '~/routes/routes'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { NETWORKS } from '~/common/constants/Networks'
import { useConnectWallet } from '~/hooks/@global/useConnectWallet'

const countries = [
  { value: 'ghana', label: 'Ghana' },
  { value: 'nigeria', label: 'Nigeria' },
  { value: 'kenya', label: 'Kenya' },
  { value: 'southAfrica', label: 'South Africa' },
  { value: 'unitedStates', label: 'United States' },
  { value: 'canada', label: 'Canada' },
  { value: 'germany', label: 'Germany' },
]

const HeaderMobile = ({ isHideSearch = false }) => {
  const navigate = useNavigate()
  const { t } = useTranslation('src/components/Header.mobile.lang.json')
  const { account } = useWeb3React()
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const [valueSearch, setValueSearch] = useState('')
  const { data } = useListSearch()
  const ref = useRef()

  const { addChain, getCurrentCode } = useConnectWallet()
  const chainCurrentCode = getCurrentCode();

  useOutsideClick({
    ref: ref,
    handler: () => setIsOpenSearch(false),
  })

  const { balanceBnb, balanceBusd } = useBlockChain()

  const [pickerItems, setPickerItems] = useState(countries)
  const [selectedItems, setSelectedItems] = useState([])

  const handleCreateItem = (item) => {
    setPickerItems((curr) => [...curr, item])
    setSelectedItems((curr) => [...curr, item])
  }

  const handleSelectedItemsChange = (selectedItems) => {
    if (selectedItems) {
      setSelectedItems(selectedItems)
    }
  }

  const renderChain = useCallback(() => {
    const chain = NETWORKS.find(item => item.code === chainCurrentCode);

    return (
      <MenuCharkra>
        {({ isOpen }) => (
          <>
            <MenuButton
              w="full"
              px="0"
              isActive={isOpen}
              as={Button}
              rightIcon={<ChevronDownIcon />}
              bg="white !important"
            >
              <HStack pr="10px">
                <Image
                  src={chain.icon}
                  w="16px"
                  h="16px"
                />
                <Text
                  color="secondary"
                  fontWeight="600"
                  fontSize="13px"
                  lineHeight="16px"
                >
                  {chain.title}
                </Text>
              </HStack>
            </MenuButton>
            <MenuList w="fit-content" style={{ minWidth: 'fit-content' }}>
              {NETWORKS.map((child, index) => {
                return (
                  <MenuItemCharkra
                    key={index}
                    cursor="pointer"
                    onClick={async () => {
                      const { code } = child
                      await addChain(code);
                    }}
                  >
                    <HStack>
                      <Image
                        src={child.icon}
                        w="16px"
                        h="16px"
                      />
                      <Text>{child.title}</Text>
                    </HStack>
                  </MenuItemCharkra>
                )
              })}

            </MenuList>
          </>
        )}
      </MenuCharkra>
    )
  }, [addChain, chainCurrentCode])

  return (
    <Box ref={ref}>
      <HStack w="100%" px="5px">
        <HStack w="100%">
          <Image
            src={LogoIcon}
            w="50px"
            h="50px"
            onClick={() => navigate('/')}
          />
          {!isHideSearch && (
            <Box position={'relative'} w={isOpenSearch ? '100%' : 'auto'}>
              <InputGroup
                maxW={{
                  '320': isOpenSearch ? '100%' : '110px',
                  '375': isOpenSearch ? '100%' : '165px',
                  md: '300px',
                }}
                w={{
                  base: isOpenSearch ? '100%' : '171px',
                  md: '300px',
                }}
              >
                <Input
                  type="text"
                  bg="#EFF3F9 !important"
                  placeholder="Search collections"
                  fontSize="12px"
                  lineHeight="14px"
                  onFocus={() => {
                    setIsOpenSearch(true)
                  }}
                  // onBlur={() => {
                  //   setIsOpenSearch(false)
                  // }}
                  value={valueSearch}
                  onChange={(e) =>
                    setValueSearch(e.target.value.toLowerCase() || '')
                  }
                />
                <InputRightElement>
                  <Icon as={SearchIcon} w="24px" />
                </InputRightElement>
              </InputGroup>

              {isOpenSearch && (
                <Box
                  position={'absolute'}
                  bg="white"
                  boxShadow="0px 0px 50px rgba(82, 63, 105, 0.15)"
                  px="20px"
                  py="15px"
                  w="100%"
                  borderRadius="6px"
                  maxH="304px"
                  overflowY="auto"
                >
                  <Text
                    fontSize="15px"
                    lineHeight="18px"
                    color="black"
                    fontWeight="500"
                  >
                    {t('collections')}
                  </Text>
                  <VStack alignItems="flex-start" spacing="10px" mt="15px">
                    {data
                      .filter(
                        (v) =>
                          v.name.toLowerCase().includes(valueSearch) ||
                          v.address.toLowerCase().includes(valueSearch),
                      )
                      .map((item, index) => {
                        return (
                          <HStack
                            key={index}
                            w="100%"
                            cursor="pointer"
                            onClick={(e) => {
                              navigate(`${MARKET_PLACE_ROUTER}/${item.address}`)
                            }}
                            _hover={{
                              bg: 'rgba(199, 225, 255, 0.5)',
                            }}
                          >
                            <Image
                              onClick={(e) => {
                                navigate(
                                  `${MARKET_PLACE_ROUTER}/${item.address}`,
                                )
                              }}
                              h="40px"
                              w="40px"
                              src={item.image}
                              fallbackSrc="/assets/images/empty-item.png"
                              objectFit={'cover'}
                              borderRadius="8px"
                            />
                            <Text
                              onClick={(e) => {
                                navigate(
                                  `${MARKET_PLACE_ROUTER}/${item.address}`,
                                )
                              }}
                            >
                              {item.name}
                            </Text>
                          </HStack>
                        )
                      })}
                  </VStack>
                </Box>
              )}
            </Box>
          )}
        </HStack>

        {!isOpenSearch && <Spacer />}
        {!isOpenSearch && (
          <HStack>

            {renderChain()}

            <Box>
              {account ? (
                <Popover>
                  <PopoverTrigger>
                    <Button
                      bg="yellow.primary !important"
                      w="32px"
                      h="32px"
                      minWidth="auto"
                      borderRadius="6px"
                      mx="auto"
                    >
                      <Icon as={WalletIcon} w="20px" h="20px" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent w="300px">
                    <PopoverArrow />
                    <PopoverHeader borderBottomColor="transparent">
                      <HStack>
                        <Avatar
                          size="60px"
                          w="60px"
                          h="60px"
                          src="/assets/images/demo/avatar.png"
                          borderWidth="6px"
                          borderStyle="solid"
                          borderColor="white"
                        />
                        <VStack w="100%" alignItems="flex-start">
                          <HStack w="100%">
                            <Text
                              fontSize="15px"
                              lineHeight="18px"
                              fontWeight="600"
                              color="black.1d"
                            >
                              Unname
                            </Text>
                            <Spacer />
                            <Link to="/profile">
                              <Text
                                fontSize="13px"
                                lineHeight="15px"
                                fontWeight="500"
                                color="yellow.dark"
                              >
                                View profile
                              </Text>
                            </Link>
                          </HStack>
                          <HStack
                            bg="#C7E1FF"
                            px="10px"
                            borderRadius="30px"
                            justifyContent="center"
                            py="5px"
                          >
                            <Text
                              color="blue.neutral"
                              fontSize="16px"
                              lineHeight="19px"
                              letterSpacing="-0.5px"
                            >
                              {formatAddress(account)}
                            </Text>
                            <Spacer />
                            <Box
                              bg="blue.neutral"
                              w="24px"
                              h="24px"
                              borderRadius="18px"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Icon w="14px" h="14px" as={CopyFilledIcon} />
                            </Box>
                          </HStack>
                        </VStack>
                      </HStack>
                    </PopoverHeader>
                    <PopoverBody>
                      <Divider />
                      <HStack w="100%" mb="20px" mt="20px">
                        <HStack>
                          <Icon as={BNBIcon} />
                          <Text
                            fontSize="14px"
                            lineHeight="16px"
                            fontWeight="400"
                            color="black.1d"
                          >
                            {pipeAmount(balanceBnb)}
                          </Text>
                        </HStack>

                        <HStack>
                          <Icon as={BNBIcon} />
                          <Text
                            fontSize="14px"
                            lineHeight="16px"
                            fontWeight="400"
                            color="black.1d"
                          >
                            {pipeAmount(balanceBusd)}
                          </Text>
                        </HStack>
                      </HStack>
                      <Divider />
                      <Flex>
                        <Link to="/profile">
                          <Text
                            fontSize="15px"
                            lineHeight="18px"
                            fontWeight="400"
                            color="black.1d"
                            mt="20px"
                          >
                            My Items
                          </Text>
                        </Link>
                      </Flex>
                      <Flex>
                        <Link to="/profile?tabIndex=1">
                          <Text
                            fontSize="15px"
                            lineHeight="18px"
                            fontWeight="400"
                            color="black.1d"
                            mt="20px"
                          >
                            On Sales
                          </Text>
                        </Link>
                      </Flex>
                      <Flex>
                        <Link to="/profile?tabIndex=2">
                          <Text
                            fontSize="15px"
                            lineHeight="18px"
                            fontWeight="400"
                            color="black.1d"
                            mt="20px"
                          >
                            Activities History
                          </Text>
                        </Link>
                      </Flex>
                      <Flex>
                        <Link to="/profile?tabIndex=3">
                          <Text
                            fontSize="15px"
                            lineHeight="18px"
                            fontWeight="400"
                            color="black.1d"
                            my="20px"
                          >
                            Favorite
                          </Text>
                        </Link>
                      </Flex>
                      {/* 
                      <Divider mt="20px" />
                      <Text
                        mt="20px"
                        mb="20px"
                        fontSize="15px"
                        lineHeight="18px"
                        fontWeight="400"
                        color="grey.66"
                        textAlign="center"
                      >
                        Sign out
                      </Text> */}
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              ) : (
                <Button
                  bg="yellow.primary !important"
                  w="32px"
                  h="32px"
                  minWidth="auto"
                  borderRadius="6px"
                  mx="auto"
                >
                  <Icon as={WalletIcon} w="20px" h="20px" />
                </Button>
              )}
            </Box>
          </HStack>
        )}
      </HStack>
    </Box>
  )
}

export const FixedMobileHeader = () => {
  return (
    <Box
      position="fixed"
      bg="white"
      top={0}
      left={0}
      right={0}
      pt="20px"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)"
      zIndex={20}
      pb="20px"
      display={{
        base: 'block',
        lg: 'none',
      }}
    >
      <HeaderMobile />
    </Box>
  )
}

export default HeaderMobile
