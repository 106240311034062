import { Box, Grid, GridItem, Text, Image, HStack, Center } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '~/components/PrimaryButton'
import { INftProject } from '~/dto'
import { COLLECTIONS } from '~/routes/routes'

import FacebookIcon from '~/components/Icons/Facebook'
import GlobalIcon from '~/components/Icons/Global'
import TwitterIcon from '~/components/Icons/Twitter'
import { TagBlue, TagOrange, TagRed } from './Tags'


const OpeningCard = (props: INftProject) => {
  const navigate = useNavigate()
  return (
    <Box>
      <Grid
        templateColumns="repeat(2, 1fr)"
        gap={4}
        bg="#FFFFFF"
        borderRadius="24px"
        p={{
          base: '0',
          lg: '24px',
        }}
      >
        <GridItem
          colSpan={{
            base: 2,
            lg: 1,
          }}
          order={{
            base: 2,
            lg: 1,
          }}
        >
          <Text
            fontWeight="600"
            fontSize="42px"
            lineHeight="49px"
            textAlign="justify"
            color="black.1d"
          >
            {props.name}
          </Text>

          {/* <HStack mt="12px">
            <FacebookIcon />
            <GlobalIcon />
            <TwitterIcon />
          </HStack> */}

          <HStack mt="12px">
            {props.tag1 && <TagBlue value={props.tag1} />}
            {props.tag2 && <TagRed value={props.tag2} />}
            {props.tag3 && <TagOrange value={props.tag3} />}
          </HStack>

          <HStack mt="42px">
            <Text
              fontSize="14px"
              lineHeight="21px"
              color="black.light"
              fontWeight="400"
              textAlign="justify"
            >
              {props.shortContent.length >= 150 ? props.shortContent.slice(0, 150).concat(" ...") : props.shortContent}
            </Text>
          </HStack>

          <PrimaryButton
            mt="42px"
            onClick={() => {
              navigate(`${COLLECTIONS}/${props.collection}`)
            }}
          >
            Go to shop
          </PrimaryButton>
        </GridItem>
        <GridItem
          colSpan={{
            base: 2,
            lg: 1,
          }}
          order={{
            base: 1,
            lg: 2,
          }}
        >
          <Center>
            <Image
              src={props.logo}
              maxH="408px"
              maxW="408px"
              w="100%"
              borderRadius={"24px"}
            />
          </Center>
        </GridItem>
      </Grid>
    </Box>
  )
}

export default OpeningCard
