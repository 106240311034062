import { useQuery } from '@tanstack/react-query'
import NFTMarketPlaceServices from '~/services/nft.marketplace.services'

interface IActivityItem {
  address: string
  createdBy: null
  createdDate: string
  description: string
  exactPrice: string
  from: string
  id: string
  image: string
  name: string
  price: number
  to: string
  tokenId: number
  transactionHash: string
  transactionType: string
  updatedBy: null
  updatedDate: string
  version: number
}

const useActivitiesItemInCollection = (variables) => {
  const results = useQuery<{
    data: Array<IActivityItem>
    total: number
  }>(['useActivitiesItemInCollection', variables], () =>
    NFTMarketPlaceServices.collectionItemListActivities(variables),
  )
  return {
    ...results,
    data: results?.data?.data ?? [],
    total: results?.data?.total,
  }
}

export default useActivitiesItemInCollection
