import { useCallback } from 'react'
import {
  Box,
  HStack,
  Spacer,
  Text,
  SimpleGrid,
  Stack,
  Flex,
  Spinner,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import { usePagination } from '@ajna/pagination'
import { ArrowUpDownIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

import Pagination from '~/components/Pagination'
import MainLayout from '~/components/MainLayout/MainLayout'
import CollectionCard from '~/components/CollectionCard'
import HeaderMobile from '~/components/Header.mobile'
import SelectCustom from '~/components/Select'

import useListCollections from '~/hooks/Collections/useListCollections'
import { ORDERS } from './constants/orders'

const Collections = () => {
  const { t } = useTranslation('src/views/Collections/Collections.lang.json')
  const [{ order, pageSize, pageIndex }, setQuery] = useQueryParams({
    order: withDefault(StringParam, ORDERS.ids[0]),
    pageSize: withDefault(NumberParam, 10),
    pageIndex: withDefault(NumberParam, 1),
  })

  const { data, total, isLoading } = useListCollections({
    pageSize,
    pageIndex,
    order,
  })

  const { pages, pagesCount, currentPage, setCurrentPage, isDisabled } =
    usePagination({
      total,
      limits: {
        outer: 2,
        inner: 2,
      },
      initialState: {
        pageSize: pageSize,
        isDisabled: false,
        currentPage: pageIndex,
      },
    })

  const handlePageChange = useCallback(
    (nextPage: number): void => {
      setCurrentPage(nextPage)
      setQuery({ pageIndex: nextPage })
      console.log('request new data with ->', nextPage)
    },
    [setCurrentPage, setQuery],
  )

  return (
    <MainLayout>
      <Box
        px={{
          base: '20px',
          lg: '50px',
        }}
        pb={{
          base: '100px',
          lg: 0,
        }}
        position="relative"
      >
        <Box
          position="fixed"
          bg="white"
          top={0}
          left={0}
          right={0}
          pt="40px"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)"
          zIndex="20"
          display={{
            base: 'block',
            lg: 'none',
          }}
        >
          <HeaderMobile />
          <Box pl="20px" py="15px">
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ArrowUpDownIcon />}
                bg="white !important"
                fontSize="15px"
                lineHeight="18px"
                fontWeight="400"
                color="black"
              >
                {t(ORDERS.data[order])}
              </MenuButton>
              <MenuList>
                {ORDERS.ids.map((key) => {
                  return (
                    <MenuItem
                      fontSize="15px"
                      lineHeight="18px"
                      fontWeight="400"
                      color="black"
                      key={key}
                      onClick={() => {
                        setQuery({
                          order: key,
                        })
                      }}
                    >
                      {t(ORDERS.data[key])}
                    </MenuItem>
                  )
                })}
              </MenuList>
            </Menu>
          </Box>
        </Box>
        <HStack
          display={{
            base: 'none',
            lg: 'flex',
          }}
          pt={{
            base: '120px',
            lg: '43px',
          }}
        >
          <Box>
            <Text
              fontWeight="600"
              lineHeight="33px"
              fontSize="28px"
              letterSpacing="-0.5px"
              color="black.light"
            >
              {t('all_collections')}
            </Text>
          </Box>
          <Spacer />
          <Box
            w={{
              base: '250px',
              xl: '250px',
            }}
          >
            <SelectCustom
              placeholder={t('select_option')}
              options={ORDERS.ids.map((item) => ({
                label: t(ORDERS.data[item]),
                value: ORDERS.data[item],
              }))}
              onChange={(selected) => setQuery({ order: selected.value })}
              value={{
                label: t(ORDERS.data[order]),
                value: ORDERS.data[order],
              }}
            />
          </Box>
        </HStack>

        {isLoading ? (
          <Flex
            mt={{
              base: '160px',
              lg: '28px',
            }}
            flex={1}
            justifyContent="center"
            alignItems="center"
          >
            <Spinner color="yellow.primary" />
          </Flex>
        ) : (
          <SimpleGrid
            columns={{
              sm: 1,
              md: 2,
              lg: 3,
              xl: 4,
              xxl: 5,
            }}
            spacing="20px"
            mt={{
              base: '160px',
              lg: '28px',
            }}
          >
            {data.map((collection) => {
              return <CollectionCard key={collection.id} {...collection} />
            })}
          </SimpleGrid>
        )}

        {total !== 0 && (
          <Stack mt="40px">
            <Pagination
              pagesCount={pagesCount}
              currentPage={currentPage}
              isDisabled={isDisabled}
              onPageChange={handlePageChange}
              pages={pages}
            />
          </Stack>
        )}
      </Box>
    </MainLayout>
  )
}

export default Collections
