import { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Input,
  Text,
  Box,
  HStack,
  Divider,
  Spacer,
} from '@chakra-ui/react'
import Select from '~/components/Select'
import { useTranslation } from 'react-i18next'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { useParams } from 'react-router-dom'
import {
  NumberParam,
  ObjectParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

import PrimaryButton from '~/components/PrimaryButton'

import useListAttributeByCollection from '../hooks/useListAttributeByCollection'

const FilterItems = ({
  onClose = () => {},
  onOpen = () => {},
  isOpen = false,
}) => {
  const [
    {
      fromPrice: currentFromPrice,
      toPrice: currentToPrice,
      attributes: currentAttributes,
    },
    setQueries,
  ] = useQueryParams({
    fromPrice: withDefault(NumberParam, 0),
    toPrice: withDefault(NumberParam, 0),
    attributes: withDefault(ObjectParam, {}),
  })

  const [fromPrice, setFromPrice] = useState('')
  const [toPrice, setToPrice] = useState('')
  const [attributes, setAttributes] = useState<any>({})

  const { collectionAddress: address } = useParams()
  const { data } = useListAttributeByCollection({
    address,
  })

  const { t } = useTranslation(
    'src/views/Marketplace/components/CollectionDetailNFTFilterDrawer.lang.json',
  )

  useEffect(() => {
    if (currentFromPrice) {
      setFromPrice(currentFromPrice.toString())
    }

    if (currentToPrice) {
      setToPrice(currentToPrice.toString())
    }

    if (Object.keys(currentAttributes)) {
      // setAttributes(currentAttributes)
    }
  }, [currentFromPrice, currentToPrice, currentAttributes])

  const onSubmitFilter = useCallback(() => {
    setQueries({
      fromPrice: fromPrice ? Number(fromPrice) : undefined,
      toPrice: toPrice ? Number(toPrice) : undefined,
      attributes: Object.keys(attributes).length ? attributes : undefined,
    })
  }, [attributes, fromPrice, setQueries, toPrice])

  return (
    <>
      <HStack p="20px" w="100%">
        <Text
          fontWeight="500"
          fontSize="18px"
          lineHeight="21px"
          color="black.1d"
        >
          Filter
        </Text>
        <Spacer />
        <ArrowBackIcon onClick={onClose} w="24px" h="24px" />
      </HStack>
      <Divider />

      <Box p="20px">
        <Text
          fontSize="15px"
          lineHeight="18px"
          color="black.light"
          fontWeight="500"
        >
          Price
        </Text>
        <HStack mt="20px">
          <Box>
            <Input
              type="number"
              fontSize="15px"
              lineHeight="18px"
              color="grey.97"
              fontWeight="400"
              placeholder="Min"
              border="1.5px solid #DFE6F1"
              borderRadius="10px"
              value={fromPrice}
              onChange={(e) => setFromPrice(e.target.value)}
            />
          </Box>
          <Text
            fontSize="15px"
            lineHeight="18px"
            color="black.light"
            fontWeight="400"
          >
            -
          </Text>
          <Box>
            <Input
              type="number"
              fontSize="15px"
              lineHeight="18px"
              color="grey.97"
              fontWeight="400"
              placeholder="Max"
              border="1.5px solid #DFE6F1"
              borderRadius="10px"
              value={toPrice}
              onChange={(e) => setToPrice(e.target.value)}
            />
          </Box>
        </HStack>
      </Box>

      <Box mt="20px" p="20px">
        <Text
          fontSize="15px"
          lineHeight="18px"
          color="black.light"
          fontWeight="500"
        >
          Attributes
        </Text>

        {data?.map((item) => {
          return (
            <Box mt="20px" key={item.traitType}>
              <Select
                value={attributes?.selected}
                options={item.values.map((att) => ({
                  label: att,
                  value: att,
                }))}
                placeholder={item.traitType}
                onChange={(selected) =>
                  setAttributes({
                    traitType: item.traitType,
                    values: selected?.value,
                    selected,
                  })
                }
              />
            </Box>
          )
        })}
      </Box>

      <HStack mt="20px" p="20px">
        <Box flex={1}>
          <Button
            border="1.5px solid #DFE6F1"
            bg="white !important"
            w="100%"
            borderRadius="12px"
            onClick={onClose}
          >
            {t('Cancel')}
          </Button>
        </Box>
        <Box flex={1}>
          <PrimaryButton
            w="100%"
            bg="#FEDD44 !important"
            borderRadius="12px"
            onClick={onSubmitFilter}
          >
            {t('Apply')}
          </PrimaryButton>
        </Box>
      </HStack>
    </>
  )
}

export default FilterItems
