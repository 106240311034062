import { Box, Button, HStack, Icon } from '@chakra-ui/react'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as TwitterIcon } from '~/assets/svgs/twitter.svg'
import { ReactComponent as DiscordIcon } from '~/assets/svgs/discord.svg'
import { ReactComponent as GlobalIcon } from '~/assets/svgs/global.svg'
import { ReactComponent as HeartIcon } from '~/assets/svgs/heart.svg'
import { ReactComponent as FullHeartIcon } from '~/assets/svgs/full-heart.svg'

const Socials = ({ socialData }) => {
  const [isFavorite, setIsFavorite] = useState(false)
  return (
    <Box
      display={{
        base: 'none',
        lg: 'block',
      }}
    >
      <HStack spacing="20px">
        <HStack spacing="10px">
          {socialData?.['DISCORD'] && (
            <Icon
              bg="#F3F6F9"
              as={DiscordIcon}
              w="40px"
              h="40px"
              padding={2}
              borderRadius="6px"
              cursor="pointer"
              onClick={() => {
                window.open(socialData?.['DISCORD'])
              }}
            />
          )}
          {socialData?.['TWITTER'] && (
            <Icon
              bg="#F3F6F9"
              as={TwitterIcon}
              w="40px"
              h="40px"
              padding={2}
              borderRadius="6px"
              cursor="pointer"
              onClick={() => {
                window.open(socialData?.['TWITTER'])
              }}
            />
          )}
          {socialData?.['WEBSITE'] && (
            <Icon
              bg="#F3F6F9"
              as={GlobalIcon}
              w="40px"
              h="40px"
              padding={2}
              borderRadius="6px"
              cursor="pointer"
              onClick={() => {
                window.open(socialData?.['WEBSITE'])
              }}
            />
          )}
        </HStack>
        {/* <Button
          leftIcon={
            <Icon
              as={isFavorite ? FullHeartIcon : HeartIcon}
              w="30px"
              h="30px"
            />
          }
          border="1px solid #DFE6F1 !important"
          bg="white !important"
          h="auto"
          py="5px"
          fontWeight="500"
          letterSpacing="-0.5px"
          fontSize="15px"
          lineHeight="18px"
          color="black.light"
          borderRadius="10px"
          onClick={() => {
            setIsFavorite((currentState) => !currentState)
          }}
        >
          Add to Favorite
        </Button> */}
      </HStack>
    </Box>
  )
}

export default memo(Socials)
