import { IEnvConfig } from '..'

const config: IEnvConfig = {
  name: 'PRODUCTION',
  DOMAIN_URL: 'https://admin-nft.famcentral.finance',
  DOMAIN_URL_API: 'https://api-nft.famcentral.finance',
  CONNECTORS: {
    ROOT: {
      baseUrl: 'https://apiv2.famcentral.finance/',
    },
  },
  CHAINS: {
    BSC: {
      chainId: 56,
      chainName: "BSC",
      nativeCurrency: {
        name: 'BNB',
        symbol: 'bnb',
        decimals: 18
      },
      rpcUrls: [
        "https://bsc-dataseed.binance.org",
        "https://bsc-dataseed.binance.org",
        "https://bsc-dataseed.binance.org",
      ],
      blockExplorerUrls: ["https://bscscan.com"]
    },
    ETHEREUM: {
      chainId: 1,
      chainName: "ETH",
      nativeCurrency: {
        name: 'ETH',
        symbol: 'eth',
        decimals: 18
      },
      rpcUrls: [
        "https://eth-mainnet.public.blastapi.io",
      ],
      blockExplorerUrls: ["https://etherscan.io"],
    },
    AURORA: {
      chainId: 1313161554,
      chainName: "AURORA",
      "nativeCurrency": {
        "name": "Ether",
        "symbol": "aETH",
        "decimals": 18
      },
      rpcUrls: [
        "https://mainnet.aurora.dev"
      ],
      blockExplorerUrls: ["https://explorer.mainnet.aurora.dev"]
    },
    POLYGON: {
      chainId: 137,
      chainName: "POLYGON",
      nativeCurrency: {
        "name": "MATIC",
        "symbol": "MATIC",
        "decimals": 18
      },
      rpcUrls: [
        "https://polygon-rpc.com/",
        "https://rpc-mainnet.matic.network",
        "https://matic-mainnet.chainstacklabs.com",
        "https://rpc-mainnet.maticvigil.com",
        "https://rpc-mainnet.matic.quiknode.pro",
        "https://matic-mainnet-full-rpc.bwarelabs.com"
      ],
      blockExplorerUrls: ["https://polygonscan.com"],
    }
  },
  BLOCK_CHAIN: {
    REACT_APP_CHAIN_ID: '56',
    REACT_APP_GTAG: 'GTM-PXLD3XW',
    REACT_APP_NODE_1: 'https://bsc-dataseed.binance.org',
    REACT_APP_NODE_2: 'https://bsc-dataseed.binance.org',
    REACT_APP_NODE_3: 'https://bsc-dataseed.binance.org',
    REACT_APP_BSCSCAN: 'https://bscscan.com',
    REACT_APP_GRAPH_API_PROFILE:
      'https://api.thegraph.com/subgraphs/name/pancakeswap/profile',
    REACT_APP_GRAPH_API_PREDICTION:
      'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction',
    REACT_APP_GRAPH_API_LOTTERY:
      'https://api.thegraph.com/subgraphs/name/pancakeswap/lottery',

    REACT_APP_SNAPSHOT_BASE_URL: 'https://testnet.snapshot.org',
    REACT_APP_SNAPSHOT_VOTING_API:
      'https://xtjyd0liqe.execute-api.ap-northeast-1.amazonaws.com/dev/api',
    REACT_APP_API_PROFILE: '',
  },
  ADDRESS_CONFIG: {
    WBNB: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'.trim(),
    BUSD: '0xe9e7cea3dedca5984780bafc599bd69add087d56'.trim(),
    USDT: '0x55d398326f99059ff775485246999027b3197955'.trim(),
    BICYCLE: '0xe78ad8967e523a29d4d3a8e76422aa7ab2b6a47e'.trim(),
    PANCAKE_FACTORY: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73'.trim(), //pancakeswap
    PANCAKE_ROUTER: '0x10ED43C718714eb63d5aA57B78B54704E256024E'.trim(), // pancakeswap
    FAM_TOKEN: '0x4556a6f454f15c4cd57167a62bda65a6be325d1f',
    MARKET_V2_ADDRESS: "0x5d30734E2f6a629F3EA8323f2fC2235185B5D7e4".trim(),
  },

  GOOGLE_CLIENT_ID: "1028163660284-uo2mcidbqlrvusbec3dm5nffc6d5ill7.apps.googleusercontent.com",
  MORALIS_API_CHAIN: 'bsc',
  TRASH_WALLET_ADDRESS: '0xa003B365E113F7c35a98d451689969aF1E70E559',
  BNB_TOKEN_SYMBOL: 'BNB',
  ERC1155_TYPE: 'ERC1155',
  TOKEN_UTILS_POOL_ADDRESS: '0xEDA00658c60C6D93c3B25bd222e08863ABc63b23',
  POOL_FAUCET: "0xa98ABf979b4A29349e13d30507e9c132ea9F4566",
  POOL_NFT_COLLECTIBLE: "0xF18df35D79BE0e43d164AC9207E59ECb92f60f65",
  POOL_NFT_TRIAL_1: "0x6DAc25141a86409b764F4D47cb796F4CE9DB7cf7",
  POOL_NFT_TRIAL_2: "0xaB93E64Fb2D4b0C0e1E0c686AB13B5E84031189D",
  POOL_NFT_TRIAL_3: "0xe213cAC13EA2D500F29E2D3bcfAf80af81805E52",
  POOL_NFT_GS25: "0x1d880686cdb89496bcf907c649307Af7B00A3b0A",
  POOL_NFT_HENIKEN: "0x24b90884eE192bF8FDEB7eBB52088dCb5067a5d5",
  POOL_NFT_TRUNG_NGUYEN: "0x50899EF08642065fdF272A90638419570dCDEaFe",
  POOL_FCOFFEE: "0xF63DDdA6DA2F331B2a403500296d0ec8b546ea55",
  POOL_REDDER: "0xcC7905Ca6Bce281764eDA486eaBE41bD4d0D72a3",
}

export default config
