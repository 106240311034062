import { Box, Text, Tooltip } from '@chakra-ui/react'
import { memo, useState } from 'react'
import useWindowSize from '~/hooks/useWindowSize'

const Description = ({ description }: { description?: string }) => {
  const [onPressSeeFull, setOnPressSeeFull] = useState(false)
  const { width } = useWindowSize()

  if (!description) {
    return null
  }

  return (
    <Tooltip isDisabled={width < 1024} label={description}>
      <Box
        bg={{
          base: 'white',
          lg: 'transparent',
        }}
        pb={{
          base: '16px',
        }}
        pt={{
          base: 0,
          lg: '16px',
        }}
        px={{
          base: 0,
          lg: '19px',
        }}
        border={{
          base: 'none',
          lg: '1px solid #DFE6F1',
        }}
        borderRadius="10px"
      >
        <Text
          color="grey.66"
          fontWeight="400"
          lineHeight="19px"
          letterSpacing="-0.5px"
          fontSize="16px"
          noOfLines={onPressSeeFull ? 100 : 4}
          onClick={() => {
            if (width < 1024) {
              setOnPressSeeFull((currentState) => !currentState)
            }
          }}
        >
          {description}
        </Text>
      </Box>
    </Tooltip>
  )
}

export default memo(Description)
