import { useQuery } from '@tanstack/react-query'
import NFTMarketPlaceServices from '~/services/nft.marketplace.services'

interface ICollectionTotalReport {
  floorPrice: number
  totalHolders: number
  avgSavePrice: number
  totalListed: number
  totalSupply: number
  totalVolume: number
}

const useCollectionTotalReport = (variables) => {
  const results = useQuery<ICollectionTotalReport>(
    ['collectionTotalReport', variables],
    async () => NFTMarketPlaceServices.collectionTotalReport(variables),
  )
  return { ...results, data: results?.data }
}

export default useCollectionTotalReport
