import { Box, Image } from '@chakra-ui/react'

const SocialsMobile = ({ socialData }) => {
  return (
    <>
      {socialData?.['DISCORD'] && (
        <Box
          position="absolute"
          bottom="55px"
          right="20px"
          display={{
            base: 'block',
            lg: 'none',
          }}
          bg="white"
          p="4px"
          borderRadius="8px"
          onClick={() => {
            window.open(socialData?.['DISCORD'])
          }}
        >
          <Image
            src="/assets/images/discord.png"
            w={{
              base: '23px',
              lg: '40px',
            }}
            h={{
              base: '23px',
              lg: '40px',
            }}
          />
        </Box>
      )}
      {socialData?.['TWITTER'] && (
        <Box
          position="absolute"
          bottom="55px"
          right="60px"
          display={{
            base: 'block',
            lg: 'none',
          }}
          bg="white"
          p="4px"
          borderRadius="8px"
          onClick={() => {
            window.open(socialData?.['TWITTER'])
          }}
        >
          <Image
            src="/assets/images/twitter.png"
            w={{
              base: '23px',
              lg: '40px',
            }}
            h={{
              base: '23px',
              lg: '40px',
            }}
          />
        </Box>
      )}
      {socialData?.['WEBSITE'] && (
        <Box
          position="absolute"
          bottom="55px"
          right="100px"
          display={{
            base: 'block',
            lg: 'none',
          }}
          bg="white"
          p="4px"
          borderRadius="8px"
          onClick={() => {
            window.open(socialData?.['WEBSITE'])
          }}
        >
          <Image
            src="/assets/images/global.png"
            w={{
              base: '23px',
              lg: '40px',
            }}
            h={{
              base: '23px',
              lg: '40px',
            }}
          />
        </Box>
      )}
    </>
  )
}

export default SocialsMobile
