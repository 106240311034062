import { ArrowBackIcon } from '@chakra-ui/icons'
import { HStack, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const HeaderMobile = (props: { name: string }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <HStack
      bg="white"
      pl="20px"
      pt="20px"
      display={{
        base: 'flex',
        lg: 'none',
      }}
      cursor="pointer"
      onClick={() => {
        navigate(-1)
      }}
      position="fixed"
      top={0}
      left={0}
      right={0}
      pb="10px"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)"
      zIndex={20}
    >
      <ArrowBackIcon />
      <Text fontWeight="600" fontSize="16px" lineHeight="23px" color="black">
        {props.name ? props.name : "NFT Items"}
      </Text>
    </HStack>
  )
}

export default HeaderMobile
