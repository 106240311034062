import { Box, Button, Flex, Text, VStack, Image, Input } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react';
import { configEnv } from '~/@config';
import useWindowSize from '~/hooks/useWindowSize'

import ImageNft from "~/assets/svgs/images/nft-fcoffee.png"
import MainLayout from '~/components/MainLayout/MainLayout';
import WrapperHeaderMobile from '../NFTSales/components/WrapperHeaderMobile';
import useMintNftFCoffee from '~/hooks/mintNft/useMintNftFCoffee';
import { MintSuccessModal } from './MintSuccessModal';

const { POOL_FCOFFEE } = configEnv();

const FCoffee = () => {
    const { width } = useWindowSize();
    const [newCode, setNewCode] = useState(null);
    const [newEmail, setNewEmail] = useState(null);
    const {
        isChainPolygon,
        isLoading,
        onwerBox,
        code,
        txHash,
        isOpen,
        setIsOpen,
        setCode,
        setEmail,
        minftNftFCoffee,
    } = useMintNftFCoffee({ poolNft: POOL_FCOFFEE });

    useEffect(() => {
        setNewCode(code);
    }, [code])

    const renderButton = useCallback(() => {
        return (
            <VStack spacing={4} pt={{ base: "30px !important", md: "80px !important" }} w="full">

                <Input
                    value={newCode}
                    onChange={(e) => { setNewCode(e.target.value) }}
                    onBlur={(e) => { setCode(newCode) }}
                    borderColor="#448AFF !important"
                    borderWidth="1px"
                    borderStyle="solid"
                    placeholder="Nhập mã của bạn"
                    borderRadius="10px"
                    textAlign="right"
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="20px"
                    bg="#F7F9FA"
                    disabled={isLoading || !isChainPolygon}
                />

                <Input
                    value={newEmail}
                    onChange={(e) => { setNewEmail(e.target.value) }}
                    onBlur={(e) => { setEmail(newEmail) }}
                    borderColor="#448AFF !important"
                    borderWidth="1px"
                    borderStyle="solid"
                    placeholder="Nhập email của bạn"
                    borderRadius="10px"
                    textAlign="right"
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="20px"
                    bg="#F7F9FA"
                    type={"email"}
                    disabled={isLoading || !isChainPolygon}
                />

                <Button
                    bg="yellow.primary !important"
                    borderRadius="8px"
                    fontWeight="600"
                    fontSize="16px"
                    lineHeight="18px"
                    h="auto"
                    py="5px"
                    color="black.1d"
                    w="full"
                    p="10px"
                    disabled={isLoading || !isChainPolygon}
                    onClick={minftNftFCoffee}
                    isLoading={isLoading}
                >
                    Nhận NFT
                </Button>

                {!isChainPolygon &&
                    <Text
                        fontWeight="400"
                        fontSize={{
                            base: '10px',
                            md: '14px',
                        }}
                        color="#E50300"
                        textAlign="center"
                    >
                        Vui lòng chuyển đổi qua mạng Polygon ở góc phải màn hình để nhận NFT.
                    </Text>
                }

            </VStack>
        )
    }, [isChainPolygon, isLoading, minftNftFCoffee, newCode, newEmail, setCode, setEmail])

    const renderInfomation = () => {
        return (
            <Box
                background={"#111827"}
                height={width <= 500 ? "auto" : "550px"}
                w={{ base: "350px", lg: "460px" }}
                p={{ base: "24px", lg: "32px 40px" }}
                borderRadius="20px"
                position={{ base: "relative", md: "absolute" }}
                left={{ base: "auto", md: "0" }}
                top={{ base: "-30px", md: "auto" }}
            >

                <VStack spacing={3}>

                    <Text
                        fontSize={{
                            base: '20px',
                            lg: '30px',
                        }}
                        lineHeight="20px"
                        color="#e5e7eb"
                        fontWeight="600"
                    >
                        F Mystery Box
                    </Text>

                    <VStack
                        alignItems={"start"}
                        spacing={1}
                        pt="20px"
                    >
                        <Text
                            fontSize={{
                                base: '12px',
                                md: '16px',
                            }}
                            lineHeight={{
                                base: '16px',
                                md: '20px',
                            }}
                            color="#9ca3af"
                            fontWeight="400"
                            textAlign={"justify"}
                        >
                            Chào mừng bạn đến với thế giới F NFT, một trải nghiệm mới của F Coffee đặc biệt dành tặng các khách hàng. Hãy giữ NFT này, vì nó không chỉ là Tài sản kỹ thuật số, nó còn được gia tăng nhiều hơn nữa các giá trị bất ngờ tại F Coffee và trong thế giới số, thế giới Web3 của thời đại internet mới. Hãy khám phá và tận hưởng cùng F Coffee.
                        </Text>
                    </VStack>

                    {renderButton()}

                </VStack>

            </Box>
        )
    }

    const renderNft = useCallback(() => {
        return (
            <Box
                w={{ base: "full", md: "56%", lg: "60%" }}
                bg="#010101"
                borderRadius="20px"
                pb="10px"
            >

                {/* <Image
                    src={ImageNft}
                    borderRadius="80px"
                    p="60px"
                    objectFit={"contain"}
                    style={{
                        height: width <= 500 ? "400px" : "630px",
                        width: "100%"
                    }}
                    className='shake-vertical'
                /> */}
                <video
                    loop
                    width="100%"
                    style={{
                        height: width <= 500 ? "400px" : "630px",
                    }}
                    //@ts-ignore
                    autoPlay={true}
                    //@ts-ignore
                    playsinline="playsinline"
                    //@ts-ignore
                    muted="muted"
                    src="fam-mystery-box.mp4"
                >
                </video>

                <Text
                    fontWeight="500"
                    fontSize={{
                        base: '13px',
                        md: '16px',
                    }}
                    color="#E50300"
                    textAlign="center"
                >
                    Số lượng sở hữu : {onwerBox}
                </Text>
            </Box>
        )
    }, [onwerBox, width])

    return (
        <MainLayout>

            <WrapperHeaderMobile />

            <Box
                mr={{
                    base: '20px',
                    xl: '41px',
                }}
                ml={{
                    base: '20px',
                    xl: '47px',
                }}
                mt={{
                    base: '14px',
                    lg: '40px',
                }}
                mb={{
                    base: '120px',
                    lg: '40px',
                }}
            >
                <VStack
                    w="full"
                    position={"relative"}
                >

                    <Flex
                        flexDirection={{ base: "column-reverse", md: "row" }}
                        justifyContent="flex-end"
                        py="30px"
                        w="full"
                        maxW={"1024px"}
                        position={"relative"}
                        alignItems="center"
                    >

                        {renderInfomation()}

                        {renderNft()}
                    </Flex>

                </VStack>

            </Box>

            <Box h="20vh" />

            <MintSuccessModal
                isOpen={isOpen}
                onDismiss={() => setIsOpen(false)}
                txHash={txHash}
            />

        </MainLayout>
    )
}

export default FCoffee
