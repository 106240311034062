import { Button, ButtonProps } from '@chakra-ui/react'

const PrimaryButton = ({ children, disabled, ...props }: ButtonProps) => {
  return (
    <Button
      w="100%"
      bg="#FEDD44"
      color="#000000"
      fontWeight="500"
      fontSize="16px"
      borderWidth="1.5px"
      borderColor="#FEDD44"
      disabled={disabled}
      borderRadius="12px"
      _disabled={{
        borderColor: '#E8E8E8 !important',
        backgroundColor: '#E8E8E8 !important',
        color: '#979797 !important',
      }}
      _focus={{
        borderColor: '#F9CC15',
        backgroundColor: '#F9CC15',
        color: '#000000',
      }}
      _hover={{
        borderColor: disabled ? '#E8E8E8' : '#FFEE57',
        backgroundColor: disabled ? '#E8E8E8' : '#FFEE57',
        boxShadow:
          '0px -2px 10px rgba(0, 0, 0, 0.04), 0px 4px 10px rgba(0, 0, 0, 0.04)',
      }}
      _active={{
        borderColor: '#F9CC15',
        backgroundColor: '#F9CC15',
        color: '#000000',
      }}
      _loading={{
        borderColor: '#B99006',
        backgroundColor: '#B99006',
        color: '#000000',
      }}
      loadingText={typeof children === 'string' ? children : ''}
      py={{
        base: '9px',
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

export default PrimaryButton
