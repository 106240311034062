import { CloseIcon } from '@chakra-ui/icons'
import { Box, Divider, HStack, Spacer, Text } from '@chakra-ui/react'
import { FC } from 'react'
import PrimaryButton from '~/components/PrimaryButton'

import BulkBuyItem from './BulkBuyItem'

type BulkBuySectionProps = {
  listBuys: {
    address: string
    askId: string
    createdBy: null
    createdDate: string
    description: string
    exactPrice: string
    id: string
    image: string
    symbol: string
    name: string
    price: string
    seller: string
    status: string
    tokenId: string
    updatedBy: null
    updatedDate: string
    version: string
    collectionName?: string
  }[]
  onClose: () => void
  onRemoveItem: (id: string) => void
}

const BulkBuySection: FC<BulkBuySectionProps> = ({
  onClose = () => {},
  listBuys,
  onRemoveItem,
}) => {
  const total = listBuys.reduce((current, next) => {
    return current + Number(next.price)
  }, 0)
  return (
    <div>
      <HStack p="20px" w="100%">
        <Text
          fontWeight="500"
          fontSize="18px"
          lineHeight="21px"
          color="black.1d"
        >
          Bulk buy
        </Text>
        <Spacer />
        <CloseIcon onClick={onClose} w="12px" h="12px" />
      </HStack>

      <Divider />

      {listBuys.map((item) => {
        return (
          <BulkBuyItem
            key={item.id}
            item={item}
            onRemoveItem={() => {
              onRemoveItem(item.id)
            }}
          />
        )
      })}

      <Box px="20px" mt="6px">
        <Divider />

        <HStack mt="20px" justify="space-between">
          <Text
            fontSize="15px"
            lineHeight="18px"
            color="black.1d"
            letterSpacing="-0.5px"
          >
            Wallet Balance
          </Text>
          <Text
            fontSize="16px"
            lineHeight="19px"
            color="black.1d"
            letterSpacing="-0.5px"
            fontWeight="600"
          >
            {total}
          </Text>
        </HStack>

        <HStack mt="20px" justify="space-between">
          <Text
            fontSize="15px"
            lineHeight="18px"
            color="black.1d"
            letterSpacing="-0.5px"
          >
            Total
          </Text>
          <Text
            fontSize="16px"
            lineHeight="19px"
            color="black.1d"
            letterSpacing="-0.5px"
            fontWeight="600"
          >
            {total}
          </Text>
        </HStack>

        <Text
          fontSize="12px"
          lineHeight="24px"
          color="grey.97"
          letterSpacing="-0.5px"
          fontWeight="500"
          mt="20px"
        >
          By clicking “Checkout”, you agree to the{' '}
          <Text as="span" color="#448AFF">
            Terms of service{' '}
          </Text>
        </Text>

        <PrimaryButton mt="20px">Checkout</PrimaryButton>
      </Box>
    </div>
  )
}

export default BulkBuySection
