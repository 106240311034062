import { Box, Image, Center, Text, HStack, Spacer, VStack, } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { INftProject } from '~/dto'
import { COLLECTIONS } from '~/routes/routes'
import { DateTag, TagBlue, TagOrange } from './Tags'

const UpcomingCard = (props: INftProject) => {
  const navigate = useNavigate()
  return (
    <Box
      cursor="pointer"
      role="group"
      bg={{
        base: 'grey.light',
        lg: 'white',
      }}
      border={{
        base: '1px solid #DFE6F1',
        lg: 'none',
      }}
      borderRadius="12px"
      onClick={() => {
        // navigate(`${COLLECTIONS}/1`)
      }}
    >
      <Box
        w={'full'}
        bg="white"
        rounded="12px"
        overflow={'hidden'}
        transition="all 250ms"
        border="1px solid transparent"
        height={"100%"}
        justifyContent={"space-between"}
      >
        <Box position="relative">
          <Image
            h={'273px'}
            maxH="273px"
            overflow="hidden"
            w={'full'}
            src={props.logo}
            objectFit={'cover'}
            _groupHover={{
              transform: 'scale(1.1)',
            }}
            borderRadius="6px"
          />
        </Box>

        <VStack
          p="15px"
          h="210px"
          maxH={"210px"}
          justifyContent={"space-between"}
          w="full"
          alignItems={"initial"}
        >

          <Box>
            <HStack spacing={'10px'}>
              <Box flex={1}>
                <Text
                  fontSize="24px"
                  lineHeight="28px"
                  fontWeight="700"
                  color="black.1d"
                >
                  {props.name}
                </Text>
              </Box>
              <DateTag value='TBA' />
            </HStack>

            <HStack mt="12px">
              {props.tag1 && <TagBlue value={props.tag1} />}
              {props.tag2 && <TagOrange value={props.tag2} />}
            </HStack>
          </Box>

          <Box>
            <HStack mt="24px">
              <Text
                fontSize="16px"
                lineHeight="19px"
                fontWeight="400"
                color="rgba(31, 34, 40, 0.89)"
              >
                Tổng số lượng
              </Text>
              <Spacer />
              <Text
                fontSize="16px"
                lineHeight="19px"
                fontWeight="600"
                color="blue.neutral"
              >
                {props.nftAllowcation}
              </Text>
            </HStack>

            <HStack mt="12px">
              <Text
                fontSize="16px"
                lineHeight="19px"
                fontWeight="400"
                color="rgba(31, 34, 40, 0.89)"
              >
                Hình thức
              </Text>
              <Spacer />
              <HStack>
                <Text
                  fontSize="16px"
                  lineHeight="19px"
                  fontWeight="600"
                  color="blue.neutral"
                >
                  {props.deposit}
                </Text>
              </HStack>
            </HStack>
          </Box>

        </VStack>

      </Box>
    </Box>
  )
}

export default UpcomingCard
