import { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Center,
  Grid,
  GridItem,
  HStack,
  Icon,
  Image,
  Spacer,
  Text,
  VStack,
  Input,
  Button,
} from '@chakra-ui/react'

import { ReactComponent as InfoIcon } from '~/assets/svgs/info.svg'
import { ReactComponent as ShieldIcon } from '~/assets/svgs/shield.svg'
import { ReactComponent as PaperIcon } from '~/assets/svgs/paper.svg'
import { ReactComponent as ArrowDown } from '~/assets/svgs/arrow-down.svg'
import HeaderMobile from '../Header.mobile'
import { IListNft } from '~/dto'
import { useMintNft } from '../hooks'
import { RedeemModal } from './RedeemModal'
import { configEnv } from '~/@config'

const { POOL_REDDER } = configEnv();
interface IProps {
  nftDetail: IListNft
  nftAllowcation: string
}

export const NftDetail = (props: IProps) => {

  const [isOpenAboutCollection, setIsOpenAboutCollection] = useState(true)
  const [isOpenAttributes, setIsOpenAttributes] = useState(true)
  const [isOpenNFTDetail, setIsOpenNFTDetail] = useState(true)

  const [newCode, setNewCode] = useState(null);
  const [newEmail, setNewEmail] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const { nftDetail, nftAllowcation } = props;
  const {
    isChainPolygon,
    isLoading,
    onwerBox,
    code,
    listNftRedeem,
    setCode,
    setEmail,
    getNftRedeem,
    minftNft,
  } = useMintNft({ poolNft: POOL_REDDER });

  useEffect(() => {
    setNewCode(code);
  }, [code])

  const renderButton = useCallback(() => {
    return (
      <VStack mt="10px">

        <Input
          value={newCode}
          onChange={(e) => { setNewCode(e.target.value) }}
          onBlur={(e) => { setCode(newCode) }}
          borderColor="#448AFF !important"
          borderWidth="1px"
          borderStyle="solid"
          placeholder="Nhập mã của bạn"
          borderRadius="10px"
          textAlign="left"
          fontWeight="500"
          fontSize="16px"
          lineHeight="20px"
          bg="#F7F9FA"
          disabled={isLoading || !isChainPolygon}
        />

        <Input
          value={newEmail}
          onChange={(e) => { setNewEmail(e.target.value) }}
          onBlur={(e) => { setEmail(newEmail) }}
          borderColor="#448AFF !important"
          borderWidth="1px"
          borderStyle="solid"
          placeholder="Nhập email của bạn"
          borderRadius="10px"
          textAlign="left"
          fontWeight="500"
          fontSize="16px"
          lineHeight="20px"
          bg="#F7F9FA"
          type={"email"}
          disabled={isLoading || !isChainPolygon}
        />

        <Button
          bg="yellow.primary !important"
          borderRadius="8px"
          fontWeight="600"
          fontSize="16px"
          lineHeight="18px"
          h="auto"
          py="5px"
          color="black.1d"
          w="full"
          p="10px"
          disabled={isLoading || !isChainPolygon}
          onClick={minftNft}
          isLoading={isLoading}
        >
          Nhận NFT
        </Button>

        {onwerBox > 0 && isChainPolygon &&
          <Button
            bg="yellow.primary !important"
            borderRadius="8px"
            fontWeight="600"
            fontSize="16px"
            lineHeight="18px"
            h="auto"
            py="5px"
            color="black.1d"
            w="full"
            p="10px"
            disabled={isLoading || !isChainPolygon || onwerBox <= 0}
            onClick={() => {
              setIsOpen(true);
              getNftRedeem();
            }}
            isLoading={isLoading}
          >
            Redeem
          </Button>
        }

        {!isChainPolygon &&
          <Text
            fontWeight="400"
            fontSize={{
              base: '10px',
              md: '14px',
            }}
            color="#E50300"
            textAlign="center"
          >
            Vui lòng chuyển đổi qua mạng Polygon ở góc phải màn hình để nhận NFT.
          </Text>
        }
      </VStack>
    )
  }, [getNftRedeem, isChainPolygon, isLoading, minftNft, newCode, newEmail, onwerBox, setCode, setEmail])

  return (
    <Box>

      <HeaderMobile />

      <Box
        p={{
          base: 0,
          lg: '30px',
          xl: '50px',
        }}
        pt={{
          base: '40px',
          lg: '30px',
          xl: '50px',
        }}
        overflow="hidden"
      >
        <Grid
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(8, 1fr)"
          gap={{
            base: 0,
            lg: 4,
          }}
        >
          <GridItem
            rowSpan={2}
            colSpan={{
              base: 8,
              lg: 3,
            }}
            p={{
              base: '20px',
              lg: '0',
            }}
            bg="white"
            borderRadius="8px"
          >
            <Box position="relative" w="100%" h="100%">

              {nftDetail.video ?
                <Center p="10px" >
                  <video
                    loop
                    style={{
                      borderRadius: "12px"
                    }}
                    //@ts-ignore
                    autoPlay={true}
                    //@ts-ignore
                    playsinline="playsinline"
                    //@ts-ignore
                    muted="muted"
                    src={nftDetail.video}
                  >
                  </video>
                </Center>
                :
                <Image
                  w={'full'}
                  h={{
                    base: '374px',
                    lg: '100%',
                  }}
                  borderRadius="8px"
                  src={nftDetail.image}
                  objectFit={'cover'}
                  fallbackSrc="/assets/images/empty-item.png"
                />
              }

            </Box>
          </GridItem>
          <GridItem
            colSpan={{
              base: 8,
              lg: 5,
            }}
            p={{
              base: '0 20px 0 20px',
              lg: '20px 68px 20px 30px',
            }}
            bg="white"
            borderRadius="8px"
            rowSpan={1}
          >
            <HStack alignItems="center">
              <Text
                fontSize="15px"
                lineHeight="18px"
                letterSpacing="-0.5px"
                color="grey.66"
              >
                {nftDetail.collectionName}
              </Text>
            </HStack>

            <HStack alignItems="center" mt="12px">
              <Text
                fontSize="15px"
                lineHeight="18px"
                letterSpacing="-0.5px"
                color="grey.66"
              >
                Số lượng phát hành:
              </Text>
              <Text
                fontSize="15px"
                lineHeight="18px"
                fontWeight="600"
                color="black.1d"
              >
                {nftAllowcation}
              </Text>
            </HStack>

            <HStack alignItems="center" mt="12px">
              <Text
                fontSize="15px"
                lineHeight="18px"
                letterSpacing="-0.5px"
                color="grey.66"
              >
                Số lượng sở hữu:
              </Text>
              <Text
                fontSize="15px"
                lineHeight="18px"
                fontWeight="600"
                color="black.1d"
              >
                {onwerBox}
              </Text>
            </HStack>

            <Text
              mt="12px"
              fontSize="24px"
              lineHeight="28px"
              fontWeight="600"
              color="black.1d"
              letterSpacing="-0.5px"
            >
              {nftDetail.name}
            </Text>

            {renderButton()}

          </GridItem>

          <GridItem
            colSpan={{
              base: 8,
              lg: 5,
            }}
            p={{
              base: '10px 20px',
              lg: 0,
            }}
            bg={{
              base: 'white',
              lg: 'transparent',
            }}
            rowSpan={1}
          >
            <Box
              border={{
                base: '1px solid #DFE6F1',
                lg: 'none',
              }}
              borderRadius="8px"
            >
              <HStack
                px={{
                  base: '10px',
                  lg: '30px',
                }}
                py="14px"
                bg="rgba(68, 138, 255, 0.12)"
                borderRadius="8px 8px 0px 0px"
              >
                <Box>
                  <HStack alignItems="center">
                    <Icon as={InfoIcon} h="24px" w="24px" />
                    <Text
                      fontWeight="600"
                      fontSize="18px"
                      lineHeight="21px"
                      color="black.light"
                      letterSpacing="-0.5px"
                    >
                      Giá trị của NFT
                    </Text>
                    <HStack
                      display={{
                        base: 'none',
                        md: 'flex',
                      }}
                    >
                      <Text
                        fontWeight="500"
                        fontSize="15px"
                        lineHeight="18px"
                        letterSpacing="-0.5px"
                        color="grey.66"
                      >
                        {nftDetail.name}
                      </Text>
                    </HStack>
                  </HStack>
                  <HStack
                    mt="10px"
                    display={{
                      base: 'flex',
                      md: 'none',
                    }}
                  >
                    <Text
                      fontWeight="500"
                      fontSize="15px"
                      lineHeight="18px"
                      letterSpacing="-0.5px"
                      color="grey.66"
                    >
                      {nftDetail.collectionName}
                    </Text>
                  </HStack>
                </Box>
                <Spacer />
                <Box>
                  <Icon
                    as={ArrowDown}
                    w="24px"
                    h="24px"
                    onClick={() => {
                      setIsOpenAboutCollection((current) => !current)
                    }}
                  />
                </Box>
              </HStack>

              {isOpenAboutCollection && (
                <Box
                  p={{
                    base: '20px 15px 30px 15px',
                    lg: '20px 68px 30px 30px',
                  }}
                  bg="white"
                  borderRadius="8px"
                >
                  <Text
                    fontWeight="400"
                    fontSize="16px"
                    lineHeight="19px"
                    letterSpacing="-0.5px"
                    color="grey.66"
                    textAlign="justify"
                  >
                    {nftDetail.about}
                  </Text>
                </Box>
              )}
            </Box>
          </GridItem>
        </Grid>

        <Grid
          mt={{
            base: 0,
            lg: '40px',
          }}
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(8, 1fr)"
          gap={{
            base: 0,
            lg: 4,
          }}
        >
          <GridItem
            rowSpan={1}
            colSpan={{
              base: 8,
              lg: 3,
            }}
            p={{
              base: '10px 20px',
              lg: 0,
            }}
            bg={{
              base: 'white',
              lg: 'transparent',
            }}
          >
            <Box
              border={{
                base: '1px solid #DFE6F1',
                lg: 'none',
              }}
              borderRadius="8px"
            >
              <HStack
                py="14px"
                bg="rgba(68, 138, 255, 0.12)"
                borderRadius="8px 8px 0px 0px"
                px={{
                  base: '10px',
                  lg: '30px',
                }}
              >
                <HStack>
                  <Icon as={ShieldIcon} h="24px" w="24px" />
                  <Text
                    fontWeight="600"
                    fontSize="18px"
                    lineHeight="21px"
                    color="black.light"
                    letterSpacing="-0.5px"
                  >
                    Nội dung chương trình
                  </Text>
                </HStack>
                <Spacer />
                <Box>
                  <Icon
                    as={ArrowDown}
                    w="24px"
                    h="24px"
                    onClick={() => {
                      setIsOpenAttributes((current) => !current)
                    }}
                  />
                </Box>
              </HStack>

              {isOpenAttributes &&
                <Box
                  w="100%"
                  bg="white"
                  px={{
                    base: '10px',
                    lg: '30px',
                  }}
                  py="20px"
                  borderRadius="8px"
                >
                  <VStack spacing={3} alignItems="start">
                    {nftDetail?.content && nftDetail?.content.map((item, idx) => {
                      return (
                        <Text
                          key={idx}
                          fontWeight="400"
                          fontSize="16px"
                          lineHeight="19px"
                          letterSpacing="-0.5px"
                          color="grey.66"
                          textAlign="justify"
                        >
                          <span
                            style={{ whiteSpace: "pre-line" }}
                            dangerouslySetInnerHTML={{ __html: item }}
                          />
                        </Text>
                      )
                    })}
                  </VStack>
                </Box>
              }
            </Box>
          </GridItem>
          <GridItem
            colSpan={{
              base: 8,
              lg: 5,
            }}
            p={{
              base: '10px 20px',
              lg: 0,
            }}
            bg={{
              base: 'white',
              lg: 'transparent',
            }}
          >
            <Box
              border={{
                base: '1px solid #DFE6F1',
                lg: 'none',
              }}
              borderRadius="8px"
            >
              <HStack
                py="14px"
                bg="rgba(68, 138, 255, 0.12)"
                borderRadius="8px 8px 0px 0px"
                px={{
                  base: '10px',
                  lg: '30px',
                }}
              >
                <HStack>
                  <Icon as={PaperIcon} h="24px" w="24px" />
                  <Text
                    fontWeight="600"
                    fontSize="18px"
                    lineHeight="21px"
                    color="black.light"
                    letterSpacing="-0.5px"
                  >
                    Hướng dẫn thao tác
                  </Text>
                </HStack>
                <Spacer />
                <Box>
                  <Icon
                    as={ArrowDown}
                    w="24px"
                    h="24px"
                    onClick={() => {
                      setIsOpenNFTDetail((current) => !current)
                    }}
                  />
                </Box>
              </HStack>

              {isOpenNFTDetail && (
                <Box
                  w="100%"
                  bg="white"
                  px={{
                    base: '10px',
                    lg: '30px',
                  }}
                  py="20px"
                  borderRadius="8px"
                >
                  <VStack spacing={3} alignItems="start">
                    {nftDetail?.howToMint && nftDetail?.howToMint.map((item, idx) => {
                      return (
                        <Text
                          key={idx}
                          fontWeight="400"
                          fontSize="16px"
                          lineHeight="19px"
                          letterSpacing="-0.5px"
                          color="grey.66"
                          textAlign="justify"
                        >
                          <span
                            style={{ whiteSpace: "pre-line" }}
                            dangerouslySetInnerHTML={{ __html: item }}
                          />
                        </Text>
                      )
                    })}
                  </VStack>
                </Box>
              )}
            </Box>
          </GridItem>
        </Grid>

      </Box>

      <Box h={"10vh"} />

      <RedeemModal
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        listRedeem={listNftRedeem}
      />

    </Box>
  )
}

