import { useQuery } from '@tanstack/react-query'
import NFTMarketPlaceServices from '~/services/nft.marketplace.services'

interface IItemAttributeByCollection {
  traitType: 'string'
  values: ['string']
}

const useListAttributeByCollection = (variables) => {
  const results = useQuery<Array<IItemAttributeByCollection>>(
    ['collectionListAttributeByCollection', variables],
    () => NFTMarketPlaceServices.collectionListAttributeByCollection(variables),
  )
  return {
    ...results,
    data: results?.data ?? [],
  }
}

export default useListAttributeByCollection
