const data1 = {
    title: "1. NFT là gì?",
    desciption: [
        "NFT (Non-fungible token) có thể hiểu một cách gần gũi là vật phẩm không thể thay thế.Đây là một dạng vật phẩm kỹ thuật số được mã hóa trên mạng blockchain. Mỗi vật phẩm đại diện cho một tài sản duy nhất và không thể thay thế được, ví dụ như: một tác phẩm nghệ thuật kỹ thuật số, NFT dựa trên một tài sản thật, tệp âm nhạc, sản phẩm vật chất hoặc NFT đại diện cho bản sắc cá nhân."
    ]
}
const data2 = {
    title: "2. Giá trị của NFT",
    desciption: [
        "NFT có được giá trị dựa vào những đặc tính của nó như:",
        "<strong>a. Tính độc nhất</strong>",
        "&nbsp;&nbsp;Mỗi NFT đều là một vật phẩm độc nhất và được định danh trên mạng blockchain",
        "<strong>b. Tính định danh sở hữu</strong>",
        "&nbsp;&nbsp;NFT định danh được quyền sở hữu đối với những tài sản kỹ thuật số hoặc tài sản thật, đặc tính này giải quyết nhiều vấn đề về quyền sở hữu hay bản quyền.",
        "&nbsp;&nbsp;Ví dụ: Một nghệ sĩ gán tệp một bài hát do chính mình làm vào một NFT mà anh ta sở hữu, thông tin nay sẽ được lưu lại một cách công khai trên mạng blockchain.",
        "<strong>c. Khả năng gia tăng giá trị</strong>",
        "&nbsp;&nbsp;Mỗi NFT đều có khả năng mang trong mình rất nhiều giá trị tùy thuộc vào mong muốn và mục đích của người phát hành. Cùng với khả năng tích lũy các giá trị đó, giá trị nội tại của NFT sẽ tăng.",
        "&nbsp;&nbsp;Ví dụ: Bạn sở hữu một “NFT thành viên” của một nhà hàng. Qua nhiều năm sử dụng, bạn tích lũy được rất nhiều điểm thưởng và phần quà, giá trị của NFT thành viên sẽ tăng lên và có khả năng thanh khoản nếu không còn nhu cầu sử dụng.",
        "<strong>d. Những gì của bạn là của bạn</strong>",
        "&nbsp;&nbsp;NFT của bạn không thể bị lấy lại, hủy bỏ hay xóa kể cả người phát hành.",
        "<strong>e. Tính tương tác cao</strong>",
        "&nbsp;&nbsp;NFT có thể dùng để tương tác với nhiều ứng dụng trên mạng blockchain hoặc thậm chí là các ứng dụng, chương trình và sự kiện ngoài đời thực.",
        "&nbsp;&nbsp;Ví dụ: Bạn sở hữu một NFT của một thương hiệu F. Thương hiệu đó tổ chức một sự kiện online “khủng” và NFT bạn sở hữu được xem như là một tấm vé để tham gia sự kiện đó.",
        "<strong>f. Tính xác minh</strong>",
        "&nbsp;&nbsp;Các thông tin của NFT được lưu trữ trên mạng blockchain một cách công khai, mọi người đều có thể xác minh thông tin bất kỳ lúc nào.",
        "&nbsp;&nbsp;Ví dụ: Xác minh xem là NFT đó được phát hành do ai, khi nào, hiện tại ai đang là người sở hữu, ai đã bán hoặc gửi cho người đó,...",
        "<strong>g. Tính khan hiếm</strong>",
        "&nbsp;&nbsp;Tương tự như các loại hình vật phẩm khác, NFT cũng có tính khan hiếm dựa vào mục đích phát hành và tiện ích của chính nó.",
        "&nbsp;&nbsp;Ví dụ: Một thương hiệu thời trang tung ra bộ sưu tập NFT giới hạn, mỗi NFT gắn liền với một sản phẩm vật lý của thương hiệu đó. Cách duy nhất để nhận được sản phẩm đó là phải sở hữu NFT."
    ]
}
const data3 = {
    title: "3. Phân biệt các dạng NFT",
    desciption: [
        "<strong>a. NFT Ticket</strong>",
        "&nbsp;&nbsp;NFT Ticket được dùng như một tấm vé “thông hành” đặc biệt vào một không gian, chương trình, sự kiện trực tuyến hoặc ngoại tuyến. Tùy vào mục đích phát hành mà “tấm vé” này có thể mang thêm những lợi ích và quyền lợi khác dành cho người sở hữu.",
        "<strong>b. NFT Membership</strong>",
        "&nbsp;&nbsp;Loại NFT chuyên dụng dành cho các nhãn hàng, thương hiệu để tạo ra các ưu đãi độc quyền cho khách hàng với một trải nghiệm hoàn toàn mới. Độc lạ hơn, quyền sở hữu cao hơn, tiện lợi hơn!",
        "<strong>c. NFT Collectible</strong>",
        "&nbsp;&nbsp;Còn được gọi là “NFT sưu tầm”. Loại NFT này được sử dụng như một dạng vật phẩm trực tuyến độc quyền của một cá nhân hay thương hiệu. Ngoài tính sưu tầm, loại NFT này vẫn có thể có thêm giá trị từ tính khan hiếm và lợi ích dành cho người sở hữu trong tương lai.",
    ]
}
const data4 = {
    title: "4. Cách sở hữu NFT",
    desciption: [
        "Bạn có thể sở hữu NFT bằng cách như:",
        "&nbsp;&nbsp;<strong>a. Mua bán, trao đổi trên các chợ NFT</strong>",
        "&nbsp;&nbsp;<strong>b. Tham gia những chương trình tặng NFT từ các nhãn hàng</strong>",
        "&nbsp;&nbsp;<strong>c. Tự tạo ra NFT</strong>",
        "Cách đơn giản nhất là truy cập vào NFT Square tham gia các chương trình nhận NFT miễn phí và mua bán, trao đổi!",
    ]
}
const data5 = {
    title: "5. Hướng dẫn in NFT",
    desciption: [
        "Bước 1: Truy cập vào trang in NFT <a href=/ target=`_blank` style=color:#3498db>tại đây!</a>",
        "Bước 2: Kết nối ví và chọn trang NFT bạn muốn in",
        "Bước 3: Nhập mã",
        "Bước 4: Nhập email của bạn",
        "Bước 5: Chọn “Nhận NFT”",
        "Bước 6: Đợi khoảng 5 phút, kiểm tra ví và hoàn tất!",
    ]
}
const data6 = {
    title: "6. Hướng dẫn mua NFT",
    desciption: [
        "Bước 1: Truy cập trang chợ NFT <a href=/ target=`_blank` style=color:#3498db>tại đây!</a>",
        "Bước 2: Kết nối ví",
        "Bước 3: Chọn NFT muốn mua và ấn “Mua NFT”",
        "Bước 4: Nhập các thông tin và chọn phương thức thanh toán và làm theo hướng dẫn",
        "Bước 5: Chọn “Xác nhận thanh toán”",
        "Bước 6: Đợi khoảng 5 phút, kiểm tra ví và hoàn tất!",
    ]
}
const data7 = {
    title: "7. Hướng dẫn bán NFT",
    desciption: [
        "Bước 1: Kết nối ví",
        "Bước 2: Kiểm tra ví và vật phẩm NFT trong ví",
        "Bước 3: Chọn NFT bạn muốn bán và ấn “Bán NFT”",
        "Bước 4: Nhập các thông tin yêu cầu",
        "Bước 5: Chọn “Bán NFT”",
        "Bước 6: Vào phần “Ví” để kiểm tra tình trạng của NFT đang rao bán",
    ]
}

const ABOUT_DATA_1 = [data1, data3, data5, data7]

const ABOUT_DATA_2 = [data2, data4, data6]

const ABOUT_DATA_MOBILE = [data1, data2, data3, data4, data5, data6, data7]

export {
    ABOUT_DATA_1,
    ABOUT_DATA_2,
    ABOUT_DATA_MOBILE,
}