import React from 'react'
import {
    ModalOverlay,
    Modal,
    ModalContent,
    ModalBody,
    Text,
    VStack,
    Divider,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    HStack,
    Icon,
    Center,
} from '@chakra-ui/react'
import { ReactComponent as CopyIcon } from '~/assets/svgs/copy.svg'
import WrapperCopy from '~/components/WrapperCopy'
import { IRedeem } from '~/dto'
import moment from 'moment'

interface IMintDetailModal {
    isOpen?: boolean
    onDismiss?: () => void
    listRedeem: IRedeem[]
}

export const RedeemModal: React.FC<IMintDetailModal> = ({
    isOpen,
    onDismiss: onClose,
    listRedeem,
}) => {
    const renderHeader = () => {
        return (
            <Thead>
                <Tr>
                    <Th>
                        <Text
                            fontSize={'13px'}
                            lineHeight={'16px'}
                            fontWeight={"bold"}
                            color='08010C'
                        >
                            Redeem Code
                        </Text>
                    </Th>
                    <Th>
                        <Text
                            fontSize={'13px'}
                            lineHeight={'16px'}
                            fontWeight={"bold"}
                            color='08010C'
                        >
                            Ngày tạo
                        </Text>
                    </Th>
                </Tr>
            </Thead>
        )
    }

    const renderBody = () => {
        return (
            <Tbody>
                {listRedeem.map((item, idx) => {
                    return (
                        <Tr key={idx}>
                            <Td>
                                <HStack>
                                    <Text
                                        fontSize={'12px'}
                                        lineHeight={'16px'}
                                        fontWeight={400}
                                        color="08010C"
                                    >
                                        {item.redeemCode}
                                    </Text>
                                    <WrapperCopy copyText={item.redeemCode} popupText="Copy code">
                                        <Icon as={CopyIcon} />
                                    </WrapperCopy>
                                </HStack>
                            </Td>
                            <Td>
                                <Text
                                    fontSize={'12px'}
                                    lineHeight={'16px'}
                                    fontWeight={400}
                                    color="08010C"
                                >
                                    {moment(item.updateDate).format('HH:mm DD/MM/YYYY')}
                                </Text>
                            </Td>
                            {/* <Td>
                                <Text
                                    fontSize={'12px'}
                                    lineHeight={'16px'}
                                    fontWeight={400}
                                    color="08010C"
                                >
                                    {item.active ? "Chưa sử dụng" : "Đã sử dụng"}
                                </Text>
                            </Td> */}
                        </Tr>
                    )
                })}
            </Tbody>
        )
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered >
            <ModalOverlay />
            <ModalContent maxWidth={{ base: "375px", md: "642px" }} w="100%" borderRadius="12px" mx={{ base: "20px", lg: "15px" }}>
                <ModalBody
                    textAlign={"center"}
                    py="30px"
                    px="10px"
                >

                    <VStack spacing={4}>

                        <Text
                            fontSize={'23px'}
                            lineHeight={'29px'}
                            fontWeight={600}
                            color='08010C'
                        >
                            Danh sách Redeem
                        </Text>

                        <Divider />

                        {listRedeem.length ?
                            <TableContainer w="full">
                                <Table variant='simple'>

                                    {renderHeader()}
                                    {renderBody()}

                                </Table>
                            </TableContainer>
                            :
                            <Center w="full" py="20px">
                                <Text
                                    fontSize={'16px'}
                                    lineHeight={'24px'}
                                    fontWeight={600}
                                    color='08010C'
                                >
                                    Không có dữ liệu!
                                </Text>
                            </Center>
                        }

                    </VStack>

                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
