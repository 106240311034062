import { INftProject } from "~/dto";
import Banner from '~/assets/images/dentsu/banner.jpeg'
import Logo from '~/assets/images/dentsu/logo.png'
import Image from '~/assets/images/dentsu/redder.jpeg'
import { EStatus } from "~/common/enums";

export const Dentsu: INftProject =
{
    collection: "dentsu",
    name: "Dentsu Redder",
    banner: Banner,
    logo: Logo,
    shortContent: "VISION & DREAM CO-CREATION DAY 2023.",
    description: `VISION & DREAM CO-CREATION DAY 2023
    /Our aim: To develop ideas that generate greater impact
    /How: By providing unparalleled business vision, strategy, and innovation 
    /From: The Directors and Senior Management of Dentsu Redder
    /Through: Conversation and co-creation 
    /date: 8:30 - 17:30, Dec 21st, 2022
    /place: GEM Center, 8 Nguyen Binh Khiem Str., Da Kao Ward, Dist. 1, HCMC`,
    status: EStatus.OPENING,
    tag1: "NFT Bộ sưu tập",
    tag2: "",
    tag3: "Ho Chi Minh",
    nftAllowcation: "40",
    deposit: "Miễn Phí",
    listNft: [
        {
            askId: "future-leaders",
            collectionName: "Dentsu Redder Leadership Day 2023",
            name: "Future Leaders",
            image: Image,
            video: null,
            about: `Voucher Starbucks`,
            content: [
                `TOPIC #1: The Metaverse, Web3 & The Inevitability of NFTs, and the Implications for advertising & marketing.`,
                `TOPIC #2: The Unexplored Edges of Dentsu on Innovative Media, Technology & Consumer Insights.`,
                `TOPIC #3: How Dentsu Redder has performed on esports marketing & eCommerce in 2021 - 2022 and how we will move forward.`,
                `TOPIC #4: Discover GenZ’s new big insights and learn from some best cases on successful applying those insights into category creation, innovations, commerce and brand communications.`,
                `TOPIC #5: How is Dentsu Redder’s approach on Metaverse & NFT in 2023-2024 to defense both our leadership & building real and multiple impacts.`,
                `TOPIC #6: The most brilliant creative on Tiktok worldwide and key takeaways to improve and innovate our works on this platform.`,
                `TOPIC #7: Meaningful motivations to grow passionate account leaders and foster account leadership excellence.`,
                `TOPIC #8: The Strategies & In-Planning Agenda to foster the creative capabilities & unique strengths at our Dentsu Redder.`,
            ],
            howToMint: [
                "Bước 1: Nhập mã code nhận thưởng và email",
                "Bước 2: Đợi khoảng 5 phút sau đó kiểm tra ví",
            ]
        }
    ]
}