import { useQuery } from '@tanstack/react-query'
import { IActivityItem } from '~/dto'
import NFTMarketPlaceServices from '~/services/nft.marketplace.services'

const useActivitiesInCollection = (variables) => {
  const results = useQuery<{
    data: Array<IActivityItem>
    total: number
  }>(['collectionListActivities', variables], () =>
    NFTMarketPlaceServices.collectionListActivities(variables),
  )
  return {
    ...results,
    data: results?.data?.data ?? [],
    total: results?.data?.total,
  }
}

export default useActivitiesInCollection
