import { INftProject } from "~/dto";
import Logo from '~/assets/images/gs25/gs25.png'
import { EStatus } from "~/common/enums";

export const Gs25: INftProject =
{
    collection: "gs25-membership",
    name: "GS25 MEMBERSHIP",
    banner: "",
    logo: Logo,
    shortContent: "",
    description: "",
    status: EStatus.UPCOMING,
    tag1: "NFT Membership",
    tag2: "Retail",
    tag3: "",
    nftAllowcation: "(TBA)",
    deposit: "Miễn Phí",
    listNft: [
        {
            askId: "",
            collectionName: "",
            name: "",
            image: "",
            video: "",
            about: ""
        }
    ]
}