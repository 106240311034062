import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import useCustomToast from "~/hooks/@global/useCustomToast";
import ChainConfig from "~/common/constants/ChainConfig";
import { useConnectWallet } from "~/hooks/@global/useConnectWallet";
import { NETWORKS } from "~/common/constants/Networks";
import mintNftServices from "~/services/mint.nft.services";

const regexEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

const useMintNftFCoffee = (props: { poolNft: string }) => {
    const toast = useCustomToast();
    const { poolNft: POOL_NFT } = props;
    const { library, account, chainId } = useWeb3React();
    const { getCurrentCode, } = useConnectWallet()
    const chainCurrentCode = getCurrentCode();
    const [onwerBox, setOnwerBox] = useState(0);
    const [code, setCode] = useState(null);
    const [email, setEmail] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isChainPolygon, setIsChainPolygon] = useState(false);
    const [txHash, setTxHash] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const checkChainPolygon = useCallback(() => {
        const chain = NETWORKS.find(item => item.code === chainCurrentCode);

        if (chain && chain.code === "POLYGON") {
            setIsChainPolygon(true);
        } else {
            setIsChainPolygon(false);
        }

    }, [chainCurrentCode])

    const deplayTime = async (ms: number) => {
        await new Promise(res => {
            setTimeout(() => {
                res("ok");
            }, ms)
        })
    }

    const ownerNft = useCallback(async () => {
        try {
            if (account && library) {
                const rs = await mintNftServices.ownerNft({ nftAddress: POOL_NFT, mintToAddress: account });
                setOnwerBox(rs);
            } else {
                setOnwerBox(0);
            }
        } catch (error) {
            setOnwerBox(0);
            toast.handleErrorBlockChain(error)
        }
    }, [POOL_NFT, account, library,])

    const minftNftFCoffee = async () => {
        setIsLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Bạn chưa đăng nhập vào ví, vui lòng đăng nhập bằng metamask để tiếp tục.");
            }

            if (!code) {
                throw new Error("Vui lòng nhập mã để được nhận NFT.");
            }

            if (email && !regexEmail.test(email.toLowerCase())) {
                throw new Error("Email sai định dạng!");
            }

            const tx = await mintNftServices.mintNftFCoffee({ nftAddress: POOL_NFT, mintToAddress: account, code, email, });
            const { transactionHash } = tx;

            await deplayTime(2000);
            setIsLoading(false);
            // toast.show({
            //     title: 'Thành công',
            //     subTitle: 'NFT của bạn sẽ được hiển thị trong vòng 5-10 phút.',
            //     description: `${ChainConfig.getUrlScan()}/tx/${transactionHash}`,
            //     type: 'success',
            // })
            setTxHash(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`)
            setIsOpen(true);

        } catch (error) {
            toast.handleErrorBlockChain(error)
            setIsLoading(false);
        }
    }

    useEffect(() => {
        ownerNft();
        checkChainPolygon();
    }, [checkChainPolygon, ownerNft]);

    return {
        isChainPolygon,
        isLoading,
        onwerBox,
        code,
        txHash,
        isOpen,
        setIsOpen,
        setCode,
        setEmail,
        minftNftFCoffee,
    }

}

export default useMintNftFCoffee;