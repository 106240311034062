import { INftProject } from "~/dto";
import Banner from '~/assets/images/fcoffee/banner-f-coffee1.png'
import Logo from '~/assets/images/fcoffee/f-logo.png'
import Image from '~/assets/images/fcoffee/nft-fcoffee.png'
import { EStatus } from "~/common/enums";

export const FCoffee: INftProject =
{
    collection: "f-coffee-tea",
    name: "F-Coffee and Tea",
    banner: Banner,
    logo: Logo,
    shortContent: "Chào mừng bạn đến với thế giới F NFT, một trải nghiệm mới của F Coffee đặc biệt dành tặng các khách hàng.",
    description: "Chào mừng bạn đến với thế giới F NFT, một trải nghiệm mới của F Coffee đặc biệt dành tặng các khách hàng. Hãy giữ NFT này, vì nó không chỉ là Tài sản kỹ thuật số, nó còn được gia tăng nhiều hơn nữa các giá trị bất ngờ tại F Coffee và trong thế giới số, thế giới Web3 của thời đại internet mới. Hãy khám phá và tận hưởng cùng F Coffee.",
    status: EStatus.OPENING,
    tag1: "NFT Bộ sưu tập",
    tag2: "Food & Bevarage",
    tag3: "Ho Chi Minh",
    nftAllowcation: "(TBA)",
    deposit: "Miễn Phí",
    listNft: [
        {
            askId: "f-mystery-box",
            collectionName: "F-Coffee and Tea",
            name: "F Mystery Box",
            image: Image,
            video: "../../fam-mystery-box.mp4",
            about: "Mỗi Hộp NFT sẽ gồm 01 NFT giới hạn của F Coffee & Tea và cơ hội trúng thưởng các phần quà từ F Coffee & Tea vào dịp Giáng sinh 2022. NFT của F Coffee & Tea sẽ có thêm những lợi ích và quyền lợi cho các khách hàng nắm giữ nó trong tương lai!",
            content: [
                "<strong>THÔNG TIN CHƯƠNG TRÌNH</strong>",
                "Địa điểm tham gia: F Coffee & Tea, 15 Hoa Phượng, p2, Phú Nhuận",
                "Thời gian tham gia: 07/12 - 24/12/2022",
                "Thời gian mở thưởng: Từ 25/12/2022",
                "<strong>Thể lệ:</strong>",
                "Khi mua một phần nước size L, quý khách sẽ nhận được một phần quà bí ẩn dưới dạng kỹ thuật số",
                "Phần quà bí ẩn này có thể mở từ ngày 25/12/2022 để nhận những phần thưởng giá trị từ F Coffee & Tea",
                "Đến quầy thu ngân tại cửa hàng để nhận phần thưởng",
                "<strong>CƠ CẤU GIẢI THƯỞNG</strong>",
                "01 tuần uống cà phê miễn phí tại F Coffee & Tea cho 20 khách hàng may mắn",
                "1000 NFT của F Coffee & Tea",
            ],
            howToMint: [
                "Bước 1: Nhập mã code nhận thưởng và email",
                "Bước 2: Đợi khoảng 5 phút sau đó kiểm tra ví",
            ]
        }
    ]
}