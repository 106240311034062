

export enum ESosialType {
    WEBSITE = "WEBSITE",
    TWITTER = "TWITTER",
    DISCORD = "DISCORD",
    TELEGRAM = "TELEGRAM",
    MEDIUM = "MEDIUM",
    INSTAGRAM = "INSTAGRAM",
    FACEBOOK = "FACEBOOK",
    REDDIT = "REDDIT",
    GITHUB = "GITHUB",
    GITLAB = "GITLAB",
    OTHERS = "OTHERS",
}
