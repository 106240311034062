

export enum ESelectvalue {
    "PROCESSING",
    "DONE",
    "FAIL"
}

export enum EViewOption {
  LISTVIEW = "LISTVIEW",
  GRIDVIEW = "GRIDVIEW"
}
