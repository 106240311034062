import { usePagination } from '@ajna/pagination'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Text,
  Stack,
  Image,
  VStack,
  HStack,
  Spacer,
  Icon,
} from '@chakra-ui/react'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import PaginationComponent from '~/components/Pagination'
import useWindowSize from '~/hooks/useWindowSize'
import { ReactComponent as CopyIcon } from '~/assets/svgs/copy.svg'
import { formatAddress } from '~/utils'

import useActivitiesItemInCollection from '../../hooks/useActivitiesItemInCollection'
import WrapperCopy from '~/components/WrapperCopy'

const TransactionType = ({ type }: { type: string }) => {
  let color = '#1F9254'
  let text = 'Listing'

  switch (type) {
    case 'AskCancelListing':
      color = '#A30D11'
      text = 'Cancel'
      break
    case 'AskUpdatePrice':
      color = '#CD6200'
      text = 'Change price'
      break
    case 'AskSale':
      color = '#0052CD'
      text = 'Sale'
      break
    case 'BidCreated':
      color = '#1F9254'
      text = 'Created'
      break
    // case 'AskCancelListing':
    //   color = '#1F9254'
    //   text = 'Listing'
    //   break

    default:
      break
  }
  return (
    <Text color={color} fontSize="13px" lineHeight="15px" fontWeight="500">
      {text}
    </Text>
  )
}

const THCustom = ({ children, ...rest }) => {
  return (
    <Th
      fontSize="12px"
      lineHeight="18px"
      letterSpacing="0.03em"
      color="grey.97"
      textTransform="uppercase"
      {...rest}
    >
      {children}
    </Th>
  )
}

interface IActivityItem {
  address: string
  createdBy: null
  createdDate: string
  description: string
  exactPrice: string
  from: string
  id: string
  image: string
  name: string
  price: number
  to: string
  tokenId: number
  transactionHash: string
  transactionType: string
  updatedBy: null
  updatedDate: string
  version: number
}

const ActivityMobileItem: FC<IActivityItem & { index: number }> = ({
  index,
  name,
  tokenId,
  address,
  transactionType,
  updatedDate,
  price,
  from,
  to,
  transactionHash,
}) => {
  return (
    <VStack
      alignItems="flex-start"
      w="100%"
      background={index % 2 === 0 ? '#F7F9FA' : 'white'}
      spacing="10px"
      px="16px"
      py="10px"
    >
      <HStack w="100%" alignItems="flex-start">
        <Text
          fontWeight="600"
          fontSize="12px"
          lineHeight="18px"
          color="grey.97"
        >
          Name
        </Text>
        <Spacer />
        <Text
          fontSize="14px"
          lineHeight="24px"
          color="black.light"
          fontWeight="500"
          textAlign="right"
        >
          {name} #{tokenId}
        </Text>
      </HStack>
      <HStack w="100%">
        <Text
          fontWeight="600"
          fontSize="12px"
          lineHeight="18px"
          color="grey.97"
        >
          TXHASH
        </Text>
        <Spacer />
        <Text
          fontSize="14px"
          lineHeight="24px"
          color="black.light"
          fontWeight="500"
          letterSpacing="-0.5px"
          cursor="pointer"
          _hover={{
            textDecoration: 'underline',
          }}
          onClick={() => {
            window.open(`https://bscscan.com/tx/${transactionHash}`)
          }}
        >
          {formatAddress(transactionHash)}
        </Text>
      </HStack>
      <HStack w="100%">
        <Text
          fontWeight="600"
          fontSize="12px"
          lineHeight="18px"
          color="grey.97"
        >
          Transaction type
        </Text>
        <Spacer />
        <Text
          fontSize="14px"
          lineHeight="24px"
          color="black.light"
          fontWeight="500"
        >
          <TransactionType type={transactionType} />
        </Text>
      </HStack>
      <HStack w="100%">
        <Text
          fontWeight="600"
          fontSize="12px"
          lineHeight="18px"
          color="grey.97"
        >
          Time
        </Text>
        <Spacer />
        <Text
          fontSize="14px"
          lineHeight="24px"
          color="black.light"
          fontWeight="500"
        >
          {dayjs(updatedDate).format('YYYY/MM/DD hh:mm:ss')}
        </Text>
      </HStack>
      <HStack w="100%">
        <Text
          fontWeight="600"
          fontSize="12px"
          lineHeight="18px"
          color="grey.97"
        >
          Price
        </Text>
        <Spacer />
        <Text
          fontSize="14px"
          lineHeight="24px"
          color="black.light"
          fontWeight="600"
        >
          ${parseInt(`${price}`).toFixed(2)}
        </Text>
      </HStack>
      <HStack w="100%" alignItems="flex-start">
        <Text
          fontWeight="600"
          fontSize="12px"
          lineHeight="18px"
          color="grey.97"
        >
          Buyer
        </Text>
        <Spacer />
        <HStack justifyContent="flex-start">
          <WrapperCopy copyText={to}>
            <Text
              fontSize="14px"
              lineHeight="24px"
              color="black.light"
              fontWeight="500"
            >
              {formatAddress(from)}
            </Text>
          </WrapperCopy>

          {from && <Icon as={CopyIcon} />}
        </HStack>
      </HStack>
      <HStack w="100%" alignItems="flex-start">
        <Text
          fontWeight="600"
          fontSize="12px"
          lineHeight="18px"
          color="grey.97"
        >
          Seller
        </Text>
        <Spacer />
        <HStack>
          <WrapperCopy copyText={to}>
            <Text
              fontSize="14px"
              lineHeight="24px"
              color="black.light"
              fontWeight="500"
            >
              {formatAddress(to)}
            </Text>
          </WrapperCopy>
          {to && <Icon as={CopyIcon} />}
        </HStack>
      </HStack>
    </VStack>
  )
}

const ActivityItemInCollection = ({ tokenId }) => {
  const { collectionAddress } = useParams()

  const { t } = useTranslation()
  const { width } = useWindowSize()
  const [currentTotal, setCurrentTotal] = useState(0)
  const { pages, pagesCount, currentPage, setCurrentPage, pageSize } =
    usePagination({
      total: currentTotal,
      limits: {
        outer: 1,
        inner: 1,
      },
      initialState: {
        pageSize: 20,
        isDisabled: false,
        currentPage: 1,
      },
    })

  const { data, total, isLoading, isFetched } = useActivitiesItemInCollection({
    pageSize,
    pageIndex: currentPage,
    address: collectionAddress,
    valueSearch: '',
    tokenId: Number(tokenId),
  })

  useEffect(() => {
    setCurrentTotal(total)
  }, [total])

  const handlePageChange = useCallback(
    (nextPage: number): void => {
      setCurrentPage(nextPage)
    },
    [setCurrentPage],
  )

  if (width < 992) {
    return (
      <Box borderRadius="8px">
        {data.map((item, index) => {
          return <ActivityMobileItem key={item.id} index={index} {...item} />
        })}
        <Stack justify="center">
          <Box>
            <PaginationComponent
              pagesCount={pagesCount}
              currentPage={currentPage}
              isDisabled={false}
              onPageChange={handlePageChange}
              pages={pages}
            />
          </Box>
        </Stack>
      </Box>
    )
  }

  return (
    <Box w="100%" overflow="hidden">
      <TableContainer overflowX="scroll">
        <Table variant="simple">
          <Thead>
            <Tr
              bg="#F3F6F9"
              borderTopLeftRadius="6px"
              borderTopRightRadius="6px"
            >
              <THCustom>{t('Image')}</THCustom>
              <THCustom>{t('Name')}</THCustom>
              <THCustom>{t('TXHASH')}</THCustom>
              <THCustom>{t('Transaction Type')}</THCustom>
              <THCustom>{t('Time')}</THCustom>
              <THCustom>{t('Price')}</THCustom>
              <THCustom>{t('Buyer')}</THCustom>
              <THCustom>{t('Seller')}</THCustom>
            </Tr>
          </Thead>

          <Tbody>
            {data?.length > 0 &&
              isFetched &&
              data.map((item, index) => {
                return (
                  <Tr key={item.id} bg={!(index % 2) ? '#FFFFFF' : '#F7F9FA'}>
                    <Td py="42px">
                      <Image
                        h="50px"
                        w="50px"
                        maxH="50px"
                        src={item.image}
                        objectFit={'cover'}
                        borderRadius="8px"
                      />
                    </Td>
                    <Td>
                      <Text
                        color="black.light"
                        letterSpacing="-0.5px"
                        lineHeight="24px"
                        fontWeight="500"
                        fontSize="14px"
                        noOfLines={2}
                      >
                        {item.name}
                      </Text>
                      <Text
                        fontSize="14px"
                        color="black.light"
                        letterSpacing="-0.5px"
                        lineHeight="24px"
                        fontWeight="500"
                      >
                        #{item.tokenId}
                      </Text>
                    </Td>
                    <Td
                      fontSize="14px"
                      color="black.light"
                      letterSpacing="-0.5px"
                      lineHeight="24px"
                      fontWeight="500"
                      cursor="pointer"
                      _hover={{
                        textDecoration: 'underline',
                      }}
                      onClick={() => {
                        window.open(
                          `https://bscscan.com/tx/${item.transactionHash}`,
                        )
                      }}
                    >
                      {formatAddress(item.transactionHash)}
                    </Td>
                    <Td>
                      <TransactionType type={item.transactionType} />
                    </Td>

                    <Td
                      fontSize="14px"
                      color="black.light"
                      letterSpacing="-0.5px"
                      lineHeight="24px"
                      fontWeight="500"
                    >
                      {dayjs(item.updatedDate).format('YYYY/MM/DD hh:mm:ss')}
                    </Td>
                    <Td>
                      <Text
                        color="black.light"
                        letterSpacing="-0.5px"
                        lineHeight="24px"
                        fontWeight="600"
                        fontSize="14px"
                        textAlign="left"
                      >
                        ${parseFloat(`${item.price}`).toFixed(2)}
                      </Text>
                    </Td>
                    <Td>
                      <HStack alignItems="center">
                        <Text
                          fontSize="14px"
                          color="black.light"
                          letterSpacing="-0.5px"
                          lineHeight="24px"
                          fontWeight="500"
                        >
                          {formatAddress(item.from || '')}
                        </Text>
                        {item.from && (
                          <WrapperCopy copyText={item.from}>
                            <Icon as={CopyIcon} />
                          </WrapperCopy>
                        )}
                      </HStack>
                    </Td>
                    <Td>
                      <HStack alignItems="center">
                        <Text
                          fontSize="14px"
                          color="black.light"
                          letterSpacing="-0.5px"
                          lineHeight="24px"
                          fontWeight="500"
                        >
                          {formatAddress(item.to)}
                        </Text>
                        {item.to && (
                          <WrapperCopy copyText={item.to}>
                            <Icon as={CopyIcon} />
                          </WrapperCopy>
                        )}
                      </HStack>
                    </Td>
                  </Tr>
                )
              })}
          </Tbody>
        </Table>
      </TableContainer>
      {data?.length === 0 && isFetched && (
        <HStack w="100%" flex={1} justifyContent="center" py={'30px'}>
          <Text
            fontSize="18px"
            lineHeight="21px"
            fontWeight="400"
            color="#C8C8C8"
          >
            No record
          </Text>
        </HStack>
      )}
      {data?.length > 0 && isFetched && (
        <Stack justify="center" w="100%">
          <Box>
            <PaginationComponent
              pagesCount={pagesCount}
              currentPage={currentPage}
              isDisabled={false}
              onPageChange={handlePageChange}
              pages={pages}
            />
          </Box>
        </Stack>
      )}
    </Box>
  )
}

export default ActivityItemInCollection
