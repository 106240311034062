import { ReactComponent as HomeIcon } from '~/assets/svgs/components/home.svg'
import { ReactComponent as MarketplaceIcon } from '~/assets/svgs/components/marketplace.svg'
import { ReactComponent as NFTSalesIcon } from '~/assets/svgs/components/nft-sales.svg'
import { ReactComponent as UpcomingDrop } from '~/assets/svgs/components/upcoming-drop.svg'
import { ReactComponent as CreatorIcon } from '~/assets/svgs/components/creator.svg'
import { ReactComponent as NewsIcon } from '~/assets/svgs/components/news.svg'

import { ReactComponent as HomeActiveIcon } from '~/assets/svgs/components/home-active.svg'
import { ReactComponent as MarketplaceActiveIcon } from '~/assets/svgs/components/market-place-active.svg'
import { ReactComponent as NFTSalesActiveIcon } from '~/assets/svgs/components/nft-sales-active.svg'
import { ReactComponent as UpcomingActiveDrop } from '~/assets/svgs/components/upcoming-active.svg'
import { ReactComponent as CreatorActiveIcon } from '~/assets/svgs/components/creator-active.svg'
import { ReactComponent as NewsActiveIcon } from '~/assets/svgs/components/news-active.svg'

import { ReactComponent as HomeDeActiveIcon } from '~/assets/svgs/components/home-deactive.svg'
import { ReactComponent as MarketplaceDeActiveIcon } from '~/assets/svgs/components/market-place-deactive.svg'
import { ReactComponent as NFTSalesDeActiveIcon } from '~/assets/svgs/components/nft-sales-deactive.svg'
import { ReactComponent as UpcomingDeActiveDrop } from '~/assets/svgs/components/upcoming-drop-deactive.svg'
import { ReactComponent as CreatorDeActiveIcon } from '~/assets/svgs/components/creator-deactive.svg'
import { ReactComponent as NewsDeActiveIcon } from '~/assets/svgs/components/news-deactive.svg'

export const HOME_ROUTER = '/'
export const MARKET_PLACE_ROUTER = '/marketplace/collections'
export const NFT_SALE_ROUTER = '/nft-sales'
export const NFT_MINT = '/nft-mint'
export const FCOFFEE_ROUTER = '/f-coffee'
export const ABOUT_NFT = '/about-nft'
export const NEWS = '/news'

export const COLLECTIONS = '/shop'

export const ROUTERS = [
  {
    title: 'Shop',
    key: 'home',
    icon: HomeIcon,
    iconActive: HomeActiveIcon,
    iconDeActive: HomeDeActiveIcon,
    href: HOME_ROUTER,
  },
  {
    title: 'About NFT',
    key: 'about_nft',
    icon: NewsIcon,
    iconActive: NewsActiveIcon,
    iconDeActive: NewsDeActiveIcon,
    isDisabled: false,
    href: ABOUT_NFT,
  },
  {
    title: 'market_place',
    key: 'market_place',
    icon: MarketplaceIcon,
    iconActive: MarketplaceActiveIcon,
    iconDeActive: MarketplaceDeActiveIcon,
    href: MARKET_PLACE_ROUTER,
    //   children: [
    //     {
    //       title: 'All',
    //       key: 'all',
    //       icon: null,
    //       href: `${MARKET_PLACE_ROUTER}?order=newest`,
    //     },
    //     // {
    //     //   title: 'sport',
    //     //   key: 'sport',
    //     //   icon: null,
    //     //   href: `${MARKET_PLACE_ROUTER}/sport`,
    //     // },
    //   ],
  },
  // {
  //   title: 'nft_sales',
  //   key: 'nft_sales',
  //   icon: NFTSalesIcon,
  //   iconActive: NFTSalesActiveIcon,
  //   iconDeActive: NFTSalesDeActiveIcon,
  //   href: NFT_SALE_ROUTER,
  //   isDisabled: true,
  //   // children: [
  //   //   {
  //   //     title: 'all_project',
  //   //     key: 'all_project',
  //   //     icon: null,
  //   //     href: `${NFT_SALE_ROUTER}/all-projects`,
  //   //   },
  //   //   {
  //   //     title: 'claim',
  //   //     key: 'claim',
  //   //     icon: null,
  //   //     href: `${NFT_SALE_ROUTER}/claim`,
  //   //   },
  //   //   {
  //   //     title: 'refund',
  //   //     key: 'refund',
  //   //     icon: null,
  //   //     href: `${NFT_SALE_ROUTER}/refund`,
  //   //   },
  //   // ],
  // },
  // {
  //   title: 'NFT Mint',
  //   key: 'mint_nft',
  //   icon: UpcomingDrop,
  //   iconActive: UpcomingActiveDrop,
  //   iconDeActive: UpcomingDeActiveDrop,
  //   href: NFT_MINT,
  //   isDisabled: true,
  // },
  // {
  //   title: 'F-Coffee',
  //   key: 'f-coffee',
  //   icon: CreatorIcon,
  //   iconActive: CreatorActiveIcon,
  //   iconDeActive: CreatorDeActiveIcon,
  //   isDisabled: false,
  //   href: FCOFFEE_ROUTER,
  // },
]
