
import { IUserInformation, } from "~/dto";
import { rootApiService } from "./@global";

const ENDPOINT = {
  REGISTER_USER: "/api/public/auth-nft/register".trim(),
}

export class UserService {

  async registerUser(body: IUserInformation) {
    return rootApiService.post(ENDPOINT.REGISTER_USER, body);
  }

}

export default new UserService();
