import { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Grid,
  GridItem,
  HStack,
  Icon,
  Image,
  Spacer,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'

import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import MainLayout from '~/components/MainLayout/MainLayout'
import SellNFTModal from '~/container/SellNFTModal/SellNFTModal'
import SendNFTModal from '~/container/SendNFTModal/SendNFTModal'
import RemoveNFTModal from '~/container/RemoveNFTModal/RemoveNFTModal'
import ChangePriceNFTModal from '~/container/ChangePriceNFTModal/ChangePriceNFTModal'
import Loading from '~/components/Loading'
import { useMarketCollection } from '~/hooks/market/useMarketCollection'
import { formatAddress } from '~/utils'
import BuyNFTModal from '~/container/BuyNFTModal/BuyNFTModal'
import WrapperCopy from '~/components/WrapperCopy'

import { ReactComponent as VerifyIcon } from '~/assets/svgs/components/verify.svg'
import { ReactComponent as InfoIcon } from '~/assets/svgs/info.svg'
import { ReactComponent as ShieldIcon } from '~/assets/svgs/shield.svg'
import { ReactComponent as PaperIcon } from '~/assets/svgs/paper.svg'
import { ReactComponent as CopyIcon } from '~/assets/svgs/copy.svg'
import { ReactComponent as ChartIcon } from '~/assets/svgs/chart.svg'
import { ReactComponent as ArrowDown } from '~/assets/svgs/arrow-down.svg'
import { ReactComponent as SoldIcon } from '~/assets/svgs/sold.svg'

import Activities from './components/Activities/Activities'
import MoreNFTsSection from './components/MoreNFTsSection'
import useNFT from './hooks/useNFT'

import HeaderMobile from './components/Header.mobile'
import GroupButton from './components/GroupButton'
import GroupButtonMobile from './components/GroupButton.mobile'

const CollectionDetailNFTItem = () => {
  const { t } = useTranslation('src/views/NFTDetail/NFTDetail.lang.json')

  const {
    isOpen: isOpenSellModal,
    onOpen: onOpenSellModal,
    onClose: onCloseSellModal,
  } = useDisclosure()

  const {
    isOpen: isOpenSendModal,
    onOpen: onOpenSendModal,
    onClose: onCloseSendModal,
  } = useDisclosure()

  const {
    isOpen: isOpenRemoveNFTModal,
    onOpen: onOpenRemoveNFTModal,
    onClose: onCloseRemoveNFTModal,
  } = useDisclosure()

  const {
    isOpen: isOpenChangePriceModal,
    onOpen: onOpenChangePriceModal,
    onClose: onCloseChangePriceModal,
  } = useDisclosure()

  const {
    isOpen: isOpenBuyNFTModal,
    onOpen: onOpenBuyNFTModal,
    onClose: onCloseBuyNFTModal,
  } = useDisclosure()

  const [isOpenAboutCollection, setIsOpenAboutCollection] = useState(true)
  const [isOpenAttributes, setIsOpenAttributes] = useState(true)
  const [isOpenNFTDetail, setIsOpenNFTDetail] = useState(true)
  const [isOpenActivities, setIsOpenActivities] = useState(true)

  const { collectionAddress, askId } = useParams()
  const { data, refetch, isLoading } = useNFT({
    address: collectionAddress,
    askId: Number(askId),
  })

  const onRefetch = useCallback(() => {
    if (refetch) {
      refetch()
    }
  }, [refetch])

  const renderActivities = useCallback(() => {
    if (data?.tokenId) {
      return <Activities tokenId={data.tokenId} />
    }
    return null
  }, [data?.tokenId])

  if (isLoading) {
    return <Loading />
  }

  return (
    <MainLayout>
      <HeaderMobile name={data?.name} />
      <Box
        p={{
          base: 0,
          lg: '30px',
          xl: '50px',
        }}
        pt={{
          base: '40px',
          lg: '30px',
          xl: '50px',
        }}
        overflow="hidden"
      >
        <Grid
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(8, 1fr)"
          gap={{
            base: 0,
            lg: 4,
          }}
        >
          <GridItem
            rowSpan={2}
            colSpan={{
              base: 8,
              lg: 3,
            }}
            p={{
              base: '20px',
              lg: '0',
            }}
            bg="white"
            borderRadius="8px"
          >
            <Box position="relative" w="100%" h="100%">
              <Image
                w={'full'}
                h={{
                  base: '374px',
                  lg: '100%',
                }}
                borderRadius="8px"
                src={data?.image}
                objectFit={'cover'}
                fallbackSrc="/assets/images/empty-item.png"
              />
              {data?.status === 'SOLD' && (
                <Box
                  position={'absolute'}
                  h={'full'}
                  w={'full'}
                  top={0}
                  _groupHover={{
                    transform: 'scale(1.1)',
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bg="linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7))"
                >
                  <Icon as={SoldIcon} w="100px" h="90px" />
                </Box>
              )}
            </Box>
          </GridItem>
          <GridItem
            colSpan={{
              base: 8,
              lg: 5,
            }}
            p={{
              base: '0 20px 0 20px',
              lg: '20px 68px 20px 30px',
            }}
            bg="white"
            borderRadius="8px"
            rowSpan={1}
          >
            <HStack alignItems="center">
              <Text
                fontSize="15px"
                lineHeight="18px"
                letterSpacing="-0.5px"
                color="grey.66"
              >
                {data?.collectionName}
              </Text>
              <Icon as={VerifyIcon} />
            </HStack>

            <Text
              mt="12px"
              fontSize="24px"
              lineHeight="28px"
              fontWeight="600"
              color="black.1d"
              letterSpacing="-0.5px"
            >
              {data?.name} #{data?.tokenId}
            </Text>

            <HStack mt="26px">
              <Image src="/assets/images/demo/busd.svg" w="32px" h="32px" />
              <Text
                color="blue.darkest"
                fontSize="32px"
                lineHeight="20px"
                fontWeight="600"
              >
                {data?.price}
              </Text>
            </HStack>

            <GroupButton
              onOpenBuyNFTModal={onOpenBuyNFTModal}
              onOpenSendModal={onOpenSendModal}
              onOpenSellModal={onOpenSellModal}
              data={data}
              onOpenChangePriceModal={onOpenChangePriceModal}
              onOpenRemoveNFTModal={onOpenRemoveNFTModal}
            />
          </GridItem>

          <GridItem
            colSpan={{
              base: 8,
              lg: 5,
            }}
            p={{
              base: '10px 20px',
              lg: 0,
            }}
            bg={{
              base: 'white',
              lg: 'transparent',
            }}
            rowSpan={1}
          >
            <Box
              border={{
                base: '1px solid #DFE6F1',
                lg: 'none',
              }}
              borderRadius="8px"
            >
              <HStack
                px={{
                  base: '10px',
                  lg: '30px',
                }}
                py="14px"
                bg="rgba(68, 138, 255, 0.12)"
                borderRadius="8px 8px 0px 0px"
              >
                <Box>
                  <HStack alignItems="center">
                    <Icon as={InfoIcon} h="24px" w="24px" />
                    <Text
                      fontWeight="600"
                      fontSize="18px"
                      lineHeight="21px"
                      color="black.light"
                      letterSpacing="-0.5px"
                    >
                      {t('about_collection')}
                    </Text>
                    <HStack
                      display={{
                        base: 'none',
                        md: 'flex',
                      }}
                    >
                      <Text
                        fontWeight="500"
                        fontSize="15px"
                        lineHeight="18px"
                        letterSpacing="-0.5px"
                        color="grey.66"
                      >
                        {data?.name}
                      </Text>
                      <Icon as={VerifyIcon} />
                    </HStack>
                  </HStack>
                  <HStack
                    mt="10px"
                    display={{
                      base: 'flex',
                      md: 'none',
                    }}
                  >
                    <Text
                      fontWeight="500"
                      fontSize="15px"
                      lineHeight="18px"
                      letterSpacing="-0.5px"
                      color="grey.66"
                    >
                      {data?.collectionName}
                    </Text>
                    <Icon as={VerifyIcon} />
                  </HStack>
                </Box>
                <Spacer />
                <Box>
                  <Icon
                    as={ArrowDown}
                    w="24px"
                    h="24px"
                    onClick={() => {
                      setIsOpenAboutCollection((current) => !current)
                    }}
                  />
                </Box>
              </HStack>

              {isOpenAboutCollection && (
                <Box
                  p={{
                    base: '20px 15px 30px 15px',
                    lg: '20px 68px 30px 30px',
                  }}
                  bg="white"
                  borderRadius="8px"
                >
                  <Text
                    fontWeight="400"
                    fontSize="16px"
                    lineHeight="19px"
                    letterSpacing="-0.5px"
                    color="grey.66"
                    textAlign="justify"
                  >
                    {data?.description}
                  </Text>
                </Box>
              )}
            </Box>
          </GridItem>
        </Grid>

        <Grid
          mt={{
            base: 0,
            lg: '40px',
          }}
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(8, 1fr)"
          gap={{
            base: 0,
            lg: 4,
          }}
        >
          <GridItem
            rowSpan={1}
            colSpan={{
              base: 8,
              lg: 3,
            }}
            p={{
              base: '10px 20px',
              lg: 0,
            }}
            bg={{
              base: 'white',
              lg: 'transparent',
            }}
          >
            <Box
              border={{
                base: '1px solid #DFE6F1',
                lg: 'none',
              }}
              borderRadius="8px"
            >
              <HStack
                py="14px"
                bg="rgba(68, 138, 255, 0.12)"
                borderRadius="8px 8px 0px 0px"
                px={{
                  base: '10px',
                  lg: '30px',
                }}
              >
                <HStack>
                  <Icon as={ShieldIcon} h="24px" w="24px" />
                  <Text
                    fontWeight="600"
                    fontSize="18px"
                    lineHeight="21px"
                    color="black.light"
                    letterSpacing="-0.5px"
                  >
                    {t('attributes')}
                  </Text>
                </HStack>
                <Spacer />
                <Box>
                  <Icon
                    as={ArrowDown}
                    w="24px"
                    h="24px"
                    onClick={() => {
                      setIsOpenAttributes((current) => !current)
                    }}
                  />
                </Box>
              </HStack>

              {isOpenAttributes &&
                (data?.attributes || []).map(({ traitType, value }, index) => (
                  <Box w="100%" bg="white" borderRadius="8px">
                    <HStack
                      px={{
                        base: '10px',
                        lg: '30px',
                      }}
                      py="20px"
                    >
                      <VStack alignItems="flex-start" spacing="20px" w="100%">
                        <HStack w="100%">
                          <Text
                            fontSize="15px"
                            fontWeight="400"
                            lineHeight="18px"
                            textAlign="justify"
                            color="black.light"
                          >
                            {traitType}
                          </Text>
                          <Spacer />
                          <HStack>
                            <Text
                              fontSize="16px"
                              fontWeight="500"
                              lineHeight="19px"
                              textAlign="justify"
                              color="grey.66"
                            >
                              {value}
                            </Text>
                          </HStack>
                        </HStack>
                      </VStack>
                    </HStack>
                  </Box>
                ))}
            </Box>
          </GridItem>
          <GridItem
            colSpan={{
              base: 8,
              lg: 5,
            }}
            p={{
              base: '10px 20px',
              lg: 0,
            }}
            bg={{
              base: 'white',
              lg: 'transparent',
            }}
          >
            <Box
              border={{
                base: '1px solid #DFE6F1',
                lg: 'none',
              }}
              borderRadius="8px"
            >
              <HStack
                py="14px"
                bg="rgba(68, 138, 255, 0.12)"
                borderRadius="8px 8px 0px 0px"
                px={{
                  base: '10px',
                  lg: '30px',
                }}
              >
                <HStack>
                  <Icon as={PaperIcon} h="24px" w="24px" />
                  <Text
                    fontWeight="600"
                    fontSize="18px"
                    lineHeight="21px"
                    color="black.light"
                    letterSpacing="-0.5px"
                  >
                    {t('nft_detail')}
                  </Text>
                </HStack>
                <Spacer />
                <Box>
                  <Icon
                    as={ArrowDown}
                    w="24px"
                    h="24px"
                    onClick={() => {
                      setIsOpenNFTDetail((current) => !current)
                    }}
                  />
                </Box>
              </HStack>

              {isOpenNFTDetail && (
                <Box
                  w="100%"
                  bg="white"
                  px={{
                    base: '10px',
                    lg: '30px',
                  }}
                  py="20px"
                  borderRadius="8px"
                >
                  <VStack alignItems="flex-start" spacing="20px" w="100%">
                    <HStack w="100%">
                      <Text
                        fontSize="15px"
                        fontWeight="400"
                        lineHeight="18px"
                        textAlign="justify"
                        color="black.light"
                      >
                        Mint Address
                      </Text>
                      <Spacer />
                      <HStack>
                        <Text
                          fontSize="16px"
                          fontWeight="500"
                          lineHeight="19px"
                          textAlign="justify"
                          color="grey.66"
                        >
                          ...
                        </Text>
                        <Icon as={CopyIcon} />
                      </HStack>
                    </HStack>
                    <HStack w="100%">
                      <Text
                        fontSize="15px"
                        fontWeight="400"
                        lineHeight="18px"
                        textAlign="justify"
                        color="black.light"
                      >
                        Owner
                      </Text>
                      <Spacer />
                      <HStack>
                        <Text
                          fontSize="16px"
                          fontWeight="500"
                          lineHeight="19px"
                          textAlign="justify"
                          color="grey.66"
                        >
                          ...
                        </Text>
                        <Icon as={CopyIcon} />
                      </HStack>
                    </HStack>
                    <HStack w="100%">
                      <Text
                        fontSize="15px"
                        fontWeight="400"
                        lineHeight="18px"
                        textAlign="justify"
                        color="black.light"
                      >
                        Token Address
                      </Text>
                      <Spacer />
                      <HStack>
                        <Text
                          fontSize="16px"
                          fontWeight="500"
                          lineHeight="19px"
                          textAlign="justify"
                          color="grey.66"
                        >
                          {formatAddress(data?.address)}
                        </Text>
                        <WrapperCopy copyText={data?.address}>
                          <Icon as={CopyIcon} />
                        </WrapperCopy>
                      </HStack>
                    </HStack>
                    <HStack w="100%">
                      <Text
                        fontSize="15px"
                        fontWeight="400"
                        lineHeight="18px"
                        textAlign="justify"
                        color="black.light"
                      >
                        Token ID
                      </Text>
                      <Spacer />
                      <HStack>
                        <Text
                          fontSize="16px"
                          fontWeight="500"
                          lineHeight="19px"
                          textAlign="justify"
                          color="grey.66"
                        >
                          {data?.tokenId}
                        </Text>
                      </HStack>
                    </HStack>
                  </VStack>
                </Box>
              )}
            </Box>
          </GridItem>
        </Grid>

        <Box
          p={{
            base: '10px 20px',
            lg: 0,
          }}
          bg={{
            base: 'white',
            lg: 'transparent',
          }}
        >
          <Box
            border={{
              base: '1px solid #DFE6F1',
              lg: 'none',
            }}
            borderRadius="8px"
          >
            <HStack
              px={{
                base: '10px',
                lg: '30px',
              }}
              py="14px"
              bg="rgba(68, 138, 255, 0.12)"
              borderRadius="8px 8px 0px 0px"
              mt={{
                base: 0,
                lg: '30px',
              }}
            >
              <HStack>
                <Icon as={ChartIcon} h="24px" w="24px" />
                <Text
                  fontWeight="600"
                  fontSize="18px"
                  lineHeight="21px"
                  color="black.light"
                  letterSpacing="-0.5px"
                >
                  {t('activities')}
                </Text>
              </HStack>
              <Spacer />
              <Box>
                <Icon
                  as={ArrowDown}
                  w="24px"
                  h="24px"
                  onClick={() => {
                    setIsOpenActivities((current) => !current)
                  }}
                />
              </Box>
            </HStack>
            {isOpenActivities && (
              <Box
                bg="white"
                p={{
                  base: 0,
                  lg: '24px',
                }}
                borderBottomLeftRadius="8px"
                borderBottomRightRadius="8px"
              >
                {renderActivities()}
              </Box>
            )}
          </Box>
        </Box>

        <MoreNFTsSection />

        <GroupButtonMobile
          onOpenBuyNFTModal={onOpenBuyNFTModal}
          onOpenSendModal={onOpenSendModal}
          onOpenSellModal={onOpenSellModal}
          data={data}
          onOpenChangePriceModal={onOpenChangePriceModal}
          onOpenRemoveNFTModal={onOpenRemoveNFTModal}
        />

        <SellNFTModal
          data={{
            ...data,
            address: collectionAddress,
          }}
          isOpen={isOpenSellModal}
          onClose={onCloseSellModal}
          refetch={onRefetch}
        />

        <SendNFTModal
          data={{
            ...data,
            address: collectionAddress,
          }}
          isOpen={isOpenSendModal}
          onClose={onCloseSendModal}
          refetch={onRefetch}
        />

        <RemoveNFTModal
          data={{
            ...data,
            address: collectionAddress,
          }}
          isOpen={isOpenRemoveNFTModal}
          onClose={onCloseRemoveNFTModal}
          refetch={onRefetch}
        />

        <ChangePriceNFTModal
          data={{
            ...data,
            address: collectionAddress,
          }}
          isOpen={isOpenChangePriceModal}
          refetch={onRefetch}
          onClose={onCloseChangePriceModal}
        />

        <BuyNFTModal
          data={{
            ...data,
            address: collectionAddress,
          }}
          isOpen={isOpenBuyNFTModal}
          onClose={onCloseBuyNFTModal}
          refresh={onRefetch}
        />
      </Box>
    </MainLayout>
  )
}

export default CollectionDetailNFTItem
