import * as envs from './envs'

export interface IChainConfig {
  chainId: number
  chainName: string
  nativeCurrency: {
    name: string
    symbol: string
    decimals: number
  }
  rpcUrls: string[]
  blockExplorerUrls: string[]
}

export interface IChainList {
  BSC?: IChainConfig;
  ETHEREUM?: IChainConfig;
  AURORA?: IChainConfig;
  POLYGON?: IChainConfig;
}

export type ICodeChainList = keyof IChainList

export interface IEnvConfig {
  name?: string
  DOMAIN_URL: string
  DOMAIN_URL_API: string
  CONNECTORS: {
    ROOT: {
      baseUrl: string
    }
  }
  BLOCK_CHAIN: {
    REACT_APP_CHAIN_ID: string
    REACT_APP_GTAG: string
    REACT_APP_NODE_1: string
    REACT_APP_NODE_2: string
    REACT_APP_NODE_3: string
    REACT_APP_BSCSCAN: string
    REACT_APP_GRAPH_API_PROFILE: string
    REACT_APP_GRAPH_API_PREDICTION: string
    REACT_APP_GRAPH_API_LOTTERY: string
    REACT_APP_SNAPSHOT_BASE_URL: string
    REACT_APP_SNAPSHOT_VOTING_API: string
    REACT_APP_API_PROFILE: string
  }
  CHAINS: IChainList
  ADDRESS_CONFIG: {
    WBNB: string
    BUSD: string
    USDT: string
    BICYCLE: string
    PANCAKE_FACTORY: string
    PANCAKE_ROUTER: string
    FAM_TOKEN: string;
    MARKET_V2_ADDRESS: string;
  }
  GOOGLE_CLIENT_ID: string,
  MORALIS_API_CHAIN: string
  TRASH_WALLET_ADDRESS: string
  BNB_TOKEN_SYMBOL: string
  ERC1155_TYPE: string
  TOKEN_UTILS_POOL_ADDRESS: string
  POOL_FAUCET: string
  POOL_NFT_COLLECTIBLE: string
  POOL_NFT_TRIAL_1: string
  POOL_NFT_TRIAL_2: string
  POOL_NFT_TRIAL_3: string
  POOL_NFT_GS25: string
  POOL_NFT_HENIKEN: string
  POOL_NFT_TRUNG_NGUYEN: string
  POOL_FCOFFEE: string
  POOL_REDDER: string
}
let envConfig: IEnvConfig
export function configEnv(): IEnvConfig {
  if (envConfig) {
    return envConfig
  }
  const envName = process.env.REACT_APP_ENV || 'qa'
  const currentConfig = (envs as any)[envName]
  return {
    ...currentConfig,
    name: envName,
  }
}
