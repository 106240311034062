import { ChakraProvider } from '@chakra-ui/react'
import { useMemo } from 'react'
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ToastContainer } from 'react-toastify'
import { I18nextProvider } from 'react-i18next'
import { Web3ReactProvider } from '@web3-react/core'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import Theme from './styles/Theme'
import ModalProvider from './contexts/ModalContext'
import HomeView from './views/Home/Home'
import NFTDetail from './views/NFTDetail/NFTDetail'
import ProfileView from './views/Profile/Profile'
import i18n from './utils/i18n'
import SideBarProvider from './contexts/SideBarContext'
import { web3ReactHelper } from './@helpers'
import { WalletProvider } from './contexts/WalletContext'
import RefundView from './views/NFTSales/Refund'
import ClaimView from './views/NFTSales/Claim'
import AllProjectsView from './views/NFTSales/AllProjects'
import DetailNFTView from './views/NFTSales/DetailNFT'
import { FCOFFEE_ROUTER, MARKET_PLACE_ROUTER, ABOUT_NFT, NFT_MINT, COLLECTIONS } from './routes/routes'
import CollectionsView from './views/Collections/Collections'
import DetailCollection from './views/CollectionDetail/DetailCollection'
import ScrollToTop from './components/ScrollToTop'
import ActivitiesMobile from './views/CollectionDetail/Activities.mobile'
import ItemsMobile from './views/CollectionDetail/Items.mobile'
import NotFoundView from './views/NotFound'
import News from './views/News/News'
import NewsDetail from './views/NewsDetail/NewsDetail'
import CollectionMint from './views/CollectionMint'
import CollectionMintDetail from './views/CollectionMintDetail'
import NFTMint from './views/NFTMint'
import FCoffee from './views/FCoffee'
import CommingSoon from './views/CommingSoon'
import { HomeV2 } from './views/V2/HomeV2'
import { CollectionDetail } from './views/V2/CollectionDetail'
import { NftDetail } from './views/V2/CollectionDetail/components/NftDetail'
import { AboutNft } from './views/V2/AboutNft'

const { getLibrary } = web3ReactHelper

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const RouteAdapter = ({ children }: { children: any }) => {
  const navigate = useNavigate()
  const location = useLocation()
  // useEagerConnect();
  const adaptedHistory = useMemo(
    () => ({
      replace(location: any) {
        navigate(location, { replace: true, state: location.state })
      },
      push(location: any) {
        navigate(location, { replace: false, state: location.state })
      },
    }),
    [navigate],
  )
  return children({ history: adaptedHistory, location })
}

function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={Theme}>
        <I18nextProvider i18n={i18n}>
          <QueryParamProvider ReactRouterRoute={RouteAdapter as any}>
            <WalletProvider>
              <ModalProvider>
                <SideBarProvider>
                  <Routes>

                    <Route caseSensitive path="/" element={<HomeV2 />} />
                    <Route path={FCOFFEE_ROUTER} element={<FCoffee />} />

                    <Route path="/profile" element={<ProfileView />} />

                    <Route path={`${COLLECTIONS}/:id`} element={<CollectionDetail />} />
                    {/* <Route path={`${COLLECTIONS}/:id/:askId`} element={<NftDetail />} /> */}

                    <Route
                      path={`${MARKET_PLACE_ROUTER}/:collectionAddress/:askId`}
                      element={<NFTDetail />}
                    />
                    <Route
                      path={`${MARKET_PLACE_ROUTER}/:collectionAddress`}
                      element={<DetailCollection />}
                    />
                    <Route
                      path={`${MARKET_PLACE_ROUTER}/*`}
                      element={<CollectionsView />}
                    />
                    <Route
                      path={`${ABOUT_NFT}`}
                      element={<AboutNft />}
                    />

                    {/* 
                    <Route
                      path={`${MARKET_PLACE_ROUTER}/:collectionAddress/activities`}
                      element={<ActivitiesMobile />}
                    />
                    <Route
                      path={`${MARKET_PLACE_ROUTER}/:collectionAddress/items`}
                      element={<ItemsMobile />}
                    />
                    <Route
                      path="/nft-sales/refund/:id"
                      element={<RefundView />}
                    />
                    <Route
                      path="/nft-sales/refund/*"
                      element={<RefundView />}
                    />
                    <Route
                      path="/nft-sales/claim/:id"
                      element={<ClaimView />}
                    />
                    <Route path="/nft-sales/claim/*" element={<ClaimView />} />
                    <Route
                      path="/nft-sales/all-projects/:id"
                      element={<DetailNFTView />}
                    />
                    <Route
                      path="/nft-sales/all-projects"
                      element={<AllProjectsView />}
                    />
                    <Route path="/nft-sales/*" element={<AllProjectsView />} />

                    <Route path={NEWS} element={<News />} />
                    <Route path={`${NEWS}/:id`} element={<NewsDetail />} />

                    <Route path={NFT_MINT} element={<CollectionMint />} />
                    <Route path={`${NFT_MINT}/:id`} element={<CollectionMintDetail />} />
                    <Route path={`${NFT_MINT}/:id/:id`} element={<NFTMint />} /> 
                    */}

                    <Route path="/*" element={<NotFoundView />} />
                  </Routes>
                </SideBarProvider>
              </ModalProvider>
            </WalletProvider>
          </QueryParamProvider>
        </I18nextProvider>
        <ToastContainer />
      </ChakraProvider>
    </QueryClientProvider>
  )
}

const ContainerApp = () => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </BrowserRouter>
    </Web3ReactProvider>
  )
}

export default ContainerApp
