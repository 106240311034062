import { usePagination } from '@ajna/pagination'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Text,
  Stack,
  Image,
  Flex,
  Spinner,
  HStack,
  Icon,
} from '@chakra-ui/react'
import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import WrapperCopy from '~/components/WrapperCopy'
import PaginationComponent from '~/components/Pagination'
import EmptyTable from '~/components/EmptyTable'
import { formatAddress } from '~/utils'
import { ReactComponent as CopyIcon } from '~/assets/svgs/copy.svg'

import useActivitiesInCollection from '../../hooks/useActivitiesInCollection'
import TransactionType from '../TransactionType'

const THCustom = ({ children, ...rest }) => {
  return (
    <Th
      fontSize="12px"
      lineHeight="18px"
      letterSpacing="0.03em"
      color="grey.97"
      textTransform="uppercase"
      {...rest}
    >
      {children}
    </Th>
  )
}

const Activities = () => {
  const { collectionAddress } = useParams()
  const { t } = useTranslation()

  const [pageIndex, setPageIndex] = useState(1)

  const { data, total, isLoading } = useActivitiesInCollection({
    pageSize: 5,
    pageIndex: pageIndex,
    address: collectionAddress,
    valueSearch: '',
  })

  const { pages, pagesCount, currentPage, setCurrentPage } = usePagination({
    total,
    limits: {
      outer: 1,
      inner: 1,
    },
    initialState: {
      pageSize: 5,
      isDisabled: false,
      currentPage: 1,
    },
  })

  const handlePageChange = useCallback(
    (nextPage: number): void => {
      setPageIndex(nextPage)
      setCurrentPage(nextPage)
    },
    [setCurrentPage],
  )

  const renderDesktopLayout = useCallback(() => {
    return (
      <TableContainer overflowX="scroll">
        <Table variant="simple">
          <Thead>
            <Tr
              bg="#F3F6F9"
              borderTopLeftRadius="6px"
              borderTopRightRadius="6px"
            >
              <THCustom>{t('image')}</THCustom>
              <THCustom>{t('name')}</THCustom>
              <THCustom>{t('TXHASH')}</THCustom>
              <THCustom>{t('TRANSACTION TYPE')}</THCustom>
              <THCustom>{t('time')}</THCustom>
              <THCustom>{t('Price')}</THCustom>
              <THCustom>{t('buyer')}</THCustom>
              <THCustom>{t('seller')}</THCustom>
            </Tr>
          </Thead>
          {data?.length > 0 && (
            <Tbody>
              {data.map((item, index) => {
                return (
                  <Tr key={item.id} bg={!(index % 2) ? '#FFFFFF' : '#F7F9FA'}>
                    <Td py="42px">
                      <Image
                        h="50px"
                        w="50px"
                        maxH="50px"
                        src={item.image}
                        objectFit="contain"
                      />
                    </Td>
                    <Td>
                      <Text
                        color="black.light"
                        letterSpacing="-0.5px"
                        lineHeight="24px"
                        fontWeight="500"
                        fontSize="14px"
                      >
                        {item.name}
                      </Text>
                      <Text
                        fontSize="14px"
                        color="black.light"
                        letterSpacing="-0.5px"
                        lineHeight="24px"
                        fontWeight="500"
                      >
                        #{item.tokenId}
                      </Text>
                    </Td>
                    <Td
                      fontSize="14px"
                      color="black.light"
                      letterSpacing="-0.5px"
                      lineHeight="24px"
                      fontWeight="500"
                      cursor="pointer"
                      _hover={{
                        textDecoration: 'underline',
                      }}
                      onClick={() => {
                        window.open(
                          `https://bscscan.com/tx/${item.transactionHash}`,
                        )
                      }}
                    >
                      {formatAddress(item.transactionHash)}
                    </Td>
                    <Td>
                      <TransactionType type={item.transactionType} />
                    </Td>

                    <Td
                      fontSize="14px"
                      color="black.light"
                      letterSpacing="-0.5px"
                      lineHeight="24px"
                      fontWeight="500"
                    >
                      {dayjs(item.updatedDate).format('DD/MM/YYYY')}
                    </Td>
                    <Td>
                      <Text
                        color="black.light"
                        letterSpacing="-0.5px"
                        lineHeight="24px"
                        fontWeight="600"
                        fontSize="14px"
                        textAlign="right"
                      >
                        ${parseFloat(`${item.price}`).toFixed(1)}
                      </Text>
                    </Td>
                    <Td>
                      <HStack>
                        <Text
                          fontSize="14px"
                          color="black.light"
                          letterSpacing="-0.5px"
                          lineHeight="24px"
                          fontWeight="500"
                        >
                          {formatAddress(item.from)}
                        </Text>
                        {item.from && (
                          <WrapperCopy copyText={item.from}>
                            <Icon as={CopyIcon} />
                          </WrapperCopy>
                        )}
                      </HStack>
                    </Td>
                    <Td>
                      <HStack>
                        <Text
                          fontSize="14px"
                          color="black.light"
                          letterSpacing="-0.5px"
                          lineHeight="24px"
                          fontWeight="500"
                        >
                          {formatAddress(item.to)}
                        </Text>
                        {item.to && (
                          <WrapperCopy copyText={item.to}>
                            <Icon as={CopyIcon} />
                          </WrapperCopy>
                        )}
                      </HStack>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          )}
        </Table>
        {!data.length && <EmptyTable />}
      </TableContainer>
    )
  }, [data, t])

  if (isLoading) {
    return (
      <Flex flex={1} justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    )
  }

  return (
    <Box w="100%" overflow="hidden">
      {renderDesktopLayout()}

      {total !== 0 && (
        <Stack justify="center" w="100%">
          <Box>
            <PaginationComponent
              pagesCount={pagesCount}
              currentPage={currentPage}
              isDisabled={false}
              onPageChange={handlePageChange}
              pages={pages}
            />
          </Box>
        </Stack>
      )}
    </Box>
  )
}

export default memo(Activities)
