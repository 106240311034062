import { useQuery } from '@tanstack/react-query'
import NFTMarketPlaceServices from '~/services/nft.marketplace.services'

interface INFT {
  name: string
  image: string
  description: string
  id: string
  createdDate: string
  updatedDate: string
  createdBy: null
  updatedBy: null
  version: number
  askId: number
  address: string
  tokenId: number
  seller: string
  price: number
  exactPrice: string
  status: string
  collectionName: string
  attributes: [
    {
      traitType: string
      value: string
    },
  ]
}

const useNFT = (variables) => {
  const results = useQuery<INFT>(
    ['getDetailItemInCollections', variables],
    async () => NFTMarketPlaceServices.getDetailItemInCollections(variables),
    {},
  )
  return { ...results, data: results?.data }
}

export default useNFT
