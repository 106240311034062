import { INftProject } from "~/dto";
import { Cio } from "./cio";
import { Dentsu } from "./dentsu";
import { FCoffee } from "./f-coffee";
import { Gs25 } from "./gs25";
import { Pnj } from "./pnj";
import { QuocKhanh } from "./quockhanh";
import { Web3Passport } from "./web3-passport";

export const resourceProjects: INftProject[] = [
    Dentsu,
    FCoffee,
    Web3Passport,
    Cio,
    Gs25,
    Pnj,
    QuocKhanh,
]